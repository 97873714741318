import React from "react";
import history from "utils/history";
import { useNavigate } from "react-router-dom";
import { Layout, Avatar, Typography } from "antd";
import { AppDispatch } from "stores";
import { useDispatch, useSelector } from "react-redux";
import { resetLogin, setMenuMode } from "stores/auth";
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  SettingOutlined,
  // SkinOutlined,
  UserSwitchOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import Logo from "assets/images/sci_logo.jpeg";

import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import style from "assets/styles/layout.module.css";

const { Header } = Layout;
const { Link } = Typography;

const HeaderLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch<AppDispatch>();
  const menuMode = useSelector((state: any) => state.auth.menuMode);
  const { lastLogin } = useSelector((state: any) => state.auth);

  const handleLogout = () => {
    dispatch(resetLogin());
    history.replace("/login");
  };

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: <div>Profile</div>,
    //   icon: <SkinOutlined />,
    //   onClick: () => {
    //     handleLogout();
    //   },
    // },
    {
      key: "2",
      label: <div>SKID</div>,
      icon: <ProjectOutlined />,
      onClick: () => {
        dispatch(setMenuMode("skid"));
        navigate("/");
        document.title = "SCI SKID";
      },
    },
    {
      key: "3",
      label: <div>MTN</div>,
      icon: <SettingOutlined />,
      onClick: () => {
        dispatch(setMenuMode("mtn"));
        navigate("/mtn");
        document.title = "SCI MTN";
      },
    },
    {
      key: "4",
      label: "Profile",
      icon: <UserSwitchOutlined />,
      onClick: () => {
        navigate("/profile");
      },
    },
    {
      key: "5",
      danger: true,
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        handleLogout();
      },
    },
  ] //only role id 1 can access all menu profile
    .filter((item) =>
      lastLogin?.role?.id === 1 ? item : item.key !== "2" && item.key !== "3"
    )
    .filter((item) =>
      menuMode === "skid" ? item.key !== "2" : item.key !== "3"
    );

  return (
    <Header className={style.headerMod}>
      <div className={style.header}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img width={50} height={50} src={Logo} alt="logo" />
          <div
            style={{ marginLeft: "10px", fontSize: "1.5rem", fontWeight: 700 }}
          >
            SCI
          </div>
        </div>
        <div>
          <Dropdown menu={{ items }} placement="bottomRight" arrow>
            <Link
              style={{ color: "#f4801a" }}
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                {lastLogin?.name}({lastLogin?.role?.name})
                <DownOutlined />
                <Avatar icon={<UserOutlined />} />
              </Space>
            </Link>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderLayout;
