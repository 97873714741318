import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Purchase", to: "/mtn/order-history/purchase" },
  ];

  return <Breadcrumb title="Purchase Order" items={bItem} />;
};

export default TitleStore;
