import { message } from "antd";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as storeAction from "stores/mtnReport/actions/mtnReport";
import { setSearch, setStatus, setType, setDate } from "stores/mtnReport";
import debounce from "lodash/debounce";
import ReportMtnService from "services/mtns/report/ReportMtnService";
import dayjs from "dayjs";
import downloader from "utils/downloader";

const useFilter = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [options, setOptions] = useState({ status: [], types: [] });

  const getOptionFilter = async () => {
    const resStatus = await ReportMtnService.getListStatus();
    const optionStatus = resStatus?.data?.data?.map((item: any) => ({
      label: item?.status,
      value: item?.status,
    }));
    setOptions((val) => ({ ...val, status: optionStatus }));
  };

  const mappingType = (types: [], results: any) => {
    return types
      .filter((t: any) => results.includes(t.input_type))
      .map((item: any) => {
        return {
          label: item?.input_type,
          value: item?.input_type,
        };
      });
  };

  const handleSetType = async (status: string) => {
    const { data } = await ReportMtnService.getListType();
    if (status === "IN") {
      const types: any = mappingType(data.data, ["WEB", "REPAIR", "PO"]);
      setOptions((val) => ({ ...val, types }));
    } else if (status === "OUT") {
      const types: any = mappingType(data.data, ["WEB", "MOBILE", "SPAREPART"]);
      setOptions((val) => ({ ...val, types }));
    } else if (status === "IN-OUT") {
      const types: any = mappingType(data.data, ["WEB"]);
      setOptions((val) => ({ ...val, types }));
    } else {
      setOptions((val) => ({ ...val, types: [] }));
    }
  };

  useEffect(() => {
    getOptionFilter();
  }, []);

  const { search, pageSize, status, type, date } = useSelector(
    (state: any) => state.mtnReport
  );

  const sendBackendRequest = useCallback((params: any) => {
    dispatch(storeAction.search(params)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChangeSearch = (e: any) => {
    const value = e.target.value;
    dispatch(setSearch(value));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: value,
      status: status,
      input_type: type,
    };
    if (date) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }
    debouncedSendRequest(params);
  };

  const handleChangeStatus = async (value: string) => {
    await handleSetType(value);
    dispatch(setStatus(value));
    dispatch(setType(null));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      status: value,
    };
    if (date) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.start_date = dayjs(date[1]).format("YYYY-MM-DD");
    }
    dispatch(storeAction.get(params)).unwrap();
  };

  const handleChangeType = (value: string) => {
    dispatch(setType(value));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      status: status,
      input_type: value,
    };
    if (date) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }
    dispatch(storeAction.get(params)).unwrap();
  };

  const handleChangeDate = (value: any) => {
    dispatch(setDate(value));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      status: status,
      input_type: type,
    };
    if (value) {
      params.start_date = dayjs(value[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(value[1]).format("YYYY-MM-DD");
    }
    dispatch(storeAction.get(params)).unwrap();
  };

  const handleDownload = async () => {
    try {
      const params: Record<string, string> = {};
      if (date) {
        params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
        params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
      }

      if (search) params.keyword = search;
      if (status) params.status = status;
      if (type) params.input_type = type;

      const { data }: any = await ReportMtnService.downloadReport(params);

      downloader(data, "Report_inout", ".xlsx");
    } catch (err) {
      message.error("Download error!");
      console.log(err);
    }
  };
  return {
    dispatch,
    sendBackendRequest,
    handleChangeSearch,
    options,
    handleChangeStatus,
    handleChangeType,
    handleChangeDate,
    handleDownload,
    type,
  };
};

export default useFilter;
