import BrowserRouterHistory from "components/BrowserRouterHistory";
import history from "utils/history";
import Routes from "routes";
import ErrorBoundary from "components/ErrorBoundary";

function App() {
  return (
    <>
      <BrowserRouterHistory history={history}>
        <ErrorBoundary>
          <Routes isLoggedIn={""} />
        </ErrorBoundary>
      </BrowserRouterHistory>
    </>
  );
}
export default App;
