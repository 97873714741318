import Breadcrumb from "components/Breadcrumb";

const TitleSkidList = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Report", to: "/report/summary" },
    { name: "Summary", to: "/report/summary" },
  ];

  return <Breadcrumb title="Summary" items={bItem} />;
};

export default TitleSkidList;
