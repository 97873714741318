import Title from "pages/mtn/order-history/purchase/sections/title";
import ActionTable from "pages/mtn/order-history/purchase/sections/action-table";
import Tabs from "pages/mtn/order-history/purchase/sections/tabs";
const Store = () => {
  return (
    <>
      <Title />
      <ActionTable />
      <Tabs />
    </>
  );
};

export default Store;
