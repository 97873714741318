import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  partOut,
  get,
  search,
  remove,
  update,
  updateStatus,
  getLimitPart
} from "stores/order-history/purchase/actions/purchase";
import { message } from "antd";

export interface PurchaseState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  dialogOut: boolean;
  dialogStatus: boolean;
  dialogCart: boolean;
  dialogPreview: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  partId: string;
  pageSize: number;
  repair: string | null;
  activeTab: string | number;
  optionTabs: any;
  carts: any;
}

const initialState: PurchaseState = {
  dialogAdd: false,
  dialogUpdate: false,
  dialogOut: false,
  dialogStatus: false,
  dialogCart: false,
  dialogPreview: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  partId: "",
  pageSize: 10,
  repair: null,
  activeTab: 1,
  optionTabs: [],
  carts: []
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    toggleOutDialog: (state, action) => {
      state.dialogOut = action.payload;
    },
    toggleStatusDialog: (state, action) => {
      state.dialogStatus = action.payload;
    },
    toggleCartDialog: (state, action) => {
      state.dialogCart = action.payload;
    },
    togglePreviewDialog: (state, action) => {
      state.dialogPreview = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPartId: (state, action) => {
      state.partId = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setRepair: (state, action) => {
      state.repair = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setOptionTabs: (state, action) => {
      state.optionTabs = action.payload;
    },
    setCart: (state, action) => {
      state.carts.push(action.payload);
    },
    setCartQty: (state, action) => {
      state.carts = action.payload;
    },
    resetRepairPart: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.rejected, (state, action: any) => {
        state.isLoading = false;
        if (action) {
          message.error(action?.payload?.message);
        } else {
          message.error("Create Purchase Part Failed");
        }
      })
      .addCase(create.fulfilled, (state, action) => {
        state.search = "";
        state.repair = null;
        state.isLoading = false;
        state.carts = []
        state.dialogCart = false;
        message.success("Create Purchase Part Success");
      });

    builder
      .addCase(partOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(partOut.rejected, (state, action: any) => {
        state.isLoading = false;
        if (action) {
          message.error(action?.payload?.message);
        } else {
          message.error("Out Purchase Part Failed");
        }
      })
      .addCase(partOut.fulfilled, (state, action) => {
        state.search = "";
        state.repair = null;
        state.isLoading = false;
        state.dialogOut = false;
        message.success("Out Purchase Part Success");
      });

    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(remove.pending, (state) => {
        state.isLoadingRemove = true;
      })
      .addCase(remove.rejected, (state, action: any) => {
        state.isLoadingRemove = false;
        message.error("Delete Purchase Part Failed");
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingRemove = false;
        message.success("Delete Purchase Part Success");
      });

    builder
      .addCase(update.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(update.rejected, (state, action: any) => {
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.error("Update Purchase Part Failed");
      })
      .addCase(update.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.success("Update Purchase Part Success");
      });

    builder
      .addCase(updateStatus.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(updateStatus.rejected, (state, action: any) => {
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        state.dialogStatus = false;
        message.error("Update Status Purchase Part Failed");
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        state.dialogStatus = false;
        message.success("Update Status Purchase Part Success");
      });

    //limit part
    builder
      .addCase(getLimitPart.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(getLimitPart.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(getLimitPart.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });
  },
});

export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  setSearch,
  setPartId,
  setPageSize,
  setRepair,
  setActiveTab,
  setOptionTabs,
  resetRepairPart,
  toggleOutDialog,
  toggleStatusDialog,
  setCart,
  toggleCartDialog,
  setCartQty,
  togglePreviewDialog
} = purchaseSlice.actions;

export default purchaseSlice.reducer;
