import { createSlice } from "@reduxjs/toolkit";
import { get, print } from "stores/print/label/actions/label";

export interface LabelPrintState {
  dialogPreview: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingSearch: boolean;
  datas: any;
  printDatas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
}

const initialState: LabelPrintState = {
  dialogPreview: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingSearch: false,
  datas: [],
  printDatas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
};

export const labelPrintReducer = createSlice({
  name: "labelPrint",
  initialState,
  reducers: {
    togglePreviewDialog: (state, action) => {
      state.dialogPreview = action.payload;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(print.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(print.rejected, (state, action: any) => {
        state.isLoading = false;
      })
      .addCase(print.fulfilled, (state, action) => {
        state.isLoading = false;
        state.printDatas = action.payload.data;
      });
  },
});

// Action creators are generated for each case reducer function
export const { togglePreviewDialog, setIsLoading, setSearch, setPageSize } =
  labelPrintReducer.actions;

export default labelPrintReducer.reducer;
