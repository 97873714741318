import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Input, Col, Row } from "antd";
import { toggleUpdateDialog } from "stores/master-data/line";
import { AppDispatch } from "stores";
import { get, update } from "stores/master-data/line/actions/line";

const { TextArea } = Input;

const UpdateStore = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogUpdate, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.line
  );

  useEffect(() => {
    form.setFieldsValue({
      name: dataRecord?.name,
      code: dataRecord?.code,
      remarks: dataRecord?.remarks,
    });
  }, [dialogUpdate]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };
      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogUpdate}
        title="Edit Line"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form
          // initialValues={dataRecord}
          form={form}
          layout="vertical"
          name="userForm"
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true },
                  {
                    pattern: /^[a-zA-Z]/,
                    message: "First character must be a letter",
                  },
                  {
                    pattern: /^.{3,}$/,
                    message: "Minimum 3 character",
                  },
                ]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="code" label="Code" rules={[{ required: true }]}>
                <Input disabled placeholder="Type here..." />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
            <Form.Item name="name" label="Floor" rules={[{ required: true }]}>
            <Input placeholder="Type here..." />
            </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item name="name" label="Floor" rules={[{ required: true }]}>
            <Input placeholder="Type here..." />
            </Form.Item>
          </Col> */}
          </Row>
          <Form.Item
            name="remarks"
            label="Remarks"
            rules={[{ required: true }]}
          >
            <TextArea rows={3} placeholder="Type here..." />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateStore;
