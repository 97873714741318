import { useState, useRef, useEffect } from "react";
import { Modal, Button, Row } from "antd";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ReportService from "services/skids/ReportService";
import zeroSize from "utils/zeroSize";
import "assets/styles/table_by_customer.css";

dayjs.extend(minMax);

const Preview = () => {
  const [modal, setModal] = useState(false);
  const [previews, setPreviews] = useState([]);
  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { skidName, date, selectCustomer, thick, width, length1, length2 } =
    useSelector((state: any) => state.reportSkidHistory);

  const dateParse: any = (dateNow: any) =>
    dateNow
      ? dayjs(dateNow).format("DD-MM-YYYY")
      : dayjs(new Date()).format("DD-MM-YYYY");
  const timeParse = dayjs(new Date()).format("HH:mm:ss");

  const periode = () => {
    if (date) {
      return dateParse(date[0]) + " - " + dateParse(date[1]);
    } else if (previews.length > 0) {
      const startPeriode: any = dayjs.min(
        previews.map((item: any) => dayjs(item.create_time))
      );
      const endPeriode: any = dayjs.max(
        previews.map((item: any) => dayjs(item.create_time))
      );
      return dateParse(startPeriode) + " - " + dateParse(endPeriode);
    } else {
      return "-";
    }
  };

  const accumulate = (val: any, type: string) => {
    return val
      ?.map((item: any) => {
        if (type === "asset") {
          return item?.assets?.length;
        } else if (type === "in") {
          return parseInt(item?.In);
        } else if (type === "out") {
          return parseInt(item?.Out);
        } else {
          return 0;
        }
      })
      .reduce((acc: any, current: any) => acc + current, 0);
  };

  const total = {
    asset: accumulate(previews, "asset"),
    in: accumulate(previews, "in"),
    out: accumulate(previews, "out"),
  };

  const falseValue = (str: any) => {
    if (str === "" || str === "0.00" || str === "0") {
      return "";
    } else {
      return parseFloat(str).toFixed(2);
    }
  };

  const getPreview = async () => {
    const params: any = {};
    if (skidName) {
      params.keyword = skidName;
    }
    if (date.length > 0) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    if (selectCustomer.length > 0) {
      params.customer = selectCustomer.toString();
    }

    if (falseValue(thick)) {
      params.thick = falseValue(thick);
    }

    if (falseValue(width)) {
      params.width = falseValue(width);
    }

    if (falseValue(length1)) {
      params.length1 = falseValue(length1);
    }

    if (falseValue(length2)) {
      params.length2 = falseValue(length2);
    }

    const res = await ReportService.getHistorySkid(params);
    const newRes = res?.data?.data?.map((item: any) => ({
      ...item,
      size: item?.assets?.find((_: any, index: number) => index === 0),
    }));
    setPreviews(newRes);
  };

  useEffect(() => {
    if (modal) {
      getPreview();
    }
  }, [modal]);

  const generateXls = () => {
    // Acquire Data (reference to the HTML table)
    const table_elt = document.getElementById("my-table-id");

    // Extract Data (create a workbook object from the table)
    const workbook = XLSX.utils.table_to_book(table_elt);

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, "Report_skid_history.xlsx");
  };

  const handlePrintProcess = async () => {
    try {
      handlePrint();
    } catch (err) {
      console.log(err);
    }
  };

  const newDatas = previews?.map((item: any, index: number) => ({
    ...item,
    no: index + 1,
    // customer: item?.customer?.name,
    no_label: item?.skid_label,
    thick: zeroSize(item?.sizeSkid?.thick),
    width: zeroSize(item?.sizeSkid?.width),
    length1: zeroSize(item?.sizeSkid?.length1),
    length2: zeroSize(item?.sizeSkid?.length2),
  }));

  return (
    <>
      <Button
        style={{ width: "fit-content" }}
        type="primary"
        onClick={() => setModal(true)}
      >
        Preview
      </Button>
      <Modal
        title="Preview Report Skid History"
        centered
        open={modal}
        onCancel={() => setModal(false)}
        width={1200}
        footer={[
          <Button key="back" onClick={() => setModal(false)}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={handlePrintProcess}>
            Print
          </Button>,
          <Button key="download" onClick={() => generateXls()} type="primary">
            Download
          </Button>,
        ]}
      >
        <Row ref={componentRef} justify="center">
          <table className="table-by-customer" id="my-table-id">
            <tr>
              <td colSpan={10}>PT. Steel Center Indonesia</td>
            </tr>
            <tr>
              <td colSpan={10}>Report Skid History In-Out Skid Besi</td>
            </tr>
            <tr>
              <td colSpan={10}>Date {dateParse("")}</td>
            </tr>
            <tr>
              <td colSpan={10}>Time {timeParse}</td>
            </tr>
            <tr>
              <td colSpan={10}>Periode {periode()}</td>
            </tr>
            <tr>
              <td colSpan={10}>
                <br />
              </td>
            </tr>
            <tr>
              <th className="active-border col-no" rowSpan={2}>
                No
              </th>
              <th className="active-border col-customer" rowSpan={2}>
                Customer
              </th>
              <th className="active-border col-label" rowSpan={2}>
                Code SKID
              </th>
              <th className="active-border col-skid-size" colSpan={4}>
                Skid Size
              </th>
              <th className="active-border col-skid-size" colSpan={4}>
                Product Size
              </th>
              <th className="active-border col-skid-asset" rowSpan={2}>
                Store
              </th>
              <th className="active-border col-skid-in" rowSpan={2}>
                Last In
              </th>
              <th className="active-border col-skid-out" rowSpan={2}>
                Last Out
              </th>
            </tr>
            <tr className="active-border">
              <th className="active-border col-size">T</th>
              <th className="active-border col-size">W</th>
              <th className="active-border col-size">L1</th>
              <th className="active-border col-size">L2</th>
              <th className="active-border col-size">T</th>
              <th className="active-border col-size">W</th>
              <th className="active-border col-size">L1</th>
              <th className="active-border col-size">L2</th>
            </tr>
            {newDatas?.map((item: any) => (
              <tr key={item?.no} className="active-border">
                <td className="active-border">{item?.no}</td>
                <td className="active-border">{item?.customer}</td>
                <td className="active-border">{item?.no_label}</td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {zeroSize(item?.sizeProduct?.thick)}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {zeroSize(item?.sizeProduct?.width)}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {zeroSize(item?.sizeProduct?.length1)}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {zeroSize(item?.sizeProduct?.length2)}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.thick}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.width}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.length1}
                </td>
                <td className="active-border" data-t="n" data-z="0.00">
                  {item?.length2}
                </td>
                <td className="active-border">{item?.store}</td>
                <td className="active-border">
                  {item?.lastin
                    ? dayjs(item?.lastin).format("DD-MM-YYYY HH:mm")
                    : "-"}
                </td>
                <td className="active-border">
                  {" "}
                  {item?.lastout
                    ? dayjs.utc(item?.lastout).format("DD-MM-YYYY HH:mm")
                    : "-"}
                </td>
              </tr>
            ))}
          </table>
        </Row>
      </Modal>
    </>
  );
};

export default Preview;
