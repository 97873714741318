import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createClass = (params: any) => {
  return xhrInstance.post("/mtn/class", params);
};

const getClass = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/class", params));
};

const removeClass = (id: string | number) => {
  return xhrInstance.delete(`/mtn/class/${id}`);
};

const updateClass = (params: any) => {
  return xhrInstance.put(`/mtn/class/${params.id}`, params.body);
};

const ClassService = {
  createClass,
  getClass,
  removeClass,
  updateClass,
};

export default ClassService;
