import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { AppDispatch } from "stores";
import { toggleOutDialog } from "stores/part";
import { get, partOut } from "stores/part/actions/part";
import useDialogPart from "pages/mtn/part/sections/hooks/useDialogPart";

const useDialogOut = (dataRecord: any) => {
  const { form, openModal, setFileImg, setImgBase64 } = useDialogPart();
  const { dialogOut, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.part
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleOutDialog(false));
    setFileImg(null);
    setImgBase64("");
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };

      await dispatch(partOut(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dialogOut) {
      openModal({ whitelist: [""] });
    }
  }, [dialogOut]);

  return {
    dialogOut,
    isLoadingUpdate,
    form,
    handleSubmit,
    handleCancel,
  };
};

export default useDialogOut;
