import Breadcrumb from "components/Breadcrumb";

const TitleSkidList = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Print", to: "/print/label" },
    { name: "Pickup SKID", to: "/report/pickup-order" },
  ];

  return <Breadcrumb title="Pickup SKID" items={bItem} />;
};

export default TitleSkidList;
