import PartService from "services/mtns/part/PartService";
import { useState, useRef } from "react";
import { Form } from "antd";

const useDialogPart = () => {
  const [partOption, setParOption] = useState({
    units: [],
    ranks: [],
    categories: [],
    classes: [],
    lines: [],
    makers: [],
    suppliers: [],
    stores: [],
  });

  const [form] = Form.useForm();
  const [fileImg, setFileImg]: any = useState(null);
  const [imgBase64, setImgBase64]: any = useState("");
  const imgRef: any = useRef(null);

  const handleRefImg = () => {
    imgRef.current.click();
  };

  const handleUpload = (event: any) => {
    if (event && event?.target?.files.length > 0) {
      setFileImg(event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImgBase64(reader.result);
      };
      reader.onerror = (err) => {
        console.log(err);
      };
    } else {
      return;
    }
  };

  const toOption = (arr: any) => {
    return arr?.map((item: any) => ({
      value: item?.id,
      label: item?.name,
    }));
  };

  const getUnit = async () => {
    const { data } = await PartService.getPartUnit({});
    setParOption((partOption) => ({
      ...partOption,
      units: toOption(data?.data),
    }));
  };

  const getRank = async () => {
    const { data } = await PartService.getPartRank({});
    setParOption((partOption) => ({
      ...partOption,
      ranks: toOption(data?.data),
    }));
  };

  const getCategory = async () => {
    const { data } = await PartService.getPartCategory({});
    setParOption((partOption) => ({
      ...partOption,
      categories: toOption(data?.data),
    }));
  };

  const getClass = async () => {
    const { data } = await PartService.getPartClass({});
    setParOption((partOption) => ({
      ...partOption,
      classes: toOption(data?.data),
    }));
  };

  const getLine = async () => {
    const { data } = await PartService.getPartLine({});
    setParOption((partOption) => ({
      ...partOption,
      lines: toOption(data?.data),
    }));
  };

  const getMaker = async () => {
    const { data } = await PartService.getPartMaker({});
    setParOption((partOption) => ({
      ...partOption,
      makers: toOption(data?.data),
    }));
  };

  const getSupplier = async () => {
    const { data } = await PartService.getPartSupplier({});
    setParOption((partOption) => ({
      ...partOption,
      suppliers: toOption(data?.data),
    }));
  };

  const getStore = async () => {
    const { data } = await PartService.getPartStore({});
    setParOption((partOption) => ({
      ...partOption,
      stores: data?.data?.map((item: any) => ({
        value: item?.id,
        label: item?.code,
      })),
    }));
  };

  const openModal = async (params?: any) => {
    const listGets = [
      getUnit,
      getRank,
      getCategory,
      getClass,
      getLine,
      getMaker,
      getSupplier,
      getStore,
    ];
    if (params?.whitelist && params?.blacklist) {
      throw Error("can't use both whitelist and blacklist, choose the one");
    } else if (params?.whitelist) {
      await Promise.all(
        listGets
          .filter((item: any) => params?.whitelist.includes(item?.name))
          ?.map((item: any) => item())
      );
    } else if (params?.blacklist) {
      await Promise.all(
        listGets
          .filter((item) => !params?.blacklist.includes(item.name))
          ?.map((item: any) => item())
      );
    } else {
      await Promise.all(listGets?.map((item: any) => item()));
    }
  };

  const openModalCreate = async (params?: any) => {
    getUnit()
    getRank()
    getCategory()
    getClass()
    getLine()
    getMaker()
    getSupplier()
    getStore()
  };



  const openModalUpdate = async (params?: any) => {
    getUnit()
    getStore()
    getLine()
    getSupplier()
    getMaker()
  };

  return {
    partOption,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
    openModal,
    openModalCreate,
    openModalUpdate,
    setFileImg,
    setImgBase64,
  };
};

export default useDialogPart;
