import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Image, Table } from "antd";
import { toggleActionDialog } from "stores/inventory/adjust";
// import { getDetailHistory } from "stores/inventory/adjust/actions/adjust";
import { getDetail } from "stores/inventory/adjust/actions/adjust";
import { useReactToPrint } from "react-to-print";
import * as ExcelJS from "exceljs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const ModalRemark = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { detailData, dialogAction, isLoadingModal } = useSelector(
    (state: any) => state.adjustInv
  );

  const newDetailData = detailData?.map((item: any, index: number) => ({
    ...item,
    no: index + 1,
  }));

  useEffect(() => {
    if (dialogAction) {
      dispatch(getDetail(dataRecord?.id)).unwrap();
    }
  }, [dialogAction]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleActionDialog(false));
  };

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");

    sheet.columns = [
      {
        header: "No",
        key: "no",
        width: 8,
      },
      { header: "Customer", key: "customer", width: 32 },
      {
        header: "Code Skid",
        key: "skid_code",
        width: 40,
      },
      {
        header: "Remark",
        key: "remarks",
        width: 30,
      },
      {
        header: "Time",
        key: "create_time",
        width: 30,
      },
    ];

    const rows = newDetailData?.map((item: any, index: number) => {
      return {
        no: item.no,
        customer: item.customer,
        skid_code: item.label,
        remarks: item.remarks,
        create_time: dayjs(item.create_time).format("DD/MM/YYYY HH:mm"),
      };
    });
    // add new rows and return them as array of row objects
    sheet.addRows(rows);

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "list_inventory.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      align: "center",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 100,
    },
    {
      title: "Code Skid",
      dataIndex: "label",
      key: "label",
      width: 150,
    },

    {
      title: "QR Code",
      key: "store",
      width: 50,
      render: (dataRecord: any) => {
        return <Image width={50} src={dataRecord?.qrcode} />;
      },
    },
    {
      title: "Remark",
      key: "scanned",
      width: 150,
      render: (dataRecord: any) => {
        return dataRecord?.remarks || "";
      },
    },
  ];

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogAction}
        title="Results List Inventory"
        onCancel={() => handleCancel()}
        width={1000}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          detailData.length > 0 && (
            <Button
              // loading={isLoadingUpdate}
              onClick={handleDownload}
              key="download"
              type="primary"
            >
              Donwload
            </Button>
          ),
          detailData.length > 0 && (
            <Button onClick={handlePrint} key="print" type="primary">
              Print
            </Button>
          ),
        ]}
      >
        <Table
          id="my-table-id"
          ref={componentRef}
          bordered
          rowKey={(record: any) => record.assetid}
          loading={isLoadingModal}
          columns={columns}
          dataSource={newDetailData}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default ModalRemark;
