import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createStore = (params: any) => {
  return xhrInstance.post("/mtn/store", params);
};

const getStore = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/store", params));
};

const removeStore = (id: string | number) => {
  return xhrInstance.delete(`/mtn/store/${id}`);
};

const updateStore = (params: any) => {
  return xhrInstance.put(`/mtn/store/${params.id}`, params.body);
};

const StoreService = {
  createStore,
  getStore,
  removeStore,
  updateStore,
};

export default StoreService;
