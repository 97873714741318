import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useMenuSkid from "components/BaseLayout/sections/skid-item";
import useMtnMenu from "components/BaseLayout/sections/mtn-item";

const ProtectedRoute = ({ children }: any) => {
  const auth = useSelector((state: any) => state.auth.lastLogin);
  const { pathname } = useLocation();
  const skidItem = useMenuSkid() ?? [];
  const mtnItem = useMtnMenu() ?? [];
  const parsePath = (items: any) => {
    const newItems = [];
    for (let skid of items) {
      if (skid.children) {
        newItems.push(...skid.children.map((item: any) => item.key));
      } else {
        newItems.push(skid.key);
      }
    }
    return newItems;
  };

  const allPathName = [...parsePath(skidItem), ...parsePath(mtnItem)];

  if (auth ? Object.keys(auth).length > 0 : false) {
    if (!allPathName.includes(pathname)) {
      return <Navigate to={allPathName[0]} />;
    } else {
      return <>{children}</>;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
