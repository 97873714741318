import Breadcrumb from "components/Breadcrumb";

const TitleSkidList = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Print", to: "/print/label" },
    { name: "Label SKID", to: "/print/label" },
  ];

  return <Breadcrumb title="Print Label SKID" items={bItem} />;
};

export default TitleSkidList;
