import { createAsyncThunk } from "@reduxjs/toolkit";
import SkidListService from "services/skids/SkidListService";

export const create = createAsyncThunk(
  "skidAdjuct/create",
  async (params: any) => {
    const { data } = await SkidListService.createSkid(params);
    return data;
  }
);

export const get = createAsyncThunk("skidAdjuct/get", async (params: any) => {
  const { data } = await SkidListService.getSkid(params);
  return data;
});

export const search = createAsyncThunk(
  "skidAdjuct/search",
  async (params: any) => {
    const { data } = await SkidListService.getSkid(params);
    return data;
  }
);

export const remove = createAsyncThunk(
  "skidAdjuct/remove",
  async (id: string | number) => {
    const { data } = await SkidListService.removeSkid(id);
    return data;
  }
);

export const update = createAsyncThunk(
  "skidAdjuct/update",
  async (params: any) => {
    const { data } = await SkidListService.updateSkid(params);
    return data;
  }
);

export const addQty = createAsyncThunk(
  "skidAdjuct/createQty",
  async (params: any) => {
    const { data } = await SkidListService.addQtySkid(params);
    return data;
  }
);
