import { useCallback, useMemo, useEffect, useState } from "react";
import { Card, Input, Row, Col, Button, Tabs, Tooltip } from "antd";
import { PlusOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import DialogCreatePurchase from "pages/mtn/order-history/purchase/sections/action-table/dialog/create";
import DialogCart from "pages/mtn/order-history/purchase/sections/action-table/dialog/cart";
import {
  toggleAddDialog,
  toggleCartDialog,
  setSearch,
  setActiveTab,
  setPartId,
} from "stores/order-history/purchase";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as partAction from "stores/order-history/purchase/actions/purchase";
import debounce from "lodash/debounce";

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    search,
    pageSize,
    isLoadingSearch,
    activeTab,
    optionTabs,
    carts,
    partId,
  } = useSelector((state: any) => state.purchase);

  const sendBackendRequest = useCallback(
    (value: any) => {
      if (activeTab === 1) {
        dispatch(partAction.getLimitPart(value)).unwrap();
      } else {
        dispatch(partAction.search(value)).unwrap();
      }
    },
    [activeTab]
  );

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChangeSearch = (e: any) => {
    const value = e.target.value;
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: value,
      part_number: partId,
    };
    if (activeTab !== 1) {
      params.status_id = activeTab;
    }
    dispatch(setSearch(value));
    debouncedSendRequest(params);
  };

  const handleChangeTab = (tab: string) => {
    dispatch(setActiveTab(tab));
  };

  const onSearch = (val: string) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: val,
      part_number: partId,
    };
    if (activeTab !== 1) {
      params.status_id = activeTab;
    }
    sendBackendRequest(params);
  };

  const sendBackendRequestPartId = useCallback(
    (value: any) => {
      dispatch(partAction.get(value)).unwrap();
    },
    [activeTab]
  );

  const debouncedSendRequestPartId = useMemo(() => {
    return debounce(sendBackendRequestPartId, 500);
  }, [sendBackendRequestPartId]);

  const handleChangeSearchPartId = (e: any) => {
    const value = e.target.value;
    const params: any = {
      page: 1,
      per_page: pageSize,
      part_number: value,
      keyword: search,
    };
    if (activeTab !== 1) {
      params.status_id = activeTab;
    }
    dispatch(setPartId(value));
    debouncedSendRequestPartId(params);
  };

  const onSearchPartId = (val: string) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      part_number: val,
      keyword: search,
    };
    if (activeTab !== 1) {
      params.status_id = activeTab;
    }
    debouncedSendRequestPartId(params);
  };

  return (
    <>
      <Card className="mb-3">
        <Tabs
          activeKey={activeTab || 1}
          onChange={handleChangeTab}
          type="card"
          items={optionTabs}
        />
        {activeTab === 1 ? (
          <Row justify="space-between" gutter={10}>
            <Col span={6}>
              <Input.Search
                loading={isLoadingSearch}
                value={search}
                placeholder="Search"
                onChange={handleChangeSearch}
                onSearch={() => onSearch(search)}
                enterButton
              />
            </Col>
            <Col span={6}></Col>
            <Col span={12}>
              <Row justify="end">
                <Tooltip title="Purchasing Cart">
                  <Button
                    size="large"
                    onClick={() => dispatch(toggleCartDialog(true))}
                    type="primary"
                    icon={<ShoppingCartOutlined className="mr-2" />}
                  >
                    {carts?.length}
                  </Button>
                </Tooltip>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row gutter={10}>
            <Col span={6}>
              <Input.Search
                loading={isLoadingSearch}
                value={search}
                placeholder="Search by PO Number"
                onChange={handleChangeSearch}
                onSearch={() => onSearch(search)}
                enterButton
              />
            </Col>
            <Col span={6}>
              <Input.Search
                // loading={isLoadingSearch}
                value={partId}
                placeholder="Search by Part ID"
                onChange={handleChangeSearchPartId}
                onSearch={() => onSearchPartId(partId)}
                enterButton
              />
            </Col>
            <Col span={6}></Col>
          </Row>
        )}
      </Card>
      <DialogCreatePurchase />
      <DialogCart />
    </>
  );
};

export default ActionTable;
