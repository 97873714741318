import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Pagination, Table } from "antd";
import { get } from "stores/print/label/actions/label";

import { setPageSize } from "stores/print/label";
import DialogPreview from "pages/skid/print/label/sections/table-pagination/dialog/print";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoadingData, currentPage, totalData, search, pageSize, datas } =
    useSelector((state: any) => state.labelPrint);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "7%",
      // fixed: "left",
      align: "center",
    },
    {
      title: "Code SKID",
      dataIndex: "no_label",
      key: "no_label",
      width: "93%",
      // fixed: "left",
    },
  ];

  return (
    <Card>
      <DialogPreview />
      <Table
        bordered
        rowKey={(record: any) => record.assetid}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
