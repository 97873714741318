import { createAsyncThunk } from "@reduxjs/toolkit";
import ApprovalService from "services/mtns/approval/ApprovalServices";


export const get = createAsyncThunk("approvalMtn/get", async (params: any) => {
  const { data } = await ApprovalService.getApproval(params);
  return data;
});

export const search = createAsyncThunk("approvalMtn/search", async (params: any) => {
  const { data } = await ApprovalService.getApproval(params);
  return data;
});


export const update = createAsyncThunk("approvalMtn/update", async (params: any) => {
  const { data } = await ApprovalService.updateApproval(params);
  return data;
});
