import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  get,
  search,
  remove,
  update,
} from "stores/master-data/customer/actions/customer";
import { message } from "antd";

export interface CustomerState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
}

const initialState: CustomerState = {
  dialogAdd: false,
  dialogUpdate: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.rejected, (state, action: any) => {
        state.isLoading = false;
        if (action) {
          message.error(action?.payload?.message);
        } else {
          message.error("Create Customer Failed");
        }
      })
      .addCase(create.fulfilled, (state, action) => {
        state.search = "";
        state.isLoading = false;
        state.dialogAdd = false;
        message.success("Create Customer Success");
      });

    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = action.payload.pages;
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = action.payload.pages;
        state.totalData = action.payload.total;
      });

    builder
      .addCase(remove.pending, (state) => {
        state.isLoadingRemove = true;
      })
      .addCase(remove.rejected, (state, action: any) => {
        state.isLoadingRemove = false;
        message.error("Delete Customer Failed");
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingRemove = false;
        message.success("Delete Customer Success");
      });

    builder
      .addCase(update.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(update.rejected, (state, action: any) => {
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.error("Update Customer Failed");
      })
      .addCase(update.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.success("Update Customer Success");
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  setSearch,
  setPageSize,
} = customerSlice.actions;

export default customerSlice.reducer;
