import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getApproval = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/approval", params));
};

const listStatusApproval = () => {
  return xhrInstance.get(`/mtn/approval/list-status`);
};

const updateApproval = (params: any) => {
  return xhrInstance.put(`/mtn/approval/update-status/${params.id}`, params.body);
};

const ApprovalService = {
  getApproval,
  listStatusApproval,
  updateApproval,
};

export default ApprovalService;
