import { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Input, Row, Col, Button, message } from "antd";
import { setSearch, toggleAddDialog } from "stores/mtnInventory/process";
import { PlusOutlined, RetweetOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as storeAction from "stores/mtnInventory/process/actions/process";
import debounce from "lodash/debounce";
import ProcessService from "services/mtns/inventory/ProcessServices";

const ActionTable = () => {
  const navigate = useNavigate();
  const uploadRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch } = useSelector(
    (state: any) => state.mtnProcessInv
  );

  const sendBackendRequest = useCallback((value: string) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: value,
    };
    dispatch(storeAction.search(params)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    dispatch(setSearch(value));
    debouncedSendRequest(value);
  };

  const handleClickUpload = async (event: any) => {
    try {
      const value = event.target.files[0];
      const form = new FormData();
      form.append("file", value);
      await ProcessService.uploadProcess(form);
      await dispatch(storeAction.get({ page: 1, per_page: 10 })).unwrap();
      message.success("Upload success");
    } catch (err: any) {
      if (err?.response?.status === 500) {
        message.error(err?.response?.data?.message);
      } else {
        message.error("Upload error");
      }
    }
  };

  const handleUpload = () => {
    uploadRef.current.click();
  };

  return (
    <>
      <Card className="mb-3">
        <input
          onChange={handleClickUpload}
          ref={uploadRef}
          type="file"
          hidden
          accept=".xlsx"
        />
        <Row justify="space-between">
          <Col span={6}>
            <Input.Search
              loading={isLoadingSearch}
              value={search}
              placeholder="Search"
              onChange={handleChange}
              onSearch={() => sendBackendRequest(search)}
              enterButton
            />
          </Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Row justify="end">
              <Button
                className="mr-3"
                onClick={() => handleUpload()}
                type="primary"
                icon={<PlusOutlined />}
              >
                Upload
              </Button>
              <Button
                onClick={() => navigate("/mtn/inventory/compare")}
                type="primary"
                icon={<RetweetOutlined />}
              >
                Compare
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ActionTable;
