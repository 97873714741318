import { useRoutes } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import BaseLayout from "components/BaseLayout";
import SkidSumarry from "pages/skid/sumarry";
import MtnSumarry from "pages/mtn/sumarry";
import Profile from "pages/auth/profile";

import auth from "routes/auth";
import masterData from "routes/mater_data";
import skid from "routes/skid";
import report from "routes/report";
import print from "routes/print";
import user from "routes/user";
import invent from "routes/inventory";

import masterDataMtn from "routes/mtn-master-data";
import orderHistory from "routes/order-history";
import Approval from "pages/mtn/approval";
import inventoryMtn from "routes/mtnInventory";
import ReportMtn from "pages/mtn/report";
import part from "routes/part";

const Router: any = ({ isLoggedIn }: any) => {
  return useRoutes([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <BaseLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <SkidSumarry />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        ...masterData,
        ...skid,
        ...report,
        ...print,
        ...user,
        ...invent,
      ],
    },
    {
      path: "/mtn",
      element: (
        <ProtectedRoute>
          <BaseLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <MtnSumarry />,
        },
        {
          path: "/mtn/summary",
          element: <MtnSumarry />,
        },
        ...part,
        ...masterDataMtn,
        ...orderHistory,
        {
          path: "/mtn/approval",
          element: <Approval />,
        },
        ...inventoryMtn,
        {
          path: "/mtn/report",
          element: <ReportMtn />,
        },
      ],
    },
    ...auth,
  ]);
};

export default Router;
