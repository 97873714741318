import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Input, Col, Row } from "antd";
import { toggleUpdateDialog } from "stores/master-data/customer";
import { get, update } from "stores/master-data/customer/actions/customer";

const { TextArea } = Input;

const UpdateCustomer = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogUpdate, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.customer
  );

  useEffect(() => {
    form.setFieldsValue({
      name: dataRecord?.name,
      code: dataRecord?.code,
      address: dataRecord?.address,
      email: dataRecord?.email,
      phone: dataRecord?.phone,
    });
  }, [dialogUpdate]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };
      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogUpdate}
        title="Edit Customer"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={16}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="code" label="Code" rules={[{ required: true }]}>
                <Input disabled placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true }]}
          >
            <TextArea rows={3} placeholder="Type here..." />
          </Form.Item>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label="Telp">
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCustomer;
