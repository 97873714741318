import Rank from "pages/mtn/master_data/rank";
import Store from "pages/mtn/master_data/store";
import Supplier from "pages/mtn/master_data/supplier";
import Class from "pages/mtn/master_data/class";
import Line from "pages/mtn/master_data/line";
import Unit from "pages/mtn/master_data/unit";
import Maker from "pages/mtn/master_data/maker";

const materData = [
  {
    path: "/mtn/master-data/rank",
    element: <Rank />,
  },
  {
    path: "/mtn/master-data/store",
    element: <Store />,
  },
  {
    path: "/mtn/master-data/supplier",
    element: <Supplier />,
  },
  {
    path: "/mtn/master-data/class",
    element: <Class />,
  },
  {
    path: "/mtn/master-data/line",
    element: <Line />,
  },
  {
    path: "/mtn/master-data/unit",
    element: <Unit />,
  },
  {
    path: "/mtn/master-data/maker",
    element: <Maker />,
  },
];

export default materData;
