import { Typography, Card } from "antd";
import SummaryPart from "pages/mtn/sumarry/section/part";
import SummaryPartPrice from "pages/mtn/sumarry/section/part-price";
import SummaryPartApproval from "pages/mtn/sumarry/section/part-approval";
import SummaryPartRepair from "pages/mtn/sumarry/section/part-reapir";
import SummaryPartPo from "pages/mtn/sumarry/section/part-po";

const { Title } = Typography;

const Sumarry = () => {
  return (
    <>
      <Card>
        <Title style={{ textAlign: "center" }} level={2}>
          Summary MTN
        </Title>

        <SummaryPart />
        <SummaryPartPrice />
        <SummaryPartApproval />
        <SummaryPartRepair />
        <SummaryPartPo />
      </Card>
    </>
  );
};

export default Sumarry;
