import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const part = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/summary/part-summary", params));
};

const partPrice = (params: any) => {
    return xhrInstance.get(objToParams("/mtn/summary/part-price-summary", params));
  };

  const partApproval = (params: any) => {
    return xhrInstance.get(objToParams("/mtn/summary/part-approval-summary", params));
  };

  const partRepair = (params: any) => {
    return xhrInstance.get(objToParams("/mtn/summary/part-repair-summary", params));
  };

  const partPo = (params: any) => {
    return xhrInstance.get(objToParams("/mtn/summary/part-po-summary", params));
  };

const SummaryMtn = {
  part,
  partPrice,
  partApproval,
  partRepair,
  partPo
};

export default SummaryMtn;
