import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  get,
  search,
  remove,
  update,
  addQty,
} from "stores/skid/list/actions/list";
import { message } from "antd";

export interface SkidListState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  dialogPrint: boolean;
  dialogAddQuantity: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  printDatas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  thick: string;
  width: string;
  length1: string;
  length2: string;
}

const initialState: SkidListState = {
  dialogAdd: false,
  dialogUpdate: false,
  dialogPrint: false,
  dialogAddQuantity: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  printDatas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  thick: "",
  width: "",
  length1: "",
  length2: "",
};

export const skidListSlice = createSlice({
  name: "skidList",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    togglePrintDialog: (state, action) => {
      state.dialogPrint = action.payload;
    },
    toggleAddQuantityDialog: (state, action) => {
      state.dialogAddQuantity = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setThick: (state, action) => {
      state.thick = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    setLength1: (state, action) => {
      state.length1 = action.payload;
    },
    setLenght2: (state, action) => {
      state.length2 = action.payload;
    },
    resetSkidList: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.rejected, (state, action: any) => {
        state.isLoading = false;
        message.error(action.error.message);
      })
      .addCase(create.fulfilled, (state, action) => {
        state.printDatas = action.payload.data;
        state.search = "";
        state.isLoading = false;
        state.dialogAdd = false;
        message.success("Create Skid Success");
        state.dialogPrint = true;
      });

    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = action.payload.pages;
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = action.payload.pages;
        state.totalData = action.payload.total;
      });

    builder
      .addCase(remove.pending, (state) => {
        state.isLoadingRemove = true;
      })
      .addCase(remove.rejected, (state, action: any) => {
        state.isLoadingRemove = false;
        message.error("Delete Skid Failed");
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingRemove = false;
        message.success("Delete Skid Success");
      });

    builder
      .addCase(update.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(update.rejected, (state, action: any) => {
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.error("Update Skid Failed");
      })
      .addCase(update.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.success("Update Skid Success");
      });

    //add qty
    builder
      .addCase(addQty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQty.rejected, (state, action: any) => {
        state.isLoading = false;
        state.dialogAddQuantity = false;
        message.error("Quantity Skid Failed Added");
      })
      .addCase(addQty.fulfilled, (state, action) => {
        state.search = "";
        state.isLoading = false;
        state.printDatas = action.payload.data;
        state.dialogAddQuantity = false;
        message.success("Quantity Skid Success Added");
        state.dialogPrint = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  togglePrintDialog,
  toggleAddQuantityDialog,
  setSearch,
  setPageSize,
  setThick,
  setWidth,
  setLength1,
  setLenght2,
  resetSkidList,
} = skidListSlice.actions;

export default skidListSlice.reducer;
