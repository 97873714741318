import User from "pages/skid/user";

const userRoute = [
  {
    path: "/users",
    element: <User />,
  },
];

export default userRoute;
