import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import HeaderLayout from "components/BaseLayout/sections/Header";
import FooterLayout from "components/BaseLayout/sections/Footer";
import SidebarLayout from "components/BaseLayout/sections/Sidebar";
import style from "assets/styles/layout.module.css";

const { Content } = Layout;

const BaseLayout = () => {
  const [collapsed, setCollapsed] = useState<any>(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <HeaderLayout />
      <Layout hasSider>
        <SidebarLayout collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout style={{ marginLeft: !collapsed ? "254px" : "80px" }}>
          <Content>
            <div className={style.contentMod}>
              <div className={style.content}>
                <Outlet />
              </div>
              <FooterLayout />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
