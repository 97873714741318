import { createAsyncThunk } from "@reduxjs/toolkit";
import MasterDataService from "services/skids/MasterDataService";

export const create = createAsyncThunk(
  "customer/create",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await MasterDataService.createCustomer(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get = createAsyncThunk("customer/get", async (params: any) => {
  const { data } = await MasterDataService.getCustomer(params);
  return data;
});

export const search = createAsyncThunk(
  "customer/search",
  async (params: any) => {
    const { data } = await MasterDataService.getCustomer(params);
    return data;
  }
);

export const remove = createAsyncThunk(
  "customer/remove",
  async (id: string | number) => {
    const { data } = await MasterDataService.removeCustomer(id);
    return data;
  }
);

export const update = createAsyncThunk(
  "customer/update",
  async (params: any) => {
    const { data } = await MasterDataService.updateCustomer(params);
    return data;
  }
);
