import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Col, Row, Select } from "antd";
import { toggleAddDialog } from "stores/print/delivery_order";
import { AppDispatch } from "stores";
import { get, create } from "stores/print/delivery_order/actions/delivey_order";
import MasterDataService from "services/skids/MasterDataService";
import dayjs from "dayjs";

const CreateDeliveryOrder = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const { dialogAdd, pageSize, isLoading, date, order, search } = useSelector(
    (state: any) => state.deliveryOrder
  );

  const getOptions = async () => {
    const res = await MasterDataService.getCustomer({
      page: 1,
      per_page: 10000,
    });
    const newData = res?.data?.data?.map((item: any) => ({
      ...item,
      value: item?.id,
      label: item.name,
    }));
    setOptions(newData);
  };

  useEffect(() => {
    if (dialogAdd) {
      getOptions();
    }
  }, [dialogAdd]);

  const onCancel = () => {
    form.resetFields();
    dispatch(toggleAddDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(create(values)).unwrap();
      const params: any = {
        page: 1,
        per_page: pageSize,
        keyword: search,
        customer: order,
      };

      if (date.length > 0) {
        params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
        params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
      }
      await dispatch(get(params)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        open={dialogAdd}
        title="Create Delivery Order"
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="order_by"
                label="Order By"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={options}
                />
              </Form.Item>
            </Col>
            </Row>

            <Row>
            <Col span={24}>
              <Form.Item
                name="recieved_by"
                label="Received By"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={options}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateDeliveryOrder;
