import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Row } from "antd";
import {
  togglePreviewDialog,
  setPreviewDatas,
} from "stores/print/pickup_order";
import Logo from "assets/images/sci_logo.jpeg";
import css from "assets/styles/OrderDelivery.module.css";
import dayjs from "dayjs";
import JsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

const UpdateCustomer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const componentRef: any = useRef();

  const { dialogPreview, previewDatas } = useSelector(
    (state: any) => state.pickupOrder
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCancel = () => {
    dispatch(togglePreviewDialog(false));
    dispatch(setPreviewDatas({}));
  };

  const toPdf = () => {
    const el: any = document.querySelector("#report");
    const pdf = new JsPDF();
    pdf
      .html(el, {
        margin: [11, 11, 11, 11],
        autoPaging: "slice",
        x: 0,
        y: 0,
        width: 190,
        windowWidth: 675,
      })
      .then(() => {
        pdf.save("order_delivery.pdf");
      });
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogPreview}
        title="Preview"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button onClick={handlePrint} key="print" type="primary">
            Print
          </Button>,
          <Button onClick={toPdf} key="download" type="primary">
            Download
          </Button>,
        ]}
        width={"210mm"}
      >
        <div ref={componentRef} id="report" className={css.wrap_main}>
          <Row>
            <div className={css.section_main}>
              <div className={css.wrap_section_one}>
                <div className={css.wrap_logo}>
                  <img className={css.logo} src={Logo} alt="cop" />
                </div>
                <div className={css.wrap_title}>
                  <div className={css.text_title}>
                    PT. STEEL CENTER INDONESIA
                  </div>
                  <div>JL. AGUNG KARYAVI BLOK A NO. 1 PAPANGGO</div>
                  <div>TG. PRIOK, JAKARTA 14340 - INDONESIA</div>
                  <div>Phone: 62 21-6512800</div>
                  <div>Fax: 62 21-6509443 / 6514155</div>
                </div>
              </div>

              <div className={css.wrap_section_two}>
                <div className={css.text_right}></div>
                <div className={css.text_right_two}>
                  <div className={css.delivery_note}>
                    <div>Delivery Noted No:</div>
                    <div>{previewDatas?.surat_jalan}</div>
                  </div>
                  <div className={css.delivery_note}>
                    <div>Delivery Date:</div>
                    <div>
                      {dayjs.utc(previewDatas?.generated).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.wrap_section_three}>
                <div className={css.wrap_orderby}>Pickup Skid By:</div>
                <div className={css.address}>
                  <div>PT. STEEL CENTER INDONESIA</div>
                  <div>JL. AGUNG KARYAVI BLOK A NO. 1 PAPANGGO</div>
                  <div>TG. PRIOK, JAKARTA 14340 - INDONESIA</div>
                </div>
              </div>

              <div className={css.wrap_section_four}>
                <div className={css.wrap_orderby}>Sender By:</div>
                <div className={css.address}>
                  <div>{previewDatas?.sender_by?.name}</div>
                  <div>{previewDatas?.sender_by?.address}</div>
                </div>
              </div>
            </div>
          </Row>
          <br />
          <Row>
            <table className={css.table}>
              <tr className={css.border_data}>
                <th className={css.no_table}>No</th>
                <th colSpan={8} className={css.skid_product}>
                  Skid Id
                </th>
                {/* <th colSpan={5} className={css.skid_product}>
                  SCI No Product
                </th> */}
              </tr>
              {previewDatas?.skid?.map((item: any, index: number) => (
                <tr key={item.id} className={css.border_data}>
                  <td>{index + 1}</td>
                  <td colSpan={8}>{item?.skid}</td>
                  {/* <td colSpan={5}>{item?.product}</td> */}
                </tr>
              ))}
              <br />
              <tr className={css.border_data}>
                <td colSpan={2} className={css.border_bottom}>
                  Received By:
                </td>
                <td className={css.border_bottom}>Driver:</td>
                <td className={css.border_bottom}>Loaded By:</td>
                <td className={css.border_bottom}>Checked By:</td>
                <td className={css.border_bottom}>Delivered By:</td>
                <td className={css.border_bottom}>Approved By:</td>
              </tr>
              <tr className={css.border_data}>
                <td colSpan={2}>
                  <br />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default UpdateCustomer;
