import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Input, Col, Row, Select } from "antd";
import { toggleUpdateDialog } from "stores/skid/list";
import { get, update } from "stores/skid/list/actions/list";
import { get as getStore } from "stores/master-data/store/actions/store";

const UpdateSkid = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogUpdate, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.skidList
  );

  const initGetData = async () => {
    const req: any = {
      page: 1,
      per_page: 100,
    };
    await dispatch(getStore(req)).unwrap();
  };

  const stores: any = useSelector((state: any) => state.store.datas)?.map(
    (item: any) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  // useEffect(() => {
  //   initGetData();
  // }, []);

  useEffect(() => {
    if (dialogUpdate) {
      initGetData();

      form.setFieldsValue({
        label: dataRecord?.no_label,
        store: dataRecord?.store?.id,
      });
    }
  }, [dialogUpdate]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.assetid,
        body: { store: values?.store },
      };
      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogUpdate}
        title="Edit Skid"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name="label" label="Code SKID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="store"
                label="Store"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={stores}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateSkid;
