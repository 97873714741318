import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createUser = (params: any) => {
  return xhrInstance.post("/users/register", params);
};

const getUser = (params: any) => {
  return xhrInstance.get(objToParams("/users", params));
};

const removeUser = (id: string | number) => {
  return xhrInstance.delete(`/users/delete/${id}`);
};

const updateUser = (params: any) => {
  return xhrInstance.put(`/users/update/${params.id}`, params.body);
};

const getRole = () => {
  return xhrInstance.get("/users/roles");
};

const MasterDataService = {
  createUser,
  getUser,
  removeUser,
  updateUser,
  getRole,
};

export default MasterDataService;
