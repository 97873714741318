import React from "react";
import ActionTable from "pages/mtn/part/in/section/action-table";
import Title from "pages/mtn/part/in/section/title";
import TablePagination from "pages/mtn/part/in/section/table-pagination";

const index = () => {
  return (
    <div>
      <Title />
      <ActionTable />
      <TablePagination />
    </div>
  );
};

export default index;
