import dayjs from "dayjs";

function downloader(data: Blob, filename: string, ext: string) {
  const date = dayjs().format("DDMMYYYY");
  const href = window.URL.createObjectURL(data);

  const anchorElement = document.createElement("a");

  anchorElement.href = href;

  anchorElement.download = `${filename}_${date}${ext}`;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);

  window.URL.revokeObjectURL(href);
}

export default downloader;
