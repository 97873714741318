import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Master Data", to: "/master-data/customer" },
    { name: "Customer", to: "/master-data/customer" },
  ];

  return <Breadcrumb title="Customer" items={bItem} />;
};

export default TitleStore;
