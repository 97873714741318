import Breadcrumb from "components/Breadcrumb";

const TitlePartIn = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Data Part", to: "/mtn/part" },
    { name: "Part In", to: "/mtn/part/in" },
  ];

  return <Breadcrumb title="Part In" items={bItem} />;
};

export default TitlePartIn;
