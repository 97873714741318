import { Breadcrumb, Typography, Card, Button, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Breadcrumbs = ({ title, items, back = false }: any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Card className="mb-5">
      <Breadcrumb>
        {items.map((item: any, i: number) => {
          return (
            <Breadcrumb.Item key={i}>
              {pathname === item.to ? (
                item.name
              ) : (
                <Link to={item.to}>{item.name}</Link>
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      <Row align="middle">
        {back && (
          <Button
            onClick={() => navigate(-1)}
            className="mr-2"
            size="small"
            type="primary"
            shape="circle"
            icon={<ArrowLeftOutlined />}
          />
        )}

        <Title className="mb-0" level={3}>
          {title}
        </Title>
      </Row>
    </Card>
  );
};

export default Breadcrumbs;
