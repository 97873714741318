import { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row } from "antd";
import { useReactToPrint } from "react-to-print";

import { togglePreviewDialog } from "stores/print/label";
import { AppDispatch } from "stores";
import { print } from "stores/print/label/actions/label";

const DialogPreview = () => {
  const dispatch = useDispatch<AppDispatch>();
  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { dialogPreview, printDatas, search } = useSelector(
    (state: any) => state.labelPrint
  );

  const onCancel = () => {
    dispatch(togglePreviewDialog(false));
  };

  const handleSubmit = async () => {
    try {
      handlePrint();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dialogPreview) {
      const params: any = {};

      if (search) {
        params.keyword = search;
      }
      dispatch(print(params)).unwrap();
    }
  }, [dialogPreview]);

  return (
    <>
      <Row className="mb-5" justify="end">
        <Button
          onClick={() => dispatch(togglePreviewDialog(true))}
          type="primary"
        >
          Preview
        </Button>
      </Row>

      <Modal
        width={"210mm"}
        open={dialogPreview}
        title="Print Skid"
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            // loading={isLoading}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Print
          </Button>,
        ]}
      >
        <div>
          <div
            ref={componentRef}
            style={{
              display: "flex",
              width: "210mm",
              alignItems: "center",
              flexWrap: "wrap",
              // marginTop: "px",
            }}
          >
            {printDatas?.map((item: any, index: number) => (
              <div key={index}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "700",
                    width: "3cm",
                    height: "3cm",
                    marginBottom: "1cm",
                    border: "1px solid black",
                    marginRight: "0.5cm",
                    marginLeft: "0.5cm",
                  }}
                >
                  <img
                    style={{ width: "2.2cm", height: "2.2cm" }}
                    src={item?.qr}
                  />
                  <div style={{ fontSize: 8 }}>{item?.no_label}</div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "700",
                    width: "3cm",
                    height: "3cm",
                    marginBottom: "1cm",
                    border: "1px solid black",
                    marginRight: "0.5cm",
                    marginLeft: "0.5cm",
                  }}
                >
                  <img
                    style={{ width: "2.2cm", height: "2.2cm" }}
                    src={item?.qr}
                  />
                  <div style={{ fontSize: 8 }}>{item?.no_label}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DialogPreview;
