import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Pagination, Table, Image } from "antd";
import { get } from "stores/skid/in/actions/in";
import { setPageSize } from "stores/skid/in";
import zeroSize from "utils/zeroSize";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    thick,
    width,
    length1,
    length2,
  } = useSelector((state: any) => state.skidIn);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: search,
      thick,
      width,
      length1,
      length2,
    };

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      align: "center",
    },
    {
      title: "Customer",
      key: "customer",
      width: 100,
      render: (dataRecord: any) => dataRecord?.customer?.name,
    },
    {
      title: "Code SKID",
      dataIndex: "no_label",
      key: "no_label",
      width: 150,
    },
    {
      title: "Size SKID",
      children: [
        {
          title: "T",
          key: "skidT",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.thick),
        },
        {
          title: "W",
          key: "skidW",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.width),
        },
        {
          title: "L1",
          key: "skidL1",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length1),
        },
        {
          title: "L2",
          key: "skidL2",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length2),
        },
      ],
    },
    {
      title: "Store",
      key: "store",
      width: 150,
      render: (dataRecord: any) => dataRecord?.store?.name,
    },
    {
      title: "QR Code",
      key: "phone",
      width: 100,
      render: (dataRecord: any) => {
        return <Image width={50} src={dataRecord?.qr} />;
      },
    },
  ];

  return (
    <Card>
      <Table
        bordered
        rowKey={(record: any) => record.assetid}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
