import { Card, Input, Row, Col, Button, Select, Space, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DialogCreateStore from "pages/mtn/master_data/store/sections/action-table/dialog/create";
import { useSelector } from "react-redux";
import useFilter from "pages/mtn/report/sections/action-table/useFilter";

const { RangePicker } = DatePicker;

const ActionTable = () => {
  const {
    sendBackendRequest,
    handleChangeSearch,
    options,
    handleChangeStatus,
    handleChangeType,
    handleChangeDate,
    handleDownload,
    type,
  } = useFilter();
  const { search, isLoadingSearch, date } = useSelector(
    (state: any) => state.mtnReport
  );

  return (
    <>
      <Card className="mb-3">
        <Row gutter={10} className="mb-3">
          <Col span={6}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Date</div>
              <RangePicker
                format={"DD/MM/YYYY"}
                onChange={handleChangeDate}
                style={{ width: "100%" }}
              />
            </Space>
          </Col>
          <Col span={9}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Status</div>
              <Select
                onChange={handleChangeStatus}
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={options?.status}
              />
            </Space>
          </Col>
          <Col span={9}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Input Type</div>
              <Select
                onChange={handleChangeType}
                value={type}
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={options?.types}
              />
            </Space>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-3">
          <Col span={8}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Search</div>
              <Input.Search
                loading={isLoadingSearch}
                value={search}
                placeholder="Search Part Number or Name"
                onChange={handleChangeSearch}
                onSearch={() => sendBackendRequest(search)}
                enterButton
              />
            </Space>
          </Col>
          <Col span={12}>
            <Row justify="end" align="bottom" style={{ height: "100%" }}>
              <Button
                disabled={!date ? true : false}
                onClick={handleDownload}
                type="primary"
                icon={<PlusOutlined />}
              >
                Download
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <DialogCreateStore />
    </>
  );
};

export default ActionTable;
