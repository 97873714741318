import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Approval", to: "/mtn/approval" },
  ];

  return <Breadcrumb title="Approval" items={bItem} />;
};

export default TitleStore;
