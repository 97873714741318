import { createAsyncThunk } from "@reduxjs/toolkit";
import ReportMtnService from "services/mtns/report/ReportMtnService";


export const get = createAsyncThunk("reportMtn/get", async (params: any) => {
  const { data } = await ReportMtnService.getReport(params);
  return data;
});

export const search = createAsyncThunk("reportMtn/search", async (params: any) => {
  const { data } = await ReportMtnService.getReport(params);
  return data;
});


