import { useEffect, useState } from "react";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { setUpdates, setFillUpdates } from "stores/partIn";
import Breadcrumb from "components/Breadcrumb";
import { useParams, useLocation } from "react-router-dom";
import PartInService from "services/mtns/part/PartInService";
import {
  Table,
  Card,
  Button,
  Tooltip,
  Modal,
  Row,
  InputNumber,
  Input,
  message,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const DetailPO = () => {
  const { updates } = useSelector((state: any) => state.partIn);
  const dispatch = useDispatch<AppDispatch>();
  const { poId }: any = useParams();
  const { state } = useLocation();
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Data Part", to: "/mtn/part" },
    { name: "List PO", to: "/mtn/part/in" },
    { name: "Detail PO", to: `/mtn/part/in/po/${poId}/detail` },
  ];

  const [detailPO, setDetailPO] = useState({ datas: [], isLoading: false });
  const getDetail = async () => {
    setDetailPO((val) => ({ ...val, isLoading: true }));
    const { data } = await PartInService.detailPO(poId);
    setDetailPO((val) => ({ ...val, isLoading: false, datas: data?.data }));
  };

  useEffect(() => {
    getDetail();
    return () => {
      dispatch(setFillUpdates([]));
    };
  }, []);

  const [modalUpdate, setModalUpdate] = useState(false);

  const onCancel = () => {
    setModalUpdate(false);
  };

  const columns: any = [
    {
      title: "ID Part",
      dataIndex: "part_number",
      key: "id_part",
      width: 150,
      fixed: "left",
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: 150,
      fixed: "left",
    },
    {
      title: "Maker",
      dataIndex: "maker_name",
      key: "maker",
      width: 150,
      fixed: "left",
      render: (record: any) => (record ? record : "-"),
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier",
      width: 150,
      fixed: "left",
    },
    {
      title: "Store",
      dataIndex: "store_code",
      key: "store",
      width: 150,
      fixed: "left",
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      key: "unit_name",
      width: 150,
      fixed: "left",
    },

    {
      title: "Options",
      key: "options",
      width: 10,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="center">
            <Tooltip title="Add to Update">
              <Button
                disabled={
                  updates?.filter((item: any) => item?.id === dataRecord?.id)
                    ?.length > 0
                    ? true
                    : false
                }
                onClick={() =>
                  dispatch(
                    setUpdates({
                      ...dataRecord,
                      remarks: "",
                      qty: 0,
                      qtyOne: dataRecord.qty,
                    })
                  )
                }
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const handleChangeQty = (val: any, id: number | string) => {
    const newUpdates = updates?.map((u: any) => {
      if (u.id === id) {
        return { ...u, qty: val };
      } else {
        return u;
      }
    });
    dispatch(setFillUpdates(newUpdates));
  };

  const handleChangeRemark = (event: any, id: number | string) => {
    const value = event.target.value;
    const newUpdates = updates?.map((u: any) => {
      if (u.id === id) {
        return { ...u, remarks: value };
      } else {
        return u;
      }
    });
    dispatch(setFillUpdates(newUpdates));
  };

  //table modal
  const columnModals: any = [
    // {
    //   title: "No",
    //   dataIndex: "no",
    //   key: "no",
    //   width: 60,
    //   align: "center",
    // },
    {
      title: "ID Part",
      dataIndex: "part_number",
      key: "part_number",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "part_name",
      key: "name",
      width: 150,
    },
    {
      title: "Quantity PO",
      dataIndex: "qtyOne",
      key: "qtyPo",
      width: 100,
      align: "center",
    },
    {
      title: "Quantity IN",
      key: "qty",
      width: 70,
      render: (record: any) => {
        return (
          <InputNumber
            onChange={(val) => handleChangeQty(val, record.id)}
            value={record?.qty}
            min={1}
          />
        );
      },
    },
    {
      title: "Remarks",
      key: "remarks",
      width: 250,
      render: (record: any) => {
        return (
          <TextArea
            onChange={(event) => handleChangeRemark(event, record.id)}
            value={record?.remarks}
            rows={1}
          />
        );
      },
    },
    // {
    //   title: "Maker",
    //   dataIndex: "maker_name",
    //   key: "maker_name",
    //   width: 150,
    //   render: (r: any) => r || "-",
    // },
    // {
    //   title: "Supplier",
    //   dataIndex: "supplier_name",
    //   key: "supplier_name",
    //   width: 150,
    //   render: (r: any) => r || "-",
    // },
    // {
    //   title: "Store",
    //   dataIndex: "store_code",
    //   key: "store"
    //   width: 150,
    // },
    {
      title: "Options",
      key: "options",
      width: 100,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="center">
            <Tooltip title="Remove part">
              <Button
                onClick={() => {
                  const newUpdates = updates.filter(
                    (u: any) => u.id !== dataRecord?.id
                  );
                  dispatch(setFillUpdates(newUpdates));
                }}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      const req = updates?.map((value: any) => ({
        pod_id: value.pod_id,
        qty: value.qty,
        remarks: value.remarks,
      }));
      const params = {
        id: parseInt(poId),
        body: {
          data_parts: req,
        },
      };
      await PartInService.updatePartPO(params);
      await getDetail();
      dispatch(setFillUpdates([]));
      setLoadingSubmit(false);
      message.success("Update Quantity Part Success");
    } catch (err) {
      setLoadingSubmit(false);
      message.error("Update Quantity Part Failed");
      console.log(err);
    }
  };

  return (
    <>
      <Breadcrumb title="Detail Purchase Order" items={bItem} back={true} />

      <Card>
        <h2>PO Number: {state?.po_number}</h2>
        <Row justify={"center"}>
          <div
            onClick={() => setModalUpdate(true)}
            style={{
              padding: "8px 10px",
              border: "1px solid #f4801a",
              borderRadius: "8px",
              width: "fit-content",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <div>Update Part</div>
            <div
              style={{ fontWeight: 600, fontSize: "30px", textAlign: "center" }}
            >
              {updates?.length || 0}
            </div>
          </div>
        </Row>
        <Table
          // rowKey={(record: any) => record.id}
          loading={detailPO.isLoading}
          columns={columns}
          dataSource={detailPO.datas}
          pagination={false}
          // scroll={{ x: "calc(700px + 50%)" }}
        />
      </Card>
      <Modal
        open={modalUpdate}
        width={1000}
        title={`Update Part in Number PO ${state.po_number}`}
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingSubmit}
            onClick={handleSubmit}
            disabled={updates.length > 0 ? false : true}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Table
          // rowKey={(record: any) => record.id}
          loading={detailPO.isLoading}
          columns={columnModals}
          dataSource={updates}
          pagination={false}
          // scroll={{ x: "calc(700px + 50%)" }}
        />
      </Modal>
    </>
  );
};

export default DetailPO;
