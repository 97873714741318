import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createMaker = (params: any) => {
  return xhrInstance.post("/mtn/maker", params);
};

const getMaker = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/maker", params));
};

const removeMaker = (id: string | number) => {
  return xhrInstance.delete(`/mtn/maker/${id}`);
};

const updateMaker = (params: any) => {
  return xhrInstance.put(`/mtn/maker/${params.id}`, params.body);
};

const MakerService = {
  createMaker,
  getMaker,
  removeMaker,
  updateMaker,
};

export default MakerService;
