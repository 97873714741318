import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Pagination, Table, Image } from "antd";

import { get } from "stores/skid/out/actions/out";
import { setPageSize } from "stores/skid/out";
import zeroSize from "utils/zeroSize";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoadingData, currentPage, totalData, search, pageSize, datas } =
    useSelector((state: any) => state.skidOut);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      // fixed: "left",
      align: "center",
    },
    {
      title: "Customer",
      // dataIndex: "customer",
      key: "customer",
      width: 100,
      render: (dataRecord: any) => dataRecord?.customer?.name,

      // fixed: "left",
    },
    {
      title: "Code SKID",
      dataIndex: "no_label",
      key: "no_label",
      width: 150,
      // fixed: "left",
    },
    {
      title: "Size SKID",
      children: [
        {
          title: "T",
          key: "skidT",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.thick),
        },
        {
          title: "W",
          key: "skidW",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.width),
        },
        {
          title: "L1",
          key: "skidL1",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length1),
        },
        {
          title: "L2",
          key: "skidL2",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length2),
        },
      ],
    },
    {
      title: "Size Product",
      children: [
        {
          title: "T",
          key: "skidTP",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.product?.thick),
        },
        {
          title: "W",
          key: "skidWP",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.product?.width),
        },
        {
          title: "L1",
          key: "skidL1P",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.product?.length1),
        },
        {
          title: "L2",
          key: "skidL2P",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.product?.length2),
        },
      ],
    },
    {
      title: "QR Code SKID",
      // dataIndex: "phone",
      key: "phone",
      width: 100,
      render: (dataRecord: any) => {
        return <Image width={50} src={dataRecord?.qr} />;
        // return <img src={dataRecord?.qr} width="50px" height="50px" />;
      },
      // fixed: "left",
    },
    {
      title: "QR Code Product",
      // dataIndex: "phone",
      key: "phone",
      width: 100,
      render: (dataRecord: any) => {
        return <Image width={50} src={dataRecord?.qrproduct} />;
        // return <img src={dataRecord?.qrproduct} width="50px" height="50px" />;
      },
      // fixed: "left",
    },
  ];

  return (
    <Card>
      <Table
        bordered
        rowKey={(record: any) => record.asset}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
        scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
