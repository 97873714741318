import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getRecap = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/inventory/recap/list", params));
};

const downloadRecap = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/inventory/recap/download", params), {
    responseType: "blob",
  });
};

const RecapServices = {
  getRecap,
  downloadRecap,
};

export default RecapServices;
