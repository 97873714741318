import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row } from "antd";
import { useReactToPrint } from "react-to-print";

import { togglePrintDialog } from "stores/skid/list";
import { AppDispatch } from "stores";

const PrintSkid = () => {
  const dispatch = useDispatch<AppDispatch>();
  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { dialogPrint, printDatas } = useSelector(
    (state: any) => state.skidList
  );

  const onCancel = () => {
    dispatch(togglePrintDialog(false));
  };

  const handleSubmit = async () => {
    try {
      handlePrint();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        width={"210mm"}
        open={dialogPrint}
        title="Print Skid"
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            // loading={isLoading}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Print
          </Button>,
        ]}
      >
        <div>
          <div
            ref={componentRef}
            style={{
              display: "flex",
              width: "210mm",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {printDatas?.map((item: any, index: number) => (
              <div key={index}>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "700",
                    width: "3cm",
                    height: "3cm",
                    marginBottom: "1cm",
                    border: "1px solid black",
                    marginRight: "0.5cm",
                    marginLeft: "0.5cm",
                  }}
                >
                  <img
                    style={{ width: "2.2cm", height: "2.2cm" }}
                    src={item?.qr}
                  />
                  <div style={{ fontSize: 8 }}>{item?.assetid}</div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "700",
                    width: "3cm",
                    height: "3cm",
                    marginBottom: "1cm",
                    border: "1px solid black",
                    marginRight: "0.5cm",
                    marginLeft: "0.5cm",
                  }}
                >
                  <img
                    style={{ width: "2.2cm", height: "2.2cm" }}
                    src={item?.qr}
                  />
                  <div style={{ fontSize: 8 }}>{item?.assetid}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PrintSkid;
