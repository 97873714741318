import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  partOut,
  get,
  search,
  remove,
  update,
} from "stores/part/actions/part";
import { message } from "antd";

export interface PartState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  dialogOut: boolean;
  dialogStatus: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  repair: string | null;
  activeTab: string;
}

const initialState: PartState = {
  dialogAdd: false,
  dialogUpdate: false,
  dialogOut: false,
  dialogStatus: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  repair: null,
  activeTab: "part",
};

export const partSlice = createSlice({
  name: "part",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    toggleOutDialog: (state, action) => {
      state.dialogOut = action.payload;
    },
    toggleStatusDialog: (state, action) => {
      state.dialogStatus = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setRepair: (state, action) => {
      state.repair = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetPart: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.rejected, (state, action: any) => {
        state.isLoading = false;
        if (action) {
          message.error(action?.payload?.message);
        } else {
          message.error("Create Part Failed");
        }
      })
      .addCase(create.fulfilled, (state, action) => {
        state.search = "";
        state.repair = null;
        state.isLoading = false;
        state.dialogAdd = false;
        message.success("Create Part Success");
      });

    builder
      .addCase(partOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(partOut.rejected, (state, action: any) => {
        state.isLoading = false;
        if (action) {
          message.error(action?.payload?.message);
        } else {
          message.error("Out Part Failed");
        }
      })
      .addCase(partOut.fulfilled, (state, action) => {
        state.search = "";
        state.repair = null;
        state.isLoading = false;
        state.dialogOut = false;
        message.success("Out Part Success");
      });

    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(remove.pending, (state) => {
        state.isLoadingRemove = true;
      })
      .addCase(remove.rejected, (state, action: any) => {
        state.isLoadingRemove = false;
        message.error("Remove Part Failed");
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingRemove = false;
        message.success("Remove Part Success");
      });

    builder
      .addCase(update.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(update.rejected, (state, action: any) => {
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.error("Update Part Failed");
      })
      .addCase(update.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.success("Update Part Success");
      });
  },
});

export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  setSearch,
  setPageSize,
  setRepair,
  setActiveTab,
  resetPart,
  toggleOutDialog,
  toggleStatusDialog,
} = partSlice.actions;

export default partSlice.reducer;
