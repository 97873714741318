import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  get,
  search,
  remove,
  update,
  changePassword,
  getRole,
} from "stores/user/actions/user";
import { message } from "antd";

export interface UsersState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  dialogPassword: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  roles: any;
}

const initialState: UsersState = {
  dialogAdd: false,
  dialogUpdate: false,
  dialogPassword: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  roles: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    togglePasswordDialog: (state, action) => {
      state.dialogPassword = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.rejected, (state, action) => {
        state.isLoading = false;
        message.error("Create User Failed");
      })
      .addCase(create.fulfilled, (state, action) => {
        state.search = "";
        state.isLoading = false;
        state.dialogAdd = false;
        message.success("Create User Success");
      });

    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(remove.pending, (state) => {
        state.isLoadingRemove = true;
      })
      .addCase(remove.rejected, (state, action: any) => {
        state.isLoadingRemove = false;
        message.error("Delete User Failed");
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingRemove = false;
        message.success("Delete User Success");
      });

    builder
      .addCase(update.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(update.rejected, (state, action: any) => {
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.error("Update User Failed");
      })
      .addCase(update.fulfilled, (state, action) => {
        state.search = "";
        state.isLoadingUpdate = false;
        state.dialogUpdate = false;
        message.success("Update User Success");
      });

      builder
      .addCase(changePassword.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(changePassword.rejected, (state, action: any) => {
        state.dialogPassword = false;
        state.isLoadingUpdate = false;
        message.error("Change User Password Failed");
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.search = "";
        state.dialogPassword = false;
        state.isLoadingUpdate = false;
        message.success("Change User Password Success");
      });

    builder.addCase(getRole.fulfilled, (state, action) => {
      const roles: any = action.payload?.map((item: any) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }));
      state.roles = roles;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  togglePasswordDialog,
  setSearch,
  setPageSize,
} = usersSlice.actions;

export default usersSlice.reducer;
