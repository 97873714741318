import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getSummary = (params: any) => {
  return xhrInstance.get(objToParams("/report", params));
};

const getHistorySkid = (params: any) => {
  return xhrInstance.get(objToParams("/report/history", params));
};

const ReportService = {
  getSummary,
  getHistorySkid,
};

export default ReportService;
