function objToParams(
  url: string,
  obj: Record<string, string | number | boolean>
): string {
  let mainUrl = url;
  for (let i = 0; i < Object.keys(obj).length; i++) {
    let objHelper = Object.keys(obj)[i];
    if (i === 0) {
      mainUrl += `?${objHelper}=${obj[objHelper]}`;
    } else {
      mainUrl += obj[objHelper] ? `&${objHelper}=${obj[objHelper]}` : "";
    }
  }
  return mainUrl;
}

export default objToParams;
