import { createSlice } from "@reduxjs/toolkit";
import { get, search } from "stores/skid/out/actions/out";

export interface SkidOutState {
  isLoadingData: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  thick: string;
  width: string;
  length1: string;
  length2: string;
}

const initialState: SkidOutState = {
  isLoadingData: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  thick: "",
  width: "",
  length1: "",
  length2: "",
};

export const skidOutSlice = createSlice({
  name: "skidOut",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setThick: (state, action) => {
      state.thick = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    setLength1: (state, action) => {
      state.length1 = action.payload;
    },
    setLenght2: (state, action) => {
      state.length2 = action.payload;
    },
    resetSkidOut: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = action.payload.pages;
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = action.payload.pages;
        state.totalData = action.payload.total;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setPageSize,
  setThick,
  setWidth,
  setLength1,
  setLenght2,
  resetSkidOut,
} = skidOutSlice.actions;

export default skidOutSlice.reducer;
