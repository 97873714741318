import {
  Card,
  Input,
  Row,
  Col,
  Button,
  Form,
  // InputNumber,
  DatePicker,
  Typography,
} from "antd";
import DialogCreateSkid from "pages/skid/skid/list/sections/action-table/dialog/create";
import DialogPrint from "pages/skid/skid/list/sections/action-table/dialog/print";
import useAction from "pages/skid/inventory/list-inventory/sections/action-table/hooks/useAction";
import PreviewListInvengory from "pages/skid/inventory/list-inventory/sections/action-table/dialog/preview";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const ActionTable = () => {
  const [form] = Form.useForm();

  const {
    handleSearch,
    handleChangeSearch,
    handleChangeDate,
    // handleChangeThick,
    // handleChangeWidth,
    // handleChangeLenghth1,
    // handleChangeLenghth2,
    handleClear,
  } = useAction();

  return (
    <>
      <Card className="mb-3">
        <Title level={5}>Search By</Title>
        <Form name="basic" form={form} size="middle" layout="vertical">
          <Row gutter={10}>
            <Col span={6}>
              <Form.Item label="Code SKID" name="search">
                <Input.Search
                  // loading={isLoadingSearch}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  onSearch={() => {
                    handleSearch();
                  }}
                  enterButton
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Periode" name="date">
                <RangePicker
                  onChange={handleChangeDate}
                  format={"DD/MM/YYYY"}
                />
              </Form.Item>
            </Col>
            <Col span={2} offset={8}>
              <Form.Item label=" " name="i">
                <Button onClick={() => handleClear(form)} block type="primary">
                  Clear
                </Button>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label=" " name="j">
                <PreviewListInvengory />
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item label="Skid Size" style={{ marginBottom: 0 }}>
            <Row gutter={15}>
              <Col span={3}>
                <Form.Item name="thick">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Thick"
                    min={0}
                    onChange={handleChangeThick}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="Width">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Width"
                    min={0}
                    onChange={handleChangeWidth}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length1">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 1"
                    min={0}
                    onChange={handleChangeLenghth1}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length2">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 2"
                    min={0}
                    onChange={handleChangeLenghth2}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3} offset={9}>
                <Button onClick={() => handleClear(form)} block type="primary">
                  Clear
                </Button>
              </Col>
            </Row>
          </Form.Item> */}
        </Form>
      </Card>
      <DialogCreateSkid />
      <DialogPrint />
    </>
  );
};

export default ActionTable;
