import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Inventory", to: "/mtn/inventory/process" },
    { name: "Process", to: "/mtn/inventory/process" },
  ];

  return <Breadcrumb title="Process" items={bItem} />;
};

export default TitleStore;
