// import Store from "pages/skid/master_data/store";
// import Customer from "pages/skid/master_data/customer";
import Label from "pages/skid/print/label";
import DeliveryOrder from "pages/skid/print/delivery_order";
// import DeliveryOrder "pages/skid/print/delivery_order";

const Print = [
  {
    path: "/print/label",
    element: <Label />,
  },
  {
    path: "print/delivery-order",
    element: <DeliveryOrder />,
  },
];

export default Print;
