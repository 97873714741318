import { createAsyncThunk } from "@reduxjs/toolkit";
import RepairService from "services/mtns/order-history/RepairService";

export const create = createAsyncThunk(
  "repairPart/create",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await RepairService.createRepairPart(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const partOut = createAsyncThunk(
  "repairPart/out",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await RepairService.outPart(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get = createAsyncThunk("repairPart/get", async (params: any) => {
  const { data } = await RepairService.getRepairPart(params);
  return data;
});

export const search = createAsyncThunk("repairPart/search", async (params: any) => {
  const { data } = await RepairService.getRepairPart(params);
  return data;
});

export const remove = createAsyncThunk(
  "repairPart/remove",
  async (id: string | number) => {
    const { data } = await RepairService.removePart(id);
    return data;
  }
);

export const update = createAsyncThunk("repairPart/update", async (params: any) => {
  const { data } = await RepairService.updateRepairPart(params);
  return data;
});

export const updateStatus = createAsyncThunk("repairPart/updateStatus", async (params: any) => {
  const { data } = await RepairService.updateRepairPartStatus(params);
  return data;
});
