import {
  Card,
  Input,
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  Button,
  InputNumber,
} from "antd";
import useAction from "pages/skid/report/skid_history/sections/action-table/hooks/useAction";
import PreviewDialog from "pages/skid/report/skid_history/sections/table-pagination/sections/dialogs/preview";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const ActionTable = () => {
  const [form] = Form.useForm();
  const {
    searchPeriode,
    searchSkidName,
    clearSearch,
    searchThick,
    searchWidth,
    searchLength1,
    searchLength2,
  } = useAction();

  return (
    <>
      <Card className="mb-3">
        <Title level={5}>Search By</Title>
        <Form name="basic" form={form} size="middle" layout="vertical">
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item label="Periode" name="period">
                <RangePicker onChange={searchPeriode} format={"DD-MM-YYYY"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Code SKID" name="skidName">
                <Input onChange={searchSkidName} placeholder="Code SKID" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label=" ">
                <Row justify="end">
                  <PreviewDialog />
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Skid Size" style={{ marginBottom: 0 }}>
            <Row gutter={15}>
              <Col span={3}>
                <Form.Item name="thick">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Thick"
                    min={0}
                    onChange={searchThick}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="Width">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Width"
                    min={0}
                    onChange={searchWidth}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length1">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 1"
                    min={0}
                    onChange={searchLength1}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length2">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 2"
                    min={0}
                    onChange={searchLength2}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={9} xxl={10}></Col>
              <Col span={3} xxl={2}>
                <Row justify="end">
                  <Button
                    style={{ width: "fit-content" }}
                    onClick={() => clearSearch(form)}
                    block
                    type="primary"
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ActionTable;
