import Store from "pages/skid/master_data/store";
import Customer from "pages/skid/master_data/customer";

const MasterData = [
  {
    path: "/master-data/store",
    element: <Store />,
  },
  {
    path: "/master-data/customer",
    element: <Customer />,
  },
];

export default MasterData;
