import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { AppDispatch } from "stores";
import { toggleUpdateDialog, toggleStatusDialog } from "stores/order-history/repair";
import { get, update, updateStatus } from "stores/order-history/repair/actions/repair";
import useDialogPart from "pages/mtn/order-history/repair/sections/hooks/useDialogPart";
// import PartService from "services/mtns/part/PartService";

const useDialogUpdate = (dataRecord: any) => {
  const {
    partOption,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
    openModal,
    getPartStore,
    getStatus,
    setFileImg,
    setImgBase64,
  } = useDialogPart();

  const { dialogUpdate, isLoadingUpdate, pageSize, dialogStatus, activeTab } = useSelector(
    (state: any) => state.repairPart
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
    dispatch(toggleStatusDialog(false));
    setFileImg(null);
    setImgBase64("");
  };

  const handleSubmitUpdate = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };

      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
        status_id: activeTab,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitStatus = async () => {
    try {

      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };

      await dispatch(updateStatus(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
        status_id: activeTab,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const setForm = async (data: any = "") => {
    form.setFieldsValue({
      part_repair_name: dataRecord?.part_name,
      store_id: data,
    });
  };

  useEffect(() => {
    if (dialogUpdate) {
      getPartStore();
      setForm(dataRecord?.store_id);

    } else if (dialogStatus) {
      getStatus()
    }
  }, [dialogUpdate, dialogStatus]);

  return {
    dialogUpdate,
    dialogStatus,
    partOption,
    isLoadingUpdate,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleSubmitUpdate,
    handleSubmitStatus,
    handleCancel,
    handleRefImg,
    handleUpload,
  };
};

export default useDialogUpdate;
