import Title from "pages/mtn/master_data/maker/sections/title";
import ActionTable from "pages/mtn/master_data/maker/sections/action-table";
import TablePagination from "pages/mtn/master_data/maker/sections/table-pagination";

const Store = () => {
  return (
    <>
      <Title />
      <ActionTable />
      <TablePagination />
    </>
  );
};

export default Store;
