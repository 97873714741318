import { useEffect } from "react";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/order-history/repair/actions/repair";
import {
  setOptionTabs,
  setSearch,
  setActiveTab,
  resetRepairPart
} from "stores/order-history/repair";
import TablePaginationRepair from "pages/mtn/order-history/repair/sections/tabs/repair/table-pagination";
import RepairService from "services/mtns/order-history/RepairService";
import useWatch from "hooks/useWatch";

const Tab = () => {
  const { activeTab } = useSelector((state: any) => state.repairPart);
  const dispatch = useDispatch<AppDispatch>();

  const initialComponent = async () => {
    const { data } = await RepairService.getFilterRepairPartStatus();
    dispatch(setActiveTab(data?.data[0].status_id));
    dispatch(
      setOptionTabs(
        data?.data?.map((item: any) => ({
          key: item.status_id,
          label: item.status_name,
        }))
      )
    );
    const params: any = {
      page: 1,
      per_page: 10,
      status_id: data?.data[0].status_id,
    };

    await dispatch(get(params)).unwrap();
  };

  useEffect(() => {
    initialComponent();
    return () => {
      dispatch(resetRepairPart());
    }
  }, []);

  useWatch(() => {
    dispatch(setSearch(""));
    const params: any = {
      page: 1,
      per_page: 10,
      status_id: activeTab,
    };

    dispatch(get(params)).unwrap();
  }, [activeTab]);

  return <TablePaginationRepair />;
};

export default Tab;
