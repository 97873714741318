import { createAsyncThunk } from "@reduxjs/toolkit";
import ReportService from "services/skids/ReportService";
import MasterDataService from "services/skids/MasterDataService";

export const get = createAsyncThunk(
  "reportSummary/get",
  async (params: any) => {
    const { data } = await ReportService.getSummary(params);
    return data;
  }
);

export const search = createAsyncThunk(
  "reportSummary/search",
  async (params: any) => {
    const { data } = await ReportService.getSummary(params);
    return data;
  }
);

export const getCustomerCode = createAsyncThunk(
  "reportSummary/customerCode",
  async (params: any) => {
    const { data } = await MasterDataService.getCustomer(params);
    return data;
  }
);
