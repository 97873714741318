import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import { get } from "stores/report/summary/actions/summary";
import {
  setDate,
  setSkidName,
  setSelectCustomer,
  setThick,
  setWidth,
  setLength1,
  setLength2,
  resetSummary,
} from "stores/report/summary";
import dayjs from "dayjs";
import debounce from "lodash/debounce";

const useAction: any = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    pageSize,
    skidName,
    date,
    selectCustomer,
    thick,
    width,
    length1,
    length2,
  } = useSelector((state: any) => state.reportSummary);

  const networkRequest = useCallback((params: any) => {
    dispatch(get(params)).unwrap();
  }, []);
  const debounceTime = 500;
  const debounceSearch = useMemo(() => {
    return debounce(networkRequest, debounceTime);
  }, [networkRequest]);

  const falseValue = (str: any) => {
    if (!str || str === "" || str === "0.00" || str === "0") {
      return "";
    } else {
      return parseFloat(str).toFixed(2);
    }
  };

  const searchPeriode = (date: any) => {
    dispatch(setDate(date ? date : []));

    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: skidName,
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      thick: falseValue(thick),
      width: falseValue(width),
      length1: falseValue(length1),
      length2: falseValue(length2),
    };

    if (date) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    dispatch(get(params)).unwrap();
  };

  const searchSkidName = (event: any) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      thick: falseValue(thick),
      width: falseValue(width),
      length1: falseValue(length1),
      length2: falseValue(length2),
    };

    if (event.target.value) {
      params.keyword = event.target.value;
    }

    dispatch(setSkidName(event.target.value));
    debounceSearch(params);
  };

  const searchCustomerCode = (arrCode: any) => {
    dispatch(setSelectCustomer(arrCode));

    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: skidName,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      thick: falseValue(thick),
      width: falseValue(width),
      length1: falseValue(length1),
      length2: falseValue(length2),
    };

    if (arrCode.length > 0) {
      params.customer = arrCode.toString();
    }

    dispatch(get(params)).unwrap();
  };

  const searchThick = (val: any) => {
    console.log(val);
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: skidName,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      width: falseValue(width),
      length1: falseValue(length1),
      length2: falseValue(length2),
    };

    if (falseValue(val)) {
      params.thick = falseValue(val);
    }

    dispatch(setThick(val));
    debounceSearch(params);
  };

  const searchWidth = (val: any) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: skidName,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      thick: falseValue(thick),
      length1: falseValue(length1),
      length2: falseValue(length2),
    };

    if (falseValue(val)) {
      params.width = falseValue(val);
    }

    dispatch(setWidth(val));
    debounceSearch(params);
  };

  const searchLength1 = (val: any) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: skidName,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      thick: falseValue(thick),
      width: falseValue(width),
      length2: falseValue(length2),
    };

    if (falseValue(val)) {
      params.length1 = falseValue(val);
    }

    dispatch(setLength1(val));
    debounceSearch(params);
  };

  const searchLength2 = (val: any) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: skidName,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      thick: falseValue(thick),
      width: falseValue(width),
      length1: falseValue(length1),
    };

    if (falseValue(val)) {
      params.length2 = falseValue(val);
    }
    dispatch(setLength2(val));
    debounceSearch(params);
  };

  const clearSearch = async (form: any) => {
    await form.resetFields();
    dispatch(resetSummary());
    await dispatch(
      get({
        page: 1,
        per_page: 10,
      })
    ).unwrap();
  };

  return {
    searchPeriode,
    searchSkidName,
    searchCustomerCode,
    searchThick,
    searchWidth,
    searchLength1,
    searchLength2,
    clearSearch,
  };
};

export default useAction;
