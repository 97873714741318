import {
  ProjectOutlined,
  DashboardOutlined,
  SaveOutlined,
  DatabaseOutlined,
  FundProjectionScreenOutlined,
  PrinterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const useMenuSkid = () => {
  const { lastLogin } = useSelector((state: any) => state.auth);

  const skidMenu = [
    {
      key: "/",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/master-data",
      icon: <DatabaseOutlined />,
      label: "Master Data",
      children: [
        {
          key: "/master-data/customer",
          label: "Customer",
        },
        {
          key: "/master-data/store",
          label: "Store",
        },
      ],
    },
    {
      key: "/skid",
      icon: <ProjectOutlined />,
      label: "SKID",
      children: [
        {
          key: "/skid/list",
          label: "List",
        },
        {
          key: "/skid/in",
          label: "IN",
        },
        {
          key: "/skid/out",
          label: "OUT",
        },
      ],
    },
    {
      key: "/print",
      icon: <PrinterOutlined />,
      label: "Print",
      children: [
        {
          key: "/print/label",
          label: "Label SKID",
        },
        {
          key: "/print/delivery-order",
          label: "Surat Jalan",
        },
        {
          key: "/report/pickup-order",
          label: "Pickup SKID",
        },
      ],
    },
    {
      key: "/inventory",
      icon: <SaveOutlined />,
      label: "Inventory",
      children: [
        {
          key: "/inventory/adjust",
          label: "Adjustment",
        },
        {
          key: "/inventory/adjust-history",
          label: "List Inventory",
        },
      ],
    },
    {
      key: "/report",
      icon: <FundProjectionScreenOutlined />,
      label: "Report",
      children: [
        {
          key: "/report/summary",
          label: "Summary",
        },
        {
          key: "/report/skid-history",
          label: "SKID History",
        },
      ],
    },

    {
      key: "/users",
      icon: <UsergroupAddOutlined />,
      label: "User",
    },
    {
      key: "/profile",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
  ];

  const filterItem = () => {
    const role = lastLogin?.role?.id;
    if (role === 1) {
      return skidMenu;
    } else if (role === 3) {
      return skidMenu.filter((item) => item.label !== "User");
    } else if (role === 4) {
      return [];
    } else if (role === 5) {
      const roleFive = skidMenu?.filter((item) =>
        ["Dashboard", "Print", "Report", "SKID"].includes(item.label)
      );
      return roleFive;
    } else if (role === 6) {
      const roleSix = skidMenu
        ?.filter((item) => ["Dashboard", "SKID"].includes(item.label))
        ?.map((item) => {
          return {
            ...item,
            children: item?.children?.filter((item) => item.key === "/skid/in"),
          };
        });
      return roleSix;
    }
  };

  const skidItem = filterItem();

  return skidItem;
};

export default useMenuSkid;
