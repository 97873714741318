import { createAsyncThunk } from "@reduxjs/toolkit";
import SkidListService from "services/skids/SkidListService";

export const create = createAsyncThunk(
  "skidList/create",
  async (params: any) => {
    try {
      const { data } = await SkidListService.createSkid(params);
      return data;
    } catch (err: any) {
      if (err?.response?.status === 400 || err?.response?.status === 404) {
        throw err.response.data.message;
      } else {
        throw new Error("An error occurred");
      }
    }
  }
);

export const get = createAsyncThunk("skidList/get", async (params: any) => {
  const { data } = await SkidListService.getSkid(params);
  return data;
});

export const search = createAsyncThunk(
  "skidList/search",
  async (params: any) => {
    const { data } = await SkidListService.getSkid(params);
    return data;
  }
);

export const remove = createAsyncThunk(
  "skidList/remove",
  async (id: string | number) => {
    const { data } = await SkidListService.removeSkid(id);
    return data;
  }
);

export const update = createAsyncThunk(
  "skidList/update",
  async (params: any) => {
    const { data } = await SkidListService.updateSkid(params);
    return data;
  }
);

export const addQty = createAsyncThunk(
  "skidList/createQty",
  async (params: any) => {
    const { data } = await SkidListService.addQtySkid(params);
    return data;
  }
);
