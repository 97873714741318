import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Image, Input, Table } from "antd";
import { toggleActionDialog, setDetailData } from "stores/inventory/adjust";
import { getDetail } from "stores/inventory/adjust/actions/adjust";
import { addRemark } from "stores/inventory/adjust/actions/adjust";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { error } from "console";
dayjs.extend(utc);

const ModalRemark = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const componentRef = useRef<any>();
  const { detailData, dialogAction, isLoadingModal } = useSelector(
    (state: any) => state.adjustInv
  );

  const newDetailData = detailData?.map((item: any, index: number) => ({
    ...item,
    no: index + 1,
  }));

  useEffect(() => {
    if (dialogAction) {
      dispatch(getDetail(dataRecord?.id)).unwrap();
    }
  }, [dialogAction]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleActionDialog(false));
  };

  const handleRemark = (e: any, record: any) => {
    const data = detailData?.map((item: any) => {
      if (record.id === item.id) {
        const remarks = e.target.value;
        if (remarks.toLowerCase().match(/ada/gi)) {
          return { ...item, remarks, error: "'Ada' word isn't allowed" };
        } else {
          return { ...item, remarks, error: "" };
        }
      }
      return item;
    });

    dispatch(setDetailData(data));
  };

  const disableSubmit = () => {
    if (
      detailData.filter((item: any) => item.remarks).length ===
      detailData?.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    const req = {
      data_assets: detailData?.map((item: any) => ({
        id: item?.id,
        remarks: item?.remarks,
      })),
    };

    dispatch(
      addRemark({
        body: req,
        id: dataRecord?.id,
      })
    ).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 25,
      align: "center",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 100,
    },
    {
      title: "Code Skid",
      dataIndex: "label",
      key: "label",
      width: 150,
    },

    {
      title: "QR Code",
      key: "store",
      width: 50,
      render: (dataRecord: any) => {
        return <Image width={50} src={dataRecord?.qrcode} />;
      },
    },
    {
      title: "Scanned",
      key: "scanned",
      width: 150,
      render: (dataRecord: any) => {
        return dataRecord?.scanned === "true" ? "Ya" : "Tidak";
      },
    },
    {
      title: "Remark",
      key: "remarks",
      width: 150,
      render: (dataRecord: any) => {
        const errorMessage = detailData?.find(
          (item: any) => item.id === dataRecord.id
        )?.error;
        return dataRecord.scanned === "false" ? (
          <>
            <Input
              status={errorMessage ? "error" : ""}
              onChange={(e) => handleRemark(e, dataRecord)}
            />
            {errorMessage && (
              <span style={{ color: "red" }}>{errorMessage}</span>
            )}
          </>
        ) : (
          "Ada"
        );
      },
    },
  ];

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogAction}
        title="Adjustment Action"
        onCancel={() => handleCancel()}
        width={1000}
        footer={[
          detailData.length > 0 && (
            <Button
              onClick={handleSubmit}
              key="submit"
              disabled={disableSubmit()}
              type="primary"
            >
              Submit
            </Button>
          ),
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
        ]}
      >
        <Table
          id="my-table-id"
          ref={componentRef}
          bordered
          rowKey={(record: any) => record.id}
          loading={isLoadingModal}
          columns={columns}
          dataSource={newDetailData}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default ModalRemark;
