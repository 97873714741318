import { createAsyncThunk } from "@reduxjs/toolkit";
import SkidInService from "services/skids/SkidInServices";

export const get = createAsyncThunk("skidIn/get", async (params: any) => {
  const { data } = await SkidInService.getSkidIn(params);
  return data;
});

export const search = createAsyncThunk("skidIn/search", async (params: any) => {
  const { data } = await SkidInService.getSkidIn(params);
  return data;
});
