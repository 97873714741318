import { Modal, Button, Form, Input, Col, Row, InputNumber } from "antd";
import useDialogOut from "pages/mtn/part/sections/tabs/part/table-pagination/dialog/useDialogOut";
import styled from "styled-components";

const { TextArea } = Input;

const OutPart = ({ dataRecord }: any) => {
  const { dialogOut, isLoadingUpdate, form, handleSubmit, handleCancel } =
    useDialogOut(dataRecord);

  const Stock = styled.div`
    font-weight: 500;
    font-size: 18px;
  `;

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogOut}
        title="Part Out"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row justify="center">
            <Stock>Current Stock {dataRecord?.qty}</Stock>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="qty"
                label="Quantity"
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} min="0" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: true }]}
              >
                <TextArea rows={2} placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default OutPart;
