import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import debounce from "lodash/debounce";
import * as inAction from "stores/skid/out/actions/out";
import {
  setSearch,
  setThick,
  setWidth,
  setLength1,
  setLenght2,
  resetSkidOut,
} from "stores/skid/out";

const useAction: any = () => {
  const debounceTime = 500;
  const dispatch = useDispatch<AppDispatch>();
  const { search, thick, width, length1, length2, pageSize } = useSelector(
    (state: any) => state.skidOut
  );

  const networkRequest = useCallback((params: any) => {
    dispatch(inAction.search(params)).unwrap();
  }, []);

  const handleSearch = () => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      thick,
      width,
      length1,
      length2,
    };

    networkRequest(params);
  };

  const debounceSearch = useMemo(() => {
    return debounce(networkRequest, debounceTime);
  }, [networkRequest]);

  const handleChangeSearch = (event: any) => {
    const inputValue = event.target.value;
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: inputValue,
      thick,
      width,
      length1,
      length2,
    };
    dispatch(setSearch(inputValue));
    debounceSearch(params);
  };

  const debounceThick = useMemo(() => {
    return debounce(networkRequest, debounceTime);
  }, [networkRequest]);

  const handleChangeThick = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      thick: value,
      width,
      length1,
      length2,
    };
    dispatch(setThick(value));
    debounceThick(params);
  };

  const debounceWidth = useMemo(() => {
    return debounce(networkRequest, debounceTime);
  }, [networkRequest]);

  const handleChangeWidth = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      thick,
      width: value,
      length1,
      length2,
    };
    dispatch(setWidth(value));
    debounceWidth(params);
  };

  const debounceLength1 = useMemo(() => {
    return debounce(networkRequest, debounceTime);
  }, [networkRequest]);

  const handleChangeLenghth1 = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      thick,
      width,
      length1: value,
      length2,
    };
    dispatch(setLength1(value));
    debounceLength1(params);
  };

  const debounceLength2 = useMemo(() => {
    return debounce(networkRequest, debounceTime);
  }, [networkRequest]);

  const handleChangeLenghth2 = (value: any) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      thick,
      width,
      length1,
      length2: value,
    };
    dispatch(setLenght2(value));
    debounceLength2(params);
  };

  const handleClear = (form: any) => {
    form.resetFields();
    dispatch(resetSkidOut());
    networkRequest({
      page: 1,
      per_page: 10,
    });
  };

  return {
    networkRequest,
    handleSearch,
    handleChangeSearch,
    handleChangeThick,
    handleChangeWidth,
    handleChangeLenghth1,
    handleChangeLenghth2,
    handleClear,
  };
};

export default useAction;
