import { createSlice } from "@reduxjs/toolkit";
import {
  get,
  search,
  getCustomerCode,
} from "stores/report/by_customer/actions/by_customer";

export interface ReportByCustomerState {
  dialogPreview: boolean;
  isLoadingData: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  date: any;
  skidName: string;
  thick: any;
  width: any;
  length1: any;
  length2: any;
  codeCustomer: any;
  selectCustomer: any;
}

const initialState: ReportByCustomerState = {
  dialogPreview: false,
  isLoadingData: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  date: [],
  skidName: "",
  thick: "",
  width: "",
  length1: "",
  length2: "",
  codeCustomer: [],
  selectCustomer: [],
};

export const reportByCustomerSlice = createSlice({
  name: "reportByCustomer",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    toggleDialogPreview: (state, action) => {
      state.dialogPreview = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setSkidName: (state, action) => {
      state.skidName = action.payload;
    },
    setThick: (state, action) => {
      state.thick = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    setLength1: (state, action) => {
      state.length1 = action.payload;
    },
    setLength2: (state, action) => {
      state.length2 = action.payload;
    },
    setCodeCustomer: (state, action) => {
      state.codeCustomer = action.payload;
    },
    setSelectCustomer: (state, action) => {
      state.selectCustomer = action.payload;
    },
    resetSummary: (state) => {
      const allState = { ...initialState, codeCustomer: state.codeCustomer };
      return allState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = parseInt(action.payload.total);
      });

    builder.addCase(getCustomerCode.fulfilled, (state, action) => {
      const codeCustomerList = action?.payload?.data?.map((item: any) => ({
        label: item?.code,
        value: item?.id,
      }));
      state.codeCustomer = codeCustomerList;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearch,
  setPageSize,
  toggleDialogPreview,
  setDate,
  setSkidName,
  setThick,
  setWidth,
  setLength1,
  setLength2,
  setCodeCustomer,
  setSelectCustomer,
  resetSummary,
} = reportByCustomerSlice.actions;

export default reportByCustomerSlice.reducer;
