import { useEffect } from "react";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/part/actions/part";
import { resetPart } from "stores/part";
import TablePaginationPart from "pages/mtn/part/sections/tabs/part/table-pagination";
import TablePaginationRepair from "pages/mtn/part/sections/tabs/repair/table-pagination";

const Tab = () => {
  const { activeTab } = useSelector((state: any) => state.part);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (activeTab === "part") {
      const params: any = {
        page: 1,
        per_page: 10,
      };

      dispatch(get(params)).unwrap();
    } else {
      console.log("repair");
    }
    return () => {
      dispatch(resetPart());
    };
  }, [activeTab]);

  if (activeTab === "part") {
    return <TablePaginationPart />;
  } else {
    return <TablePaginationRepair />;
  }
};

export default Tab;
