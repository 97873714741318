import { createSlice } from "@reduxjs/toolkit";
import {
  get,
  search,
} from "stores/mtnInventory/recap/actions/recap";

export interface RecapInvState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  date: any
}

const initialState: RecapInvState = {
  dialogAdd: false,
  dialogUpdate: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  date: null
};

export const recapSlice = createSlice({
  name: "recapMtn",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    resetRecapMtn: () => initialState,
  },
  extraReducers: (builder) => {

    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = parseInt(action.payload.total);
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = parseInt(action.payload.total);
      });

  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  setSearch,
  setPageSize,
  setDate,
  resetRecapMtn
} = recapSlice.actions;

export default recapSlice.reducer;
