import { createAsyncThunk } from "@reduxjs/toolkit";
import PrintService from "services/skids/PrintServices";

export const get = createAsyncThunk("label/get", async (params: any) => {
  const { data } = await PrintService.getLabel(params);
  return data;
});

export const print = createAsyncThunk("label/print", async (params: any) => {
  const { data } = await PrintService.getLabel(params);
  return data;
});
