import { Card, Input, Row, Col, DatePicker, Select, Space } from "antd";
import DialogCreateDelivery from "pages/skid/report/pickup/sections/action-table/dialog/create";
import useAction from "pages/skid/report/pickup/sections/action-table/hooks/useAction";
const { RangePicker } = DatePicker;

const ActionTable = () => {
  const {
    senders,
    handleChangeDate,
    handleChangeSender,
    handleChange,
    handleSearch,
    search,
    isLoadingSearch,
  } = useAction();

  return (
    <>
      <Card className="mb-3">
        <Row gutter={8} justify={"space-between"}>
          <Col span={6}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Pickup Noted Number</div>
              <Input.Search
                loading={isLoadingSearch}
                value={search}
                onChange={handleChange}
                placeholder="Search"
                onSearch={handleSearch}
                enterButton
              />
            </Space>
          </Col>
          <Col span={2}></Col>
          <Col span={6}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Date</div>
              <RangePicker
                onChange={handleChangeDate}
                style={{ width: "100%" }}
              />
            </Space>
          </Col>
          <Col span={10}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Sender By</div>
              <Select
                onChange={handleChangeSender}
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={senders}
              />
            </Space>
          </Col>
        </Row>
      </Card>
      <DialogCreateDelivery />
    </>
  );
};

export default ActionTable;
