import { createSlice } from "@reduxjs/toolkit";
import {
  get,
  search,
} from "stores/partIn/actions/partIn";

export interface PartInState {
  dialogAdd: boolean;
  dialogUpdate: boolean;
  isLoading: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  status: string;
  type: string;
  date: any,
  updates: any,
}

const initialState: PartInState = {
  dialogAdd: false,
  dialogUpdate: false,
  isLoading: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  status: "",
  type: "",
  date: null,
  updates: []
};

export const partInSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    }, setStatus: (state, action) => {
      state.status = action.payload;
    }, setType: (state, action) => {
      state.type = action.payload;
    }, setDate: (state, action) => {
      state.date = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates.push(action.payload);
    },
    setFillUpdates: (state, action) => {
      state.updates = action.payload;
    },
    resetPartIn: () => initialState,
  },
  extraReducers: (builder) => {


    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = parseInt(action.payload.total);
      });

    builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
        state.isLoadingSearch = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.isLoadingSearch = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = parseInt(action.payload.total);
      });




  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  setSearch,
  setPageSize,
  setStatus,
  setType,
  setDate,
  setUpdates,
  setFillUpdates,
  resetPartIn
} = partInSlice.actions;

export default partInSlice.reducer;
