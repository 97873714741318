import { useEffect, useState } from "react";
import { Card, Row, Pagination, Table, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/mtnReport/actions/mtnReport";

import UpdateStoreDialog from "pages/mtn/master_data/store/sections/table-pagination/dialog/update";
import PrintStoreDialog from "pages/mtn/master_data/store/sections/table-pagination/dialog/print";
import { setPageSize, resetReportMtn } from "stores/mtnReport";
import dayjs from "dayjs";

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    status,
    date,
    type,
  } = useSelector((state: any) => state.mtnReport);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
    
    return () => {
      dispatch(resetReportMtn())
    }
  }, []);

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: search,
      status: status,
      input_type: type,
    };

    if (date) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 10,
      fixed: "left",
      align: "center",
    },
    {
      title: "Part Number",
      dataIndex: "mp_part_number",
      key: "mp_part_number",
      width: 100,
      fixed: "left",
    },
    {
      title: "Part Name",
      dataIndex: "mp_part_name",
      key: "mp_part_name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Qty",
      dataIndex: "mph_qty",
      key: "mph_qty",
      width: 50,
      fixed: "left",
    },
    {
      title: "Size",
      dataIndex: "mp_size",
      key: "mp_size",
      width: 50,
      fixed: "left",
    },
    {
      title: "Type",
      dataIndex: "mp_type",
      key: "mp_type",
      width: 50,
      fixed: "left",
    },
    {
      title: "Input Type",
      dataIndex: "mph_input_type",
      key: "mph_input_type",
      width: 100,
      fixed: "left",
    },
    {
      title: "Action By",
      dataIndex: "u_name",
      key: "u_name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      fixed: "left",
    },
  ];

  return (
    <Card>
      {contextHolder}
      <UpdateStoreDialog dataRecord={currentRecord} />
      <PrintStoreDialog dataRecord={currentRecord} />

      <Table
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          pageSize={pageSize}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
