import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Input, InputNumber, Col, Row } from "antd";
import { toggleAddQuantityDialog } from "stores/skid/list";
import { get, addQty } from "stores/skid/list/actions/list";
import useFetchLast from "pages/skid/skid/list/sections/table-pagination/dialog/fetchLast";

const AddQuantity = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { lastSkid } = useFetchLast();
  const { dialogAddQuantity, isLoading, pageSize } = useSelector(
    (state: any) => state.skidList
  );

  const initGetData = async () => {
    const res = await lastSkid(dataRecord?.skid);
    form.setFieldsValue({
      lastQty: res?.lastqty,
      store: res?.store,
      type: res?.skid_type,
      customer: res?.customer,
      code: res?.skid_code,
    });
  };

  useEffect(() => {
    if (dialogAddQuantity) {
      initGetData();
    }
  }, [dialogAddQuantity]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleAddQuantityDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.skid,
        body: { qty: values?.qty },
      };
      await dispatch(addQty(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogAddQuantity}
        title="Add Quantity Skid"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Skid Type"
                // rules={[{ required: true }]}
              >
                <Input disabled placeholder="Type P or S" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="store"
                label="Store"
                // rules={[{ required: true }]}
              >
                <Input disabled placeholder="Type here..." />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="customer"
                label="Customer Code"
                // rules={[{ required: true }]}
              >
                <Input disabled placeholder="Type here..." />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="lastQty"
                label="Last Quantity"
                // rules={[{ required: true }]}
              >
                <InputNumber disabled style={{ width: "100%" }} min="0" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="code"
                label="Code SKID"
                // rules={[{ required: true }]}
              >
                <Input disabled placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="qty"
                label="Add Quantity"
                rules={[
                  { required: true },
                  {
                    validator: async (_, value) => {
                      if (value === 0) {
                        throw new Error("Quantity must more than 0");
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min="0" max="99" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddQuantity;
