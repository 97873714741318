import PurchaseService from "services/mtns/order-history/PurchaseService";
import PartService from "services/mtns/part/PartService";
import { useState, useRef } from "react";
import { Form } from "antd";

const useDialogPart = () => {
  const [partOption, setParOption] = useState({
    repairs: [],
    statuses: [],
    stores: [],
    idRepairPart: null
  });

  const [form] = Form.useForm();
  const [fileImg, setFileImg]: any = useState(null);
  const [imgBase64, setImgBase64]: any = useState("");
  const imgRef: any = useRef(null);

  const handleRefImg = () => {
    imgRef.current.click();
  };

  const handleUpload = (event: any) => {
    if (event && event?.target?.files.length > 0) {
      setFileImg(event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImgBase64(reader.result);
      };
      reader.onerror = (err) => {
        console.log(err);
      };
    } else {
      return;
    }
  };

  const toOption = (arr: any) => {
    return arr?.map((item: any) => ({
      value: item?.id,
      label: item?.name || item?.part_number || item?.status_name,
    }));
  };

  const getRepairList = async () => {
    const { data } = await PurchaseService.getPurchaseList({});
    setParOption((partOption) => ({
      ...partOption,
      repairs: toOption(data?.data),
    }));
  };

  const getStatus = async () => {
    const { data } = await PurchaseService.getPurchaseStatus({});
    setParOption((partOption) => ({
      ...partOption,
      statuses: toOption(data?.data),
    }));
  };

  const getPartStore = async () => {
    const { data } = await PartService.getPartStore({});
    setParOption((partOption) => ({
      ...partOption,
      stores: toOption(data?.data),
    }));
  };


  return {
    partOption,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
    getRepairList,
    getStatus,
    getPartStore,
    setFileImg,
    setImgBase64,
  };
};

export default useDialogPart;
