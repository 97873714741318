import { createAsyncThunk } from "@reduxjs/toolkit";
import ProcessService from "services/mtns/inventory/ProcessServices";


export const get = createAsyncThunk("processInv/get", async (params: any) => {
  const { data } = await ProcessService.getProcess(params);
  return data;
});

export const search = createAsyncThunk("processInv/search", async (params: any) => {
  const { data } = await ProcessService.getProcess(params);
  return data;
});


export const update = createAsyncThunk("processInv/update", async (params: any) => {
  const { data } = await ProcessService.updateProcess(params);
  return data;
});
