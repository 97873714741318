import { useState } from "react";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/order-history/purchase/actions/purchase";
import PurchaseService from "services/mtns/order-history/PurchaseService";

import {
  EditOutlined,
  UnorderedListOutlined,
  InteractionOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
// import UpdatePurchaseDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/update";
import StatusPurchaseDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/status";
import PreviewPurchaseDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/preview";
import {
  toggleUpdateDialog,
  toggleStatusDialog,
  togglePreviewDialog,
  setPageSize,
} from "stores/order-history/purchase";
import DetailRepairPartDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/detail";
import dayjs from "dayjs";
// import "dayjs/locale/id";
// dayjs.locale("id");

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    activeTab,
    partId,
  } = useSelector((state: any) => state.purchase);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleChangeStatus = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleStatusDialog(true));
  };

  // const handleUpdate = (record: any) => {
  //   setCurrentRecord(record);
  //   dispatch(toggleUpdateDialog(true));
  // };

  const handleChangeToProcess = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: (() => {
        return (
          <>
            <div>
              PO Number{" "}
              <span style={{ fontWeight: 600 }}>{dataRecord?.po_number}</span>
            </div>
            <div>Are you sure to change status?</div>
          </>
        );
      })(),
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await PurchaseService.updateToProcessStatus(dataRecord?.id);
        const params = {
          page: 1,
          per_page: pageSize,
          status_id: activeTab,
        };
        await dispatch(get(params)).unwrap();
      },
    });
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      status_id: activeTab,
    };

    if (search) {
      params.keyword = search;
    }

    if (partId) {
      params.part_number = partId;
    }

    await dispatch(get(params)).unwrap();
  };

  const handlePreview = async (record: any) => {
    setCurrentRecord(record);
    dispatch(togglePreviewDialog(true));
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 20,
      align: "center",
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
      key: "po_number",
      width: 400,
    },
    {
      title: "Create Date",
      dataIndex: "create_time",
      key: "create_time",
      width: 400,
      render: (record: string) =>
        record ? dayjs.utc(record).format("DD/MM/YYYY HH:mm") : "-",
    },
  ];

  console.log(activeTab);

  const getColumns = () => {
    //Tab Started
    if (activeTab === 100) {
      return [
        ...columns,
        {
          title: "Options",
          key: "options",
          width: 10,
          fixed: "right",
          render: (dataRecord: any) => {
            return (
              <Row justify="space-between">
                <Tooltip title="Print">
                  <Button
                    onClick={() => handlePreview(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<PrinterOutlined />}
                  />
                </Tooltip>

                <Tooltip title="Change To Process Status">
                  <Button
                    onClick={() => handleChangeToProcess(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<InteractionOutlined />}
                  />
                </Tooltip>
              </Row>
            );
          },
        },
      ];
    } else if (activeTab === 110) {
      //Tab Process
      return [
        ...columns,
        {
          title: "Options",
          key: "options",
          width: 10,
          fixed: "right",
          render: (dataRecord: any) => {
            return (
              <Row justify="space-between">
                <Tooltip title="Print">
                  <Button
                    onClick={() => handlePreview(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<PrinterOutlined />}
                  />
                </Tooltip>

                <Tooltip title="Change Status">
                  <Button
                    onClick={() => handleChangeStatus(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<InteractionOutlined />}
                  />
                </Tooltip>
              </Row>
            );
          },
        },
      ];
    } else if (activeTab === 200) {
      //Tab Completed
      return [
        ...columns,
        {
          title: "Options",
          key: "options",
          width: 10,
          fixed: "right",
          render: (dataRecord: any) => {
            return (
              <Row justify="space-between">
                <Tooltip title="Print">
                  <Button
                    onClick={() => handlePreview(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<PrinterOutlined />}
                  />
                </Tooltip>

                {/* <Tooltip title="Change To Process Status">
                  <Button
                    onClick={() => handleChangeToProcess(dataRecord)}
                    type="primary"
                    shape="circle"
                    icon={<InteractionOutlined />}
                  />
                </Tooltip> */}
              </Row>
            );
          },
        },
      ];
    } else {
      return columns;
    }
  };

  const [toggleDetail, setToggleDetail] = useState(false);

  return (
    <Card>
      {contextHolder}
      {/* <UpdateRepairDialog dataRecord={currentRecord} /> */}
      <StatusPurchaseDialog dataRecord={currentRecord} />
      <PreviewPurchaseDialog dataRecord={currentRecord} />
      {/* <UpdatePurchaseDialog dataRecord={currentRecord} /> */}

      <DetailRepairPartDialog
        toggleDetail={toggleDetail}
        setToggleDetail={setToggleDetail}
        dataRecord={currentRecord}
      />

      <Table
        // rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={getColumns()}
        dataSource={newDatas}
        pagination={false}
        // scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          pageSize={pageSize}
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
