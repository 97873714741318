import Title from "pages/mtn/order-history/repair/sections/title";
import ActionTable from "pages/mtn/order-history/repair/sections/action-table";
import Tabs from "pages/mtn/order-history/repair/sections/tabs";

const Store = () => {
  return (
    <>
      <Title />
      <ActionTable />
      <Tabs />
    </>
  );
};

export default Store;
