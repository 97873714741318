import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Input, Col, Row, Select } from "antd";
import { toggleUpdateDialog } from "stores/user";
import { get, update, getRole } from "stores/user/actions/user";
import MasterDataService from "services/skids/MasterDataService";

const UpdateCustomer = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogUpdate, isLoadingUpdate, pageSize, roles } = useSelector(
    (state: any) => state.users
  );
  const [customers, setCustomers] = useState([]);
  const [role, setRole] = useState<number>(0);

  const getRoles = async () => {
    await dispatch(getRole()).unwrap();
    setRole(parseInt(dataRecord?.role));
    if (dataRecord?.role === "8") {
      form.setFieldsValue({
        name: dataRecord?.name,
        nik: dataRecord?.nik,
        department: dataRecord?.department,
        division: dataRecord?.division,
        role: dataRecord?.role ? parseInt(dataRecord?.role) : null,
        customer: dataRecord?.customer,
      });
    } else {
      form.setFieldsValue({
        name: dataRecord?.name,
        nik: dataRecord?.nik,
        department: dataRecord?.department,
        division: dataRecord?.division,
        role: dataRecord?.role ? parseInt(dataRecord?.role) : null,
      });
    }
  };

  const getCustomers = async () => {
    const { data } = await MasterDataService.getCustomer({
      page: 1,
      per_page: 1000000,
    });
    setCustomers(
      data.data?.map((item: any) => ({ value: item.id, label: item.name }))
    );
  };

  useEffect(() => {
    if (dialogUpdate) {
      getCustomers();
      getRoles();
    }
  }, [dialogUpdate]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: { ...values, customer: role === 8 ? values.customer : 0 },
      };
      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogUpdate}
        title="Edit User"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="nik"
                label="NIK"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("NIK is required");
                      }
                      if (/\b\d{5}\b/g.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Only number & must be 5 characters"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input disabled placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="department"
                label="Department"
                rules={[{ required: true }]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["role"]}
                label="Role"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  onChange={(val) => setRole(val)}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={roles}
                />
              </Form.Item>
            </Col>
            {role === 8 && (
              <Col span={24}>
                <Form.Item
                  name="customer"
                  label="Customer"
                  rules={[{ required: true }]}
                >
                  <Select
                    disabled={dataRecord?.customer !== 0}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={customers}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCustomer;
