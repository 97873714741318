import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Master Data", to: "/mtn/master-data/rank" },
    { name: "Line", to: "/mtn/master-data/line" },
  ];

  return <Breadcrumb title="Line" items={bItem} />;
};

export default TitleStore;
