import { createAsyncThunk } from "@reduxjs/toolkit";
import PartInService from "services/mtns/part/PartInService";


export const get = createAsyncThunk("reportMtn/get", async (params: any) => {
  const { data } = await PartInService.getPO(params);
  return data;
});

export const search = createAsyncThunk("reportMtn/search", async (params: any) => {
  const { data } = await PartInService.getPO(params);
  return data;
});


