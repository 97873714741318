import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createUnit = (params: any) => {
  return xhrInstance.post("/mtn/unit", params);
};

const getUnit = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/unit", params));
};

const removeUnit = (id: string | number) => {
  return xhrInstance.delete(`/mtn/unit/${id}`);
};

const updateUnit = (params: any) => {
  return xhrInstance.put(`/mtn/unit/${params.id}`, params.body);
};

const UnitService = {
  createUnit,
  getUnit,
  removeUnit,
  updateUnit,
};

export default UnitService;
