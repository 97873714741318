import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Row, Button, Pagination, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { getInv } from "stores/inventory/adjust/actions/adjust";
import ActionDialog from "pages/skid/inventory/list-inventory/sections/table-pagination-adjust/dialog/action_adjust";
import {
  setPageSize,
  toggleActionDialog,
  resetAdjustInv,
} from "stores/inventory/adjust";
import zeroSize from "utils/zeroSize";
import dayjs from "dayjs";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    thick,
    width,
    length1,
    length2,
    date,
  } = useSelector((state: any) => state.adjustInv);

  const handleInitData = async () => {
    await dispatch(
      getInv({
        page: 1,
        per_page: 10,
      })
    ).unwrap();
  };

  useEffect(() => {
    handleInitData();
    return () => {
      dispatch(resetAdjustInv());
    };
  }, []);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const handleActionUp = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleActionDialog(true));
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,

      thick,
      width,
      length1,
      length2,
    };

    if (search) {
      params.code_skid = search;
    }

    if (date) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    await dispatch(getInv(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      align: "center",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 100,
    },
    {
      title: "Code Skid",
      dataIndex: "code_skid",
      key: "code_skid",
      width: 150,
    },
    {
      title: "Size SKID",
      children: [
        {
          title: "T",
          key: "skidT",
          width: 100,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.thick),
        },
        {
          title: "W",
          key: "skidW",
          width: 100,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.width),
        },
        {
          title: "L1",
          key: "skidL1",
          width: 100,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.length1),
        },
        {
          title: "L2",
          key: "skidL2",
          width: 100,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.length2),
        },
      ],
    },
    {
      title: "Actual System",
      key: "actual_system",
      dataIndex: "actual_system",
      width: 80,
    },
    {
      title: "In",
      key: "qty_in",
      dataIndex: "qty_in",
      width: 80,
    },

    {
      title: "Out",
      key: "qty_out",
      dataIndex: "qty_out",
      width: 80,
    },
    {
      title: "Scanned",
      key: "qty_scanned",
      dataIndex: "qty_scanned",
      width: 80,
    },

    {
      title: "Last Adjustment",
      key: "last",
      // dataIndex: "last_adjustment",
      width: 150,
      render: (text: any) =>
        text.last_adjustment
          ? text.last_adjustment === "-"
            ? "-"
            : dayjs(text.last_adjustment).format("DD/MM/YYYY HH:mm")
          : "No",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (dataRecord: any) => {
        return (
          <Button
            onClick={() => handleActionUp(dataRecord)}
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
          />
        );
      },
    },
  ];

  return (
    <>
      <ActionDialog dataRecord={currentRecord} />
      <Table
        bordered
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
        // rowClassName={""}
        scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          pageSize={pageSize}
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </>
  );
};

export default TablePagination;
