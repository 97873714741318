import { Modal, Button, Row, InputNumber, Table, Tooltip, Input } from "antd";
import { toggleCartDialog } from "stores/order-history/purchase";
import { AppDispatch } from "stores";
import { useDispatch, useSelector } from "react-redux";
import { setCartQty } from "stores/order-history/purchase";
import { DeleteOutlined } from "@ant-design/icons";
import {
  create,
  getLimitPart,
} from "stores/order-history/purchase/actions/purchase";

const { TextArea } = Input;

const UpdateStore = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { dialogCart, carts, isLoading } = useSelector(
    (state: any) => state.purchase
  );
  const onCancel = () => {
    dispatch(toggleCartDialog(false));
  };

  const handleChangeQty = (val: any, idCart: number) => {
    const newCart = carts?.map((cart: any) => {
      if (cart.id === idCart) {
        return { ...cart, qty: val };
      } else {
        return cart;
      }
    });
    dispatch(setCartQty(newCart));
  };

  const handleChangeRemark = (event: any, idCart: number) => {
    const value = event.target.value;
    const newCart = carts?.map((cart: any) => {
      if (cart.id === idCart) {
        return { ...cart, remarks: value };
      } else {
        return cart;
      }
    });
    dispatch(setCartQty(newCart));
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      align: "center",
    },
    {
      title: "ID Part",
      dataIndex: "part_number",
      key: "part_number",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "part_name",
      key: "name",
      width: 150,
    },
    {
      title: "Available Stock",
      dataIndex: "stock",
      key: "stock",
      width: 100,
      align: "center",
    },
    {
      title: "Quantity",
      key: "qty",
      width: 70,
      render: (record: any) => {
        return (
          <InputNumber
            onChange={(val) => handleChangeQty(val, record.id)}
            value={record?.qty}
            min={1}
          />
        );
      },
    },
    
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 100,
      render: (r: any) => r || "-",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      render: (r: any) => r || "-",
    },
    // {
    //   title: "Supplier",
    //   dataIndex: "supplier_name",
    //   key: "supplier_name",
    //   width: 150,
    //   render: (r: any) => r || "-",
    // },
    // {
    //   title: "Store",
    //   dataIndex: "store_code",
    //   key: "store",
    //   width: 150,
    // },
    {
      title: "Remarks",
      key: "remarks",
      width: 250,
      render: (record: any) => {
        return (
          <TextArea
            onChange={(event) => handleChangeRemark(event, record.id)}
            value={record?.remarks}
            rows={1}
          />
        );
      },
    },
    {
      title: "Options",
      key: "options",
      width: 100,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="center">
            <Tooltip title="Remove part">
              <Button
                onClick={() => {
                  const newCart = carts.filter(
                    (cart: any) => cart.id !== dataRecord?.id
                  );
                  dispatch(setCartQty(newCart));
                }}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const newDatas = carts?.map((item: any, index: number) => {
    return {
      ...item,
      no: index + 1,
    };
  });

  const handlePurchase = async () => {
    const req = {
      data_parts: carts?.map((cart: any) => ({
        part_id: parseInt(cart.id),
        qty: cart.qty,
        remarks: cart.remarks,
      })),
    };
    await dispatch(create(req)).unwrap();
    const params: any = {
      page: 1,
      per_page: 10,
    };
    await dispatch(getLimitPart(params)).unwrap();
  };

  return (
    <>
      <Modal
        width={1200}
        open={dialogCart}
        title="List Part Will Purchasing"
        onCancel={() => onCancel()}
        maskClosable={false}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            disabled={carts?.length === 0}
            onClick={handlePurchase}
            key="submit"
            type="primary"
          >
            Purchase
          </Button>,
        ]}
      >
        <Table
          className="mt-4"
          rowKey={(record: any) => record.id}
          columns={columns}
          dataSource={newDatas}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default UpdateStore;
