import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import {
  PrinterOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  get,
  remove,
  detail,
} from "stores/print/pickup_order/actions/pickup_order";
import DeliveryOrderPreview from "pages/skid/report/pickup/sections/table-pagination/dialog/preview";
import { setPageSize, togglePreviewDialog } from "stores/print/pickup_order";
import dayjs from "dayjs";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [modal, contextHolder] = Modal.useModal();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    sender,
    date,
  } = useSelector((state: any) => state.pickupOrder);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handleDelete = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await dispatch(remove(dataRecord.id)).unwrap();
        const params: any = {
          page: 1,
          per_page: pageSize,
          customer: sender,
          keyword: search,
        };

        if (date.length > 0) {
          params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
          params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
        }

        await dispatch(get(params)).unwrap();
      },
    });
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      customer: sender,
      keyword: search,
    };

    if (date.length > 0) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    await dispatch(get(params)).unwrap();
  };

  const handlePrint = (record: any) => {
    dispatch(detail(record?.id)).unwrap();
    dispatch(togglePreviewDialog(true));
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 30,
      // fixed: "left",
      align: "center",
    },
    {
      title: "Pickup Noted Number",
      dataIndex: "no_surat",
      key: "no_surat",
      width: 650,
      // fixed: "left",
    },
    {
      title: "Options",
      key: "options",
      width: 80,
      align: "center",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Delete">
              <Button
                onClick={() => handleDelete(dataRecord)}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
            <Tooltip title="Print">
              <Button
                onClick={() => handlePrint(dataRecord)}
                type="primary"
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <DeliveryOrderPreview />

      <Table
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
