import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Button,
  Form,
  // Input,
  Col,
  Row,
  // InputNumber,
  Select,
} from "antd";
import { toggleUpdateDialog } from "stores/master-data/mtn-store";
import { AppDispatch } from "stores";
import { get, update } from "stores/approval/actions/approval";
import ApprovalService from "services/mtns/approval/ApprovalServices";

const UpdateStore = ({ dataRecord }: any) => {
  const [statuses, setStatuses] = useState([]);
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogUpdate, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.approval
  );

  const getListStatus = async () => {
    const { data } = await ApprovalService.listStatusApproval();
    setStatuses(
      data?.data?.map((item: any) => ({
        label: item?.status_name,
        value: item?.id,
      }))
    );
  };

  useEffect(() => {
    if (dialogUpdate) {
      getListStatus();
    }
  }, [dialogUpdate]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };
      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogUpdate}
        title="Change Status"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="status_id"
                label="Status"
                rules={[{ required: true }]}
              >
                <Select placeholder="Choose status" options={statuses} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateStore;
