import { useEffect } from "react";
import history from "utils/history";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { resetLogin } from "stores/auth";
import { AppDispatch } from "stores";
import { useLocation } from "react-router-dom";

const ErrorBoundary = ({ children }: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();

  const getErr = async (event: any) => {
    console.log(event?.reason);
    if (event?.reason?.response?.status === 401) {
      messageApi.open({
        type: "warning",
        content: "Your Session is expired, please login again",
      });
      dispatch(resetLogin());
      history.replace("/login");
    } else {
      messageApi.open({
        type: "error",
        content: "An Error Ocured",
      });
    }
  };

  useEffect(() => {
    if (pathname !== "/login") {
      window.addEventListener("unhandledrejection", getErr);
    } else {
      return () => {
        window.removeEventListener("unhandledrejection", getErr);
      };
    }
    return () => {
      window.removeEventListener("unhandledrejection", getErr);
    };
  }, [pathname]);

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};

export default ErrorBoundary;
