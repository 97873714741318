import { createAsyncThunk } from "@reduxjs/toolkit";
import SkidOutService from "services/skids/SkidOutServices";

export const get = createAsyncThunk("skidOut/get", async (params: any) => {
  const { data } = await SkidOutService.getSkidOut(params);
  return data;
});

export const search = createAsyncThunk("skidOut/search", async (params: any) => {
  const { data } = await SkidOutService.getSkidOut(params);
  return data;
});
