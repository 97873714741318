import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createStore = (params: any) => {
  return xhrInstance.post("/store", params);
};

const getStore = (params: any) => {
  return xhrInstance.get(objToParams("/store", params));
};

const removeStore = (id: string | number) => {
  return xhrInstance.delete(`/store/${id}`);
};

const updateStore = (params: any) => {
  return xhrInstance.put(`/store/${params.id}`, params.body);
};

const createCustomer = (params: any) => {
  return xhrInstance.post("/customer", params);
};

const getCustomer = (params: any) => {
  return xhrInstance.get(objToParams("/customer", params));
};

const removeCustomer = (id: string | number) => {
  return xhrInstance.delete(`/customer/${id}`);
};

const updateCustomer = (params: any) => {
  return xhrInstance.put(`/customer/${params.id}`, params.body);
};

const MasterDataService = {
  createStore,
  getStore,
  removeStore,
  updateStore,
  createCustomer,
  getCustomer,
  removeCustomer,
  updateCustomer,
};

export default MasterDataService;
