import React from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  Typography,
  Space,
  InputNumber,
} from "antd";
import Logo from "assets/images/sci_logo.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { LoginAction } from "stores/auth/actions";
import { AppDispatch } from "stores";

const { Title } = Typography;

const Login: React.FC = () => {
  const isLoading = useSelector((state: any) => state.auth.isLoading);
  const dispatch = useDispatch<AppDispatch>();

  const onFinish = (values: any) => {
    dispatch(LoginAction(values)).unwrap();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row align="middle" style={{ height: "100vh" }}>
        <Col span={12}>
          <Row justify="center">
            <img width={400} src={Logo} alt="logo" />
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="center">
            <Space direction="vertical" size="middle">
              <Space direction="vertical" size="small">
                <Title style={{ margin: 0 }}>Login to your account</Title>
                <Title style={{ margin: 0 }} level={4} type="secondary">
                  Welcome back! Please enter your detail
                </Title>
              </Space>

              <Form
                name="basic"
                labelCol={{ span: 5 }}
                size="middle"
                style={{ maxWidth: 400 }}
                initialValues={{ type: 1 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  name="nik"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject("Please input your NIK");
                        }
                        if (/\b\d{5}\b/g.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Only number & must be 5 characters"
                          );
                        }
                      },
                    },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="NIK" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  Login
                </Button>
              </Form>
            </Space>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
