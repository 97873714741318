import {
  Modal,
  Button,
  Form,
  Input,
  Col,
  Row,
  Select,
  InputNumber,
} from "antd";
import useDialogCreate from "pages/mtn/order-history/purchase/sections/action-table/dialog/useDialogCreate";

const { TextArea } = Input;

const UpdateStore = () => {
  const { dialogAdd, partOption, isLoading, form, onCancel, onSubmit } =
    useDialogCreate();

  return (
    <>
      <Modal
        // width={800}
        open={dialogAdd}
        title="Create Purchase Part"
        onCancel={() => onCancel()}
        maskClosable={false}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            onClick={onSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="part_id"
                label="List Parts"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.repairs}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="qty"
                label="Quantity"
                rules={[
                  { required: true },
                  {
                    validator: async (rule, value) => {
                      if (value === 0) {
                        throw new Error("Quantity must more than 0");
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min="0" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                // rules={[{ required: true }]}
              >
                <TextArea rows={2} placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateStore;
