import { Modal, Button, Image, Col, Row, Empty } from "antd";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import PartService from "services/mtns/part/PartService";
import fileToBase64 from "utils/fileToBase64";
import { useReactToPrint } from "react-to-print";

const DetailPart = ({ toggleDetail, setToggleDetail, dataRecord }: any) => {
  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleCancel = () => {
    setToggleDetail(false);
  };

  const TextTitle = styled.div`
    font-weight: 600;
    font-size: 1rem;
  `;

  const ImageDetail = styled(Image)`
    width: 150px !important;
    height: 150px !important;
    border-radius: 8px;
    object-fit: contain;
  `;

  const idr = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumSignificantDigits: 1,
  });

  const [imgBase64, setImgBase64]: any = useState("");
  const [imgQr, setImgQr]: any = useState("");
  const dialogOpened = async () => {
    if (dataRecord.filename) {
      const res = await PartService.getPartImage({}, dataRecord.filename);
      const base64 = await fileToBase64(res?.data);
      setImgBase64(base64);
    }

    const { data } = await PartService.getPartQr(dataRecord?.id);
    setImgQr(data?.data?.qrcode);
  };

  useEffect(() => {
    if (toggleDetail) {
      dialogOpened();
    }
  }, [toggleDetail]);

  const detailDatas: any = {
    "ID Part": dataRecord?.part_number,
    Name: dataRecord?.part_name,
    Group: dataRecord?.group_name || "",
    Size: dataRecord?.size,
    Quantity: dataRecord?.qty,
    Unit: dataRecord?.unit_name,
    Category: dataRecord?.category_name || "",
    "Stock Limit": dataRecord?.stock_limit,
    "Stock Max": dataRecord?.stock_max,
    Class: dataRecord?.class_name,
    Line: dataRecord?.line_name,
    Type: dataRecord?.type,
    Maker: dataRecord?.maker_name,
    I_L: dataRecord?.I_L,
    Supplier: dataRecord?.supplier_name,
    Store: dataRecord?.store_code,
    Price: idr.format(dataRecord?.price),
    Remarks: dataRecord?.remarks ? dataRecord?.remarks : "-" 
  };

  const PrintArea = styled.div`
    width: 80mm;
    height: 40mm;
    border: 1px solid black;
    padding: 2mm;
    margin-right: 10px;
    box-sizing: border-box;
    display: flex;
  `;

  // const S

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={toggleDetail}
        title="Part Detail"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
        ]}
      >
        <Row align="middle" className="mb-5">
          <PrintArea ref={componentRef}>
            <div style={{ width: "55%", fontSize: "10px", lineHeight: "19px" }}>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  ID
                </span>
                <span>:</span>
                <span> {dataRecord?.part_number}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Name
                </span>
                <span>:</span>

                <span> {dataRecord?.part_name}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Size
                </span>
                <span>:</span>

                <span> {dataRecord?.size}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Type
                </span>
                <span>:</span>

                <span> {dataRecord?.type}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Maker
                </span>
                <span>:</span>

                <span> {dataRecord?.maker_name}</span>
              </div>
              <div>
                <span style={{ width: "20%", display: "inline-block" }}>
                  Store
                </span>
                <span>:</span>

                <span> {dataRecord?.store_code}</span>
              </div>
            </div>

            {/* <Row> */}
            <div style={{ width: "45%" }}>
              <img src={imgQr} style={{ width: "100%" }} alt="qr" />
            </div>
            {/* <Col span={10}>
            </Col> */}
            {/* </Row> */}
          </PrintArea>
          <Button onClick={handlePrint} type="primary">
            Print QR
          </Button>
        </Row>

        <div className="mb-3">
          <TextTitle className="mb-2">Foto</TextTitle>
          {dataRecord?.filename ? (
            <ImageDetail src={imgBase64} />
          ) : (
            <Empty imageStyle={{ textAlign: "left" }} description={false} />
          )}
        </div>
        <Row gutter={[10, 10]}>
          {Object.keys(detailDatas)?.map((item) => (
            <Col key={item} span={12}>
              <div>
                <TextTitle>{item}</TextTitle>
                <div> {detailDatas[item]}</div>
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default DetailPart;
