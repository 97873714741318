import xhrInstance from "services/config";

const getSummary = () => {
  return xhrInstance.get("/skid/summary");
};

const SummaryService = {
  getSummary,
};

export default SummaryService;
