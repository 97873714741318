import { useEffect, useState } from "react";
import { Typography, DatePicker, Statistic } from "antd";
import { Col, Row, Card } from "antd";
import SummaryMtn from "services/mtns/summary";
import dayjs from "dayjs";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const SummaryPartApproval = () => {
  const [part, setPart]: any = useState({ isLoading: false, data: {} });
  const [date, setDate] = useState({
    part: null,
  });
  const handleDatePart = async (val: any) => {
    setDate((v) => ({ ...val, part: val }));

    const params: any = {};
    if (val) {
      params.start_date = dayjs(val[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(val[2]).format("YYYY-MM-DD");
    }
    const { data } = await SummaryMtn.partApproval(params);
    setPart(() => ({ ...part, isLoading: false, data: data.data }));
  };

  const getPart = async () => {
    const { data } = await SummaryMtn.partApproval({});
    setPart(() => ({ ...part, isLoading: false, data: data.data }));
  };
  useEffect(() => {
    getPart();
  }, []);

  return (
    <Card className="mb-3">
      <Title level={3}>Part Approval</Title>
      <RangePicker
        onChange={handleDatePart}
        value={date.part}
        format={"DD/MM/YYYY"}
        className="mb-3"
      />
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title="Total Approval Part"
            groupSeparator="."
            value={part.data?.totalPartApproval || 0}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Part Accept"
            groupSeparator="."
            value={part.data?.totalPartApproved || 0}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Part Reject"
            groupSeparator="."
            value={part.data?.totalPartRejected || 0}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SummaryPartApproval;
