import { useEffect, useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import {
  setSearch,
  resetDeliveryOrder,
  setDate,
  setSender,
} from "stores/print/pickup_order";
import MasterDataService from "services/skids/MasterDataService";
import * as userAction from "stores/print/pickup_order/actions/pickup_order";
import debounce from "lodash/debounce";
import dayjs from "dayjs";

const useAction = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch, date, sender } = useSelector(
    (state: any) => state.pickupOrder
  );

  const [senders, setSenders] = useState([]);
  const getSenders = async () => {
    const res = await MasterDataService.getCustomer({
      page: 1,
      per_page: 10000,
    });
    const newData = res?.data?.data?.map((item: any) => ({
      ...item,
      value: item?.id,
      label: item.name,
    }));
    setSenders(newData);
  };

  useEffect(() => {
    getSenders();
    dispatch(resetDeliveryOrder());
  }, []);

  const sendBackendRequest = useCallback((value: string) => {
    dispatch(userAction.search(value)).unwrap();
  }, []);
  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);
  const handleChange = (e: any) => {
    const value = e?.target?.value;

    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: value,
      customer: sender,
    };

    if (date.length > 0) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    dispatch(setSearch(value));
    debouncedSendRequest(params);
  };

  const handleSearch = (val: any) => {
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: val,
      customer: sender,
    };

    if (date.length > 0) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    dispatch(setSearch(val));
    dispatch(userAction.search(params)).unwrap();
  };

  const handleChangeDate = (dateValue: any) => {
    dispatch(setDate(dateValue ? dateValue : []));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      customer: sender,
    };

    if (dateValue) {
      params.start_date = dayjs(dateValue[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(dateValue[1]).format("YYYY-MM-DD");
    }

    dispatch(userAction.get(params)).unwrap();
  };

  const handleChangeSender = (value: any) => {
    dispatch(setSender(value));
    const params: any = {
      page: 1,
      per_page: pageSize,
      keyword: search,
      customer: value,
    };

    if (date.length > 0) {
      params.start_date = dayjs(date[0]).format("YYYY-MM-DD");
      params.end_date = dayjs(date[1]).format("YYYY-MM-DD");
    }

    dispatch(userAction.get(params)).unwrap();
  };

  return {
    senders,
    setSenders,
    handleChange,
    handleChangeDate,
    handleChangeSender,
    handleSearch,
    isLoadingSearch,
    date,
    search,
  };
};

export default useAction;
