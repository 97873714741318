import { useEffect, useState } from "react";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/approval/actions/approval";
import { EditOutlined } from "@ant-design/icons";
import UpdateStoreDialog from "pages/mtn/approval/sections/table-pagination/dialog/update";
// import PrintStoreDialog from "pages/mtn/master_data/store/sections/table-pagination/dialog/print";
import {
  toggleUpdateDialog,
  // togglePrintDialog,
  setPageSize,
  resetApproval
} from "stores/approval";

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const { isLoadingData, currentPage, totalData, search, pageSize, datas } =
    useSelector((state: any) => state.approval);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();

    return () => {
      dispatch(resetApproval());
    }
  }, []);

  const handleDialogUpdate = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleUpdateDialog(true));
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 10,
      fixed: "left",
      align: "center",
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      key: "part_number",
      width: 100,
      fixed: "left",
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "part_name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 100,
      fixed: "left",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      fixed: "left",
    },
    {
      title: "Quantity Out",
      dataIndex: "qty",
      key: "qty",
      width: 100,
      fixed: "left",
    },
    {
      title: "Scanned By",
      dataIndex: "username",
      key: "username",
      width: 100,
      fixed: "left",
    },

    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 100,
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Options",
      key: "options",
      width: 50,
      fixed: "left",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Edit">
              <Button
                onClick={() => handleDialogUpdate(dataRecord)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <UpdateStoreDialog dataRecord={currentRecord} />
      {/* <PrintStoreDialog dataRecord={currentRecord} /> */}

      <Table
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
