import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getLabel = (params: any) => {
  return xhrInstance.get(objToParams("/cetak/label", params));
};

const createDeliveryOrder = (params: any) => {
  return xhrInstance.post("/cetak", params);
};

const getDeliveryOrder = (params: any) => {
  return xhrInstance.get(objToParams("/cetak", params));
};

const removeDeliveryOrder = (id: any) => {
  return xhrInstance.delete(`/cetak/${id}`);
};

const detailDeliveryOrder = (letterId: any) => {
  return xhrInstance.get(`/cetak/${letterId}`);
};

const getPickupOrder = (params: any) => {
  return xhrInstance.get(objToParams("/pickup", params));
};

const removePickupOrder = (id: any) => {
  return xhrInstance.delete(`/pickup/${id}`);
};

const detailPickupOrder = (id: any) => {
  return xhrInstance.get(`/pickup/${id}`);
};

const createPickupOrder = (params: any) => {
  return xhrInstance.post("/pickup", params);
};

const PrintService = {
  getLabel,
  createDeliveryOrder,
  getDeliveryOrder,
  removeDeliveryOrder,
  detailDeliveryOrder,
  getPickupOrder,
  removePickupOrder,
  detailPickupOrder,
  createPickupOrder,
};

export default PrintService;
