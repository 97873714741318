import { useEffect } from "react";
import { Card, Input, Row, Col, Button, Form, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DialogCreateSkid from "pages/skid/skid/list/sections/action-table/dialog/create";
import DialogPrint from "pages/skid/skid/list/sections/action-table/dialog/print";
import { toggleAddDialog, resetSkidList } from "stores/skid/list";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import useAction from "pages/skid/skid/list/sections/action-table/hooks/useAction";

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const {
    handleSearch,
    handleChangeSearch,
    handleChangeThick,
    handleChangeWidth,
    handleChangeLenghth1,
    handleChangeLenghth2,
    handleClear,
  } = useAction();
  const { isLoadingSearch } = useSelector((state: any) => state.skidList);

  useEffect(() => {
    return () => {
      dispatch(resetSkidList());
    };
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Form form={form}>
          <Row justify={"space-between"}>
            <Col span={6}>
              <Form.Item label="" name="search">
                <Input.Search
                  loading={isLoadingSearch}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  onSearch={() => {
                    handleSearch();
                  }}
                  enterButton
                />
              </Form.Item>
            </Col>
            <Row>
              <Col>
                <Button
                  onClick={() => dispatch(toggleAddDialog(true))}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </Row>

          <Form.Item label="Skid Size" style={{ marginBottom: 0 }}>
            <Row gutter={15}>
              <Col span={3}>
                <Form.Item name="thick">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Thick"
                    min={0}
                    onChange={handleChangeThick}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="Width">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Width"
                    min={0}
                    onChange={handleChangeWidth}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length1">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 1"
                    min={0}
                    onChange={handleChangeLenghth1}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length2">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 2"
                    min={0}
                    onChange={handleChangeLenghth2}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3} offset={9}>
                <Button onClick={() => handleClear(form)} block type="primary">
                  Clear
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
      <DialogCreateSkid />
      <DialogPrint />
    </>
  );
};

export default ActionTable;
