import React, { useEffect, useState } from "react";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get, remove } from "stores/master-data/mtn-store/actions/store";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import UpdateStoreDialog from "pages/mtn/master_data/store/sections/table-pagination/dialog/update";
import PrintStoreDialog from "pages/mtn/master_data/store/sections/table-pagination/dialog/print";
import {
  toggleUpdateDialog,
  togglePrintDialog,
  setPageSize,
  resetMtnStore
} from "stores/master-data/mtn-store";

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const { isLoadingData, currentPage, totalData, search, pageSize, datas } =
    useSelector((state: any) => state.mtnStore);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();

    return () => {
      dispatch(resetMtnStore());
    }
  }, []);

  const handleDialogUpdate = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleUpdateDialog(true));
  };

  const handleDialogPrint = (record: any) => {
    setCurrentRecord(record);
    dispatch(togglePrintDialog(true));
  };

  const handleDelete = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await dispatch(remove(dataRecord.id)).unwrap();
        const params = {
          page: 1,
          per_page: pageSize,
        };
        await dispatch(get(params)).unwrap();
      },
    });
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 10,
      fixed: "left",
      align: "center",
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: 100,
      fixed: "left",
    },
    {
      title: "Rack Number",
      dataIndex: "rack_number",
      key: "rack_number",
      width: 100,
      fixed: "left",
    },

    {
      title: "Level",
      dataIndex: "leveling",
      key: "leveling",
      width: 100,
      fixed: "left",
    },
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      width: 100,
      fixed: "left",
    },
    {
      title: "Options",
      key: "options",
      width: 50,
      fixed: "left",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Print">
              <Button
                onClick={() => handleDialogPrint(dataRecord)}
                type="primary"
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                onClick={() => handleDialogUpdate(dataRecord)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>

            <Tooltip title="Delete">
              <Button
                onClick={() => handleDelete(dataRecord)}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <UpdateStoreDialog dataRecord={currentRecord} />
      <PrintStoreDialog dataRecord={currentRecord} />

      <Table
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
