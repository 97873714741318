import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Row } from "antd";
import { togglePrintDialog } from "stores/master-data/mtn-store";
import { AppDispatch } from "stores";
import { useReactToPrint } from "react-to-print";

const UpdateStore = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogPrint } = useSelector((state: any) => state.mtnStore);

  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCancel = () => {
    form.resetFields();
    dispatch(togglePrintDialog(false));
  };

  return (
    <>
      <Modal
        forceRender
        open={dialogPrint}
        title="Preview"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button onClick={handlePrint} key="submit" type="primary">
            Print
          </Button>,
        ]}
      >
        <Row justify="center" className="mb-5">
          <div
            ref={componentRef}
            style={{
              textAlign: "center",
              fontWeight: "700",
              width: "4cm",
              height: "4cm",
              marginBottom: "1cm",
              border: "1px solid black",
              marginRight: "0.5cm",
              marginLeft: "0.5cm",
            }}
          >
            <img
              style={{ width: "3.2cm", height: "3.2cm" }}
              src={dataRecord?.qrcode}
              alt="print_store"
            />
            <div style={{ fontSize: 14, lineHeight: 1 }}>
              {dataRecord?.code}
            </div>
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default UpdateStore;
