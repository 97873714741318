import {
  Modal,
  Button,
  Form,
  Input,
  Col,
  Row,
  InputNumber,
  Select,
} from "antd";
import useDialogUpdate from "pages/mtn/part/sections/tabs/repair/table-pagination/dialog/useDialogUpdate";
import styled from "styled-components";

const { TextArea } = Input;

const UpdatePart = ({ dataRecord }: any) => {
  const {
    dialogUpdate,
    partOption,
    isLoadingUpdate,
    form,
    imgBase64,
    imgRef,
    handleSubmit,
    handleCancel,
    handleRefImg,
    handleUpload,
  } = useDialogUpdate(dataRecord);

  const UploadImage = styled.div`
    color: #d9d9d9;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px dashed #f4801a;
      cursor: pointer;
      color: #f4801a;
    }
  `;

  const ImagePrev = styled.img`
    color: #d9d9d9;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    &:hover {
      border: 1px dashed #f4801a;
      cursor: pointer;
      color: #f4801a;
    }
  `;

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        // width={800}
        open={dialogUpdate}
        title="Edit Part"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          {/* <Row>
            <Col span={12}>
              <Form.Item name="file" label="Foto">
                {imgBase64 ? (
                  <ImagePrev onClick={handleRefImg} src={imgBase64} />
                ) : (
                  <UploadImage onClick={handleRefImg}>Upload</UploadImage>
                )}

                <input
                  onChange={handleUpload}
                  ref={imgRef}
                  hidden
                  type="file"
                  accept=".jpg,.png,.jpeg"
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="part_name"
                label="Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item name="size" label="Size" rules={[{ required: true }]}>
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="unit_id"
                label="Unit"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.units}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="rank_id"
                label="Rank"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.ranks}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="category_id"
                label="Category"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.categories}
                />
              </Form.Item>
            </Col> */}

            {/* <Col span={12}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: true }]}
              >
                <TextArea rows={2} placeholder="Type here..." />
              </Form.Item>
            </Col> */}

            {/* <Col span={12}>
              <Form.Item
                name="stock_limit"
                label="Stock Limit"
                rules={[
                  { required: true },
                  {
                    validator: async (rule, value) => {
                      if (value === 0) {
                        throw new Error("Quantity must more than 0");
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min="0" />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="class_id"
                label="Class"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.classes}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="line_id"
                label="Line"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.lines}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="maker_id"
                label="Maker"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.makers}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item name="I_L" label="I_L" rules={[{ required: true }]}>
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    { label: "I", value: "I" },
                    { label: "L", value: "L" },
                  ]}
                />
              </Form.Item>
            </Col> */}

            {/* <Col span={12}>
              <Form.Item
                name="supplier_id"
                label="Supplier"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.suppliers}
                />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                name="store_id"
                label="Store"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={partOption?.stores}
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true },
                  {
                    validator: async (rule, value) => {
                      if (value === 0) {
                        throw new Error("Price must more than 0");
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  formatter={(value: any) =>
                    `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value.replace(/\Rp\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                  min="0"
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                name="is_repair"
                label="Repair"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  options={[
                    { value: 1, label: "Yes" },
                    { value: 0, label: "No" },
                  ]}
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePart;
