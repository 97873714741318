import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "components/Breadcrumb";
import { setLastLogin, resetLogin } from "stores/auth";
import history from "utils/history";

// import Breadcrumbs from "components/breadcrumb";
// import MemberLayout from "components/layout/mall/memberLayout";
import { Card, Col, Row, message, Form, Input, Button } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import AuthService from "services/AuthService";

const Profile = () => {
  const [formUser] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [toggleBtn, setToggleBtn] = useState(false);
  const [disable, setDisable] = useState(true);
  const [isLoadingPass, setIsLoadingPass] = useState(false);
  const user = useSelector((state: any) => state.auth.lastLogin);
  const dispatch = useDispatch();

  const bItem = [
    { name: "Dashboard", to: "/" },
    { name: "Profile", to: "/profile" },
  ];

  const editable = () => {
    setToggleBtn(!toggleBtn);
    setDisable(!disable);
  };

  const handleEdit = async () => {
    try {
      const val = await formUser.validateFields();
      await AuthService.changeProfile({ name: val.name }, user.id);
      setToggleBtn(!toggleBtn);
      setDisable(!disable);
      dispatch(setLastLogin({ ...user, name: val.name }));
      message.success("Update user success");
    } catch (err: any) {
      if (!err.errorFields) {
        message.error("Update user failed");
      } else {
        console.log(err);
      }
    }
  };

  const handleChangePassword = async () => {
    try {
      const val = await formPassword.validateFields();
      setIsLoadingPass(true);
      if (val.new_password !== val.new_password_confirmation)
        throw Error("New password and New password confirmation must same!");
      await AuthService.changeProfile({ password: val.new_password, confirm_password: val.new_password_confirmation }, user.id);

      formPassword.resetFields();
      message.success("Change password success");
      setIsLoadingPass(false);
      dispatch(resetLogin());
      history.replace("/login");
    } catch (err: any) {
      if (err.response) {
        message.error("Invalid old password");
      } else if (err.message) {
        message.error(err.message);
      } else {
        console.log(err);
      }
      setIsLoadingPass(false);
    }
  };

  return (
    // <MemberLayout>
    <div className="main-content">
      <Breadcrumb title="Profile" items={bItem} />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <Title level={4}>Profile Information</Title>
            <Text>Update your profile information here</Text>
            <Form
              initialValues={{
                name: user.name,
                nik: user.nik,
                department: user.department,
                role: user.role.name,
              }}
              name="basic"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 5 }}
              autoComplete="off"
              // onFinish={handleEdit}
              form={formUser}
            >
              <Form.Item
                label="NIK"
                name="nik"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input disabled={disable} />
              </Form.Item>
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  { required: true, message: "Please input your department!" },
                ]}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please input your role!" }]}
              >
                <Input disabled />
              </Form.Item>

              {toggleBtn ? (
                <Form.Item>
                  <Button
                    // loading={isLoading}
                    type="primary"
                    onClick={handleEdit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button onClick={editable} type="primary">
                    Edit
                  </Button>
                </Form.Item>
              )}
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Title level={4}>Password Information</Title>

            <Text>Update your Password information here</Text>
            <Form
              form={formPassword}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 14 }}
              name="basic2"
              // labelCol={{ span: 8 }}
              autoComplete="off"
            >
              {/* <Form.Item
                label="Old Password"
                name="old_password"
                rules={[{ required: true, message: "Old Password!" }]}
              >
                <Input.Password />
              </Form.Item> */}
              <Form.Item
                label="New Password:"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your New Password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm New Password:"
                name="new_password_confirmation"
                rules={[
                  {
                    required: true,
                    message: "Please input your New Password Confirmation!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  // loading={isLoadingPass}
                  onClick={handleChangePassword}
                  type="primary"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
