import { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Row } from "antd";
import { togglePreviewDialog } from "stores/order-history/purchase";
import Logo from "assets/images/sci_logo.jpeg";
import css from "assets/styles/PurchaseOrder.module.css";
import dayjs from "dayjs";
import JsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import PurchaseService from "services/mtns/order-history/PurchaseService";

const PreviewPurchase = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const componentRef: any = useRef();

  const { dialogPreview, previewDatas } = useSelector(
    (state: any) => state.purchase
  );

  const [previewData, setPreviewData] = useState([]);
  const [dateOrder, setOrderDate] = useState("");
  const handlePrintPreview = async () => {
    const { data } = await PurchaseService.printPurchase({
      id: dataRecord?.id,
    });
    setPreviewData(data?.data);
    setOrderDate(data?.tgl_pesan)
  };

  useEffect(() => {
    if (dialogPreview) {
      handlePrintPreview();
    }
  }, [dialogPreview]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCancel = () => {
    dispatch(togglePreviewDialog(false));
    // dispatch(setPreviewDatas({}));
  };

  const toPdf = () => {
    const el: any = document.querySelector("#report");
    const pdf = new JsPDF();
    pdf
      .html(el, {
        margin: [11, 11, 11, 11],
        autoPaging: "slice",
        x: 0,
        y: 0,
        width: 190,
        windowWidth: 675,
      })
      .then(() => {
        pdf.save("purchasing_order.pdf");
      });
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogPreview}
        title="Preview"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button onClick={handlePrint} key="print" type="primary">
            Print
          </Button>,
          <Button onClick={toPdf} key="download" type="primary">
            Download
          </Button>,
        ]}
        width={"210mm"}
      >
        <div ref={componentRef} id="report">
          <div className={css.wrap_main}>
            <Row justify="space-between">
              <div className={css.wrap_logo}>
                <img className={css.logo} src={Logo} alt="cop" />
                <div> PT. STEEL CENTER INDONESIA</div>
              </div>
              <div>
                <div className={css.warp_work_cop}>
                  <div className={css.date_value}>DEPT / SEKSI</div>
                  <div className={css.space}>:</div>
                  <div className={css.date_value}>Maintenance</div>
                </div>
                <div className={css.warp_date_cop}>
                  <div className={css.date_value}>TANGGAL PESAN</div>
                  <div className={css.space}>:</div>
                  <div className={css.date_value}>
                    {dayjs.utc(dateOrder).format("DD/MM/YYYY")}
                  </div>
                </div>
              </div>
              {/* <div className={css.wrap_cop}></div> */}
              <div className={css.section_main}>
                <div className={css.title}>PERMINTAAN PEMBELIAN</div>
              </div>
            </Row>
            <br />
            <Row>
              <table className={css.table}>
                <tr className={css.border_data}>
                  <th className={css.no_table}>NO</th>
                  <th colSpan={2}>NAMA BARANG</th>
                  <th colSpan={2}>SPESIFIKASI BARANG</th>
                  <th colSpan={1}>QTY (SATUAN)</th>
                  <th colSpan={3}>KETERANGAN</th>
                  <th colSpan={2}>TANGGAL KIRIM</th>
                </tr>
                {previewData?.map((item: any, index: number) => (
                  <tr key={item.no} className={css.border_data}>
                    <td>{item?.no}</td>
                    <td colSpan={2}>{item?.nama_barang}</td>
                    <td colSpan={2}>{item?.spesifikasi_barang}</td>
                    <td colSpan={1}>{item?.qty}</td>
                    <td colSpan={3}>{item?.keterangan}</td>
                    <td colSpan={2}></td>
                  </tr>
                ))}
              </table>
              <div className={css.wrap_note}>
                <div className={css.note}>
                  1. Data barang yang diminta harap diisi dengan jelas
                </div>
                <div className={css.note_two}>
                  <div className={css.note}>
                    2. Setelah persetujuan atasan tidak boleh merubah permintaan
                  </div>
                  <div className={css.text_note_two}>
                    Jika tangal tidak diisi maka mengikuti lead team standard
                    purchasing
                  </div>
                </div>
              </div>
              <div className={css.wrap_tdd}>
                <div className={css.wrap_tdd_section}>
                  <div className={css.ttd}>
                    <div>PEMESAN</div>
                  </div>
                  <div className={css.ttd_other}>
                    <div>LEAD / ASP</div>
                  </div>
                  <div className={css.ttd_other}>
                    <div>SPV / MGR</div>
                  </div>
                  <div className={css.ttd_other}>
                    <div>GM</div>
                  </div>
                  <div className={css.ttd_other}>
                    <div>DIREKTUR</div>
                  </div>
                </div>
                <div className={css.ttd}>
                  <div>PURCHASING</div>
                </div>
              </div>
            </Row>
          </div>
          <div className={css.letter_id}>F-PUR-001 Rev. 02</div>
        </div>
      </Modal>
    </>
  );
};

export default PreviewPurchase;
