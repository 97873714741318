import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProjectOutlined,
  DashboardOutlined,
  SaveOutlined,
  DatabaseOutlined,
  FundProjectionScreenOutlined,
  PrinterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import useMenuSkid from "components/BaseLayout/sections/skid-item";
import useMtnMenu from "components/BaseLayout/sections/mtn-item";
import "assets/styles/custom_layout.css";
import style from "assets/styles/layout.module.css";

const { Sider } = Layout;

const SidebarLayout: any = ({ collapsed, setCollapsed }: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { poId } = useParams();

  // const [collapsed, setCollapsed] = useState(false);
  const [openSub, setOpenSub] = useState<any>([]);
  const menuMode = useSelector((state: any) => state.auth.menuMode);

  const handleSelectMenu = ({ key }: any) => {
    navigate(key);
  };

  const handleSubMenu = (e: any) => {
    setOpenSub(e);
  };

  const skidItem = useMenuSkid();
  const mtnItem = useMtnMenu();

  const excludeMenus = (menu: any) => {
    const keys = [
      "/profile",
      "/mtn/inventory/compare",
      `/mtn/part/in/po/${poId}/detail`,
    ];
    return menu.filter((m: any) => !keys.includes(m.key));
  };

  const items = [
    {
      key: "/",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/skid",
      icon: <ProjectOutlined />,
      label: "SKID",
      children: [
        {
          key: "/skid/list",
          label: "List",
        },
        {
          key: "/skid/in",
          label: "IN",
        },
        {
          key: "/skid/out",
          label: "OUT",
        },
      ],
    },
    {
      key: "/inventory",
      icon: <SaveOutlined />,
      label: "Inventory",
    },
    {
      key: "/master-data",
      icon: <DatabaseOutlined />,
      label: "Master Data",
      children: [
        {
          key: "/master-data/customer",
          label: "Customer",
        },
        {
          key: "/master-data/store",
          label: "Store",
        },
      ],
    },
    {
      key: "/report",
      icon: <FundProjectionScreenOutlined />,
      label: "Report",
      children: [
        {
          key: "/menu2-1",
          label: "Option 1",
        },
        {
          key: "/menu2-2",
          label: "Option 2",
        },
      ],
    },
    {
      key: "/print",
      icon: <PrinterOutlined />,
      label: "Print",
      children: [
        {
          key: "/menu2-1",
          label: "Option 1",
        },
        {
          key: "/menu2-2",
          label: "Option 2",
        },
      ],
    },
    {
      key: "/users",
      icon: <UsergroupAddOutlined />,
      label: "User",
      children: [
        {
          key: "/menu2-1",
          label: "Option 1",
        },
        {
          key: "/menu2-2",
          label: "Option 2",
        },
      ],
    },
  ];

  useEffect(() => {
    const foundChild = items
      .map((item, index) => ({
        ...item,
        children: item.children,
        index,
      }))
      .filter((item) => item.children);

    const foundSameKey = foundChild
      .map((item) => {
        return {
          index: item.index,
          find: item?.children?.filter((item) => item.key === pathname),
        };
      })
      .find((item) => item?.find?.length !== 0);

    const finalKey = foundSameKey ? items[foundSameKey.index] : { key: "" };
    setOpenSub([finalKey.key]);
  }, []);

  const selectionMenu = (pathName: any) => {
    if (pathName === "/mtn/inventory/compare") {
      return "/mtn/inventory/process";
    } else {
      return pathName;
    }
  };

  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width="256"
      className={style.sidebar}
      style={{
        overflow: "scroll",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        marginTop: "60px",
      }}
    >
      <div
        className={collapsed ? style.collapseInactive : style.collapseActive}
      >
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: style.trigger,
            onClick: () => setCollapsed(!collapsed),
          }
        )}
      </div>
      <Menu
        theme="light"
        mode="inline"
        openKeys={openSub}
        selectedKeys={[selectionMenu(pathname)]}
        items={
          menuMode === "skid" ? excludeMenus(skidItem) : excludeMenus(mtnItem)
        }
        onOpenChange={handleSubMenu}
        onSelect={handleSelectMenu}
        // inlineIndent={24}
      />
    </Sider>
  );
};

export default SidebarLayout;
