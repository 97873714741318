import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { get, remove } from "stores/skid/list/actions/list";
import UpdateStoreDialog from "pages/skid/skid/list/sections/table-pagination/dialog/update";
import AddQuantityDialog from "pages/skid/skid/list/sections/table-pagination/dialog/addQuantity";
import {
  toggleUpdateDialog,
  toggleAddQuantityDialog,
  setPageSize,
} from "stores/skid/list";
import zeroSize from "utils/zeroSize";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const { isLoadingData, currentPage, totalData, search, pageSize, datas } =
    useSelector((state: any) => state.skidList);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handleDialogUpdate = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleUpdateDialog(true));
  };

  const handleDialogAddQty = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleAddQuantityDialog(true));
  };

  const handleDelete = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await dispatch(remove(dataRecord.assetid)).unwrap();
        const params = {
          page: 1,
          perpage: pageSize,
        };
        await dispatch(get(params)).unwrap();
      },
    });
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      perpage: pageSize,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      // fixed: "left",
      align: "center",
    },
    {
      title: "Customer",
      // dataIndex: "customer",
      key: "customer",
      width: 100,
      render: (dataRecord: any) => dataRecord?.customer?.name,

      // fixed: "left",
    },
    {
      title: "Code SKID",
      dataIndex: "no_label",
      key: "no_label",
      width: 150,
      // fixed: "left",
    },
    {
      title: "Size SKID",
      children: [
        {
          title: "T",
          key: "skidT",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.thick),
        },
        {
          title: "W",
          key: "skidW",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.width),
        },
        {
          title: "L1",
          key: "skidL1",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length1),
        },
        {
          title: "L2",
          key: "skidL2",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length2),
        },
      ],
    },
    {
      title: "Store",
      // dataIndex: "store",
      key: "store",
      width: 150,
      render: (dataRecord: any) => dataRecord?.store?.name,

      // fixed: "left",
    },
    {
      title: "Options",
      key: "options",
      width: 120,
      // fixed: "left",
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Add Quantity">
              <Button
                onClick={() => handleDialogAddQty(dataRecord)}
                type="primary"
                shape="circle"
                icon={<PlusCircleOutlined />}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                onClick={() => handleDialogUpdate(dataRecord)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                onClick={() => handleDelete(dataRecord)}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <UpdateStoreDialog dataRecord={currentRecord} />
      <AddQuantityDialog dataRecord={currentRecord} />

      <Table
        bordered
        rowKey={(record: any) => record.assetid}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
        scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
