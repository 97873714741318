import SkidListService from "services/skids/SkidListService";

const FetchLast = () => {
  const lastSkid: any = async (id: number | string) => {
    try {
      const res = await SkidListService.getLastSkid(id);
      return res?.data?.data;
    } catch (err) {
      return {};
    }
  };

  return { lastSkid };
};

export default FetchLast;
