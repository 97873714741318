import Repair from "pages/mtn/order-history/repair";
import Purchase from "pages/mtn/order-history/purchase"

const orderHistory = [
  {
    path: "/mtn/order-history/repair",
    element: <Repair />,
  },
  {
    path: "/mtn/order-history/purchase",
    element: <Purchase />,
  },
];

export default orderHistory;
