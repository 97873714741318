import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Repair", to: "/mtn/order-history/repair" },
  ];

  return <Breadcrumb title="Order Repair" items={bItem} />;
};

export default TitleStore;
