import { Modal, Button, Image, Col, Row } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
// import PartService from "services/mtns/part/PartService";
import fileToBase64 from "utils/fileToBase64";

const DetailRepairPart = ({
  toggleDetail,
  setToggleDetail,
  dataRecord,
}: any) => {
  const handleCancel = () => {
    setToggleDetail(false);
  };

  const TextTitle = styled.div`
    font-weight: 600;
    font-size: 1rem;
  `;

  const ImageDetail = styled(Image)`
    width: 150px !important;
    height: 150px !important;
    border-radius: 8px;
    object-fit: contain;
  `;

  const idr = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumSignificantDigits: 1,
  });

  const [imgBase64, setImgBase64]: any = useState("");
  const dialogOpened = async () => {
    // const res = await PartService.getPartImage({}, dataRecord.filename);
    // const base64 = await fileToBase64(res?.data);
    // setImgBase64(base64);
  };

  useEffect(() => {
    if (toggleDetail) {
      // dialogOpened();
    }
  }, [toggleDetail]);

  const detailDatas: any = {
    "ID Part": dataRecord?.part_repair_number,
    Name: dataRecord?.part_name,
    // Group: dataRecord?.group_name || "",
    Size: dataRecord?.size,
    Quantity: dataRecord?.qty,
    // Unit: dataRecord?.unit_name,
    // Category: dataRecord?.category_name || "",
    // "Stock Limit": dataRecord?.stock_limit,
    // Class: dataRecord?.class_name,
    // Line: dataRecord?.line_name,
    Type: dataRecord?.type,
    Maker: dataRecord?.maker_name || "-",
    // I_L: dataRecord?.I_L,
    Supplier: dataRecord?.supplier_name || "-",
    Store: dataRecord?.store_name,
    Status: dataRecord?.status_name,
    // Price: idr.format(dataRecord?.price),
  };

  const PrintArea = styled.div`
    width: 80mm;
    height: 40mm;
    border: 2px dashed #ffa245;
    margin-bottom: 20px;
  `;

  // const S

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={toggleDetail}
        title="Part Detail"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
        ]}
      >
        {/* <PrintArea>
          <Row>
            <Col span={8}>
              <div>ID part</div>
              <div>Name Part</div>
              <div>Size</div>
              <div>ID</div>
              <div>ID part</div>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
        </PrintArea> */}
        {/* <div className="mb-3">
          <TextTitle className="mb-2">Foto</TextTitle>
          <ImageDetail src={imgBase64} />
        </div> */}
        <Row gutter={[10, 10]}>
          {Object.keys(detailDatas)?.map((item) => (
            <Col key={item} span={12}>
              <div>
                <TextTitle>{item}</TextTitle>
                <div> {detailDatas[item]}</div>
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default DetailRepairPart;
