import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createPart = (params: any) => {
  return xhrInstance.post("/mtn/part/create", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getPart = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part", params));
};

const outPart = (params: any) => {
  return xhrInstance.post(`/mtn/part/out/${params.id}`, params.body);
};

const removePart = (id: string | number) => {
  return xhrInstance.delete(`/mtn/part/${id}`);
};

const updatePart = (params: any) => {
  return xhrInstance.put(`/mtn/part/update/${params.id}`, params.body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getPartRank = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/group", params));
};
const getPartCategory = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/category", params));
};
const getPartGroup = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/part-group", params));
};
const getPartUnit = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/unit", params));
};
const getPartClass = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/class", params));
};
const getPartLine = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/line", params));
};
const getPartSupplier = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/supplier", params));
};
const getPartStore = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/store", params));
};

const getPartMaker = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/maker", params));
};

const getPartImage = (params: any, filename: string) => {
  return xhrInstance.get(objToParams(`/mtn/part/image/${filename}`, params), {
    responseType: "blob",
  });
};

const getPartQr = (id: number | string) => {
  return xhrInstance.get(`/mtn/part/print/${id}`);
};

const purgePart = (id: number | string) => {
  return xhrInstance.delete(`/mtn/part/${id}`);
};

const donwloadPart = (params: Record<string, string | number | boolean>) => {
  return xhrInstance.get(objToParams("/mtn/part/download", params), {
    responseType: "blob",
  });
};

const PartService = {
  createPart,
  getPart,
  outPart,
  removePart,
  updatePart,
  getPartRank,
  getPartCategory,
  getPartGroup,
  getPartUnit,
  getPartClass,
  getPartLine,
  getPartSupplier,
  getPartStore,
  getPartMaker,
  getPartImage,
  getPartQr,
  purgePart,
  donwloadPart,
};

export default PartService;
