import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createRank = (params: any) => {
  return xhrInstance.post("/mtn/rank", params);
};

const getRank = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/rank", params));
};

const removeRank = (id: string | number) => {
  return xhrInstance.delete(`/mtn/rank/${id}`);
};

const updateRank = (params: any) => {
  return xhrInstance.put(`/mtn/rank/${params.id}`, params.body);
};

const RankService = {
  createRank,
  getRank,
  removeRank,
  updateRank,
};

export default RankService;
