import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Button,
  Form,
  Input,
  Col,
  Row,
  InputNumber,
  Select,
} from "antd";

import { toggleAddDialog } from "stores/skid/list";
import { AppDispatch } from "stores";
import {
  get as getSkid,
  create as createSkid,
} from "stores/skid/list/actions/list";
import { get as getCustomer } from "stores/master-data/customer/actions/customer";
import { get as getStore } from "stores/master-data/store/actions/store";

const CreateCustomer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogAdd, isLoading, pageSize } = useSelector(
    (state: any) => state.skidList
  );

  const customers: any = useSelector((state: any) => state.customer.datas)?.map(
    (item: any) => ({
      value: item?.id,
      label: item?.name,
    })
  );
  const stores: any = useSelector((state: any) => state.store.datas)?.map(
    (item: any) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const initGetData = async () => {
    const req: any = {
      page: 1,
      per_page: 100,
    };
    await dispatch(getCustomer(req)).unwrap();
    await dispatch(getStore(req)).unwrap();
  };

  useEffect(() => {
    if (dialogAdd === true) {
      initGetData();
    }
  }, [dialogAdd]);

  const onCancel = () => {
    form.resetFields();
    dispatch(toggleAddDialog(false));
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      const req: any = {
        customer: values.customer,
        type: values.type,
        code: values.code,
        qty: values.qty,
        store: values.store,
        size: {
          thick: values.thick,
          width: values.width,
          length1: values.length1,
          length2: values.length2,
        },
      };

      await dispatch(createSkid(req)).unwrap();
      const params = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(getSkid(params)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        open={dialogAdd}
        title="Create Skid"
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            onClick={onSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          name="userForm"
          initialValues={{
            qty: 0,
          }}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Skid Type"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select Skid Type"
                  options={[
                    { value: "P", label: "P" },
                    { value: "S", label: "S" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="qty"
                label="Quantity"
                rules={[
                  { required: true },
                  {
                    validator: async (rule, value) => {
                      if (value === 0) {
                        throw new Error("Quantity must more than 0");
                      }
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min="0" max="99" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="customer"
                label="Customer"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={customers}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="store"
                label="Store"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={stores}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="code"
                label="Skid Code"
                rules={[
                  { required: true },
                  {
                    validator: async (_, value) => {
                      if (!value.match(/^[0-9A-Za-z]+$/) && value) {
                        throw new Error("Only numbers and letters are allowed");
                      }
                    },
                  },
                  { pattern: /^.{1,15}$/, message: "Max 15 Character" },
                ]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Skid Size" style={{ marginBottom: 0 }}>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="thick">
                  <InputNumber
                    defaultValue="0"
                    min="0.00"
                    step="0.01"
                    style={{ width: "100%" }}
                    placeholder="Thick"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="width">
                  <InputNumber
                    defaultValue="0"
                    min="0.00"
                    step="0.01"
                    style={{ width: "100%" }}
                    placeholder="Width"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="length1">
                  <InputNumber
                    defaultValue="0"
                    min="0.00"
                    step="0.01"
                    style={{ width: "100%" }}
                    placeholder="Length 1"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="length2">
                  <InputNumber
                    defaultValue="0"
                    min="0.00"
                    step="0.01"
                    style={{ width: "100%" }}
                    placeholder="Length 2"
                    stringMode
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCustomer;
