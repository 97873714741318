import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Pagination, Table } from "antd";
import { setPageSize } from "stores/report/summary";
import zeroSize from "utils/zeroSize";
import dayjs from "dayjs";
import { get } from "stores/report/summary/actions/summary";
import PreviewDialog from "pages/skid/report/summary/sections/table-pagination/sections/dialogs/preview";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoadingData,
    currentPage,
    totalData,
    pageSize,
    datas,
    skidName,
    date,
    selectCustomer,
    thick,
    width,
    length1,
    length2,
  } = useSelector((state: any) => state.reportSummary);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
      size: item?.assets?.find((_: any, index: number) => index === 0),
    };
  });

  const falseValue = (str: any) => {
    if (str === "" || str === "0.00" || str === "0") {
      return "";
    } else {
      return parseFloat(str).toFixed(2);
    }
  };

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: skidName,
      start_date: date.length > 0 ? dayjs(date[0]).format("YYYY-MM-DD") : "",
      end_date: date.length > 0 ? dayjs(date[1]).format("YYYY-MM-DD") : "",
      customer: selectCustomer.length > 0 ? selectCustomer.toString() : "",
      thick: falseValue(thick),
      width: falseValue(width),
      length1: falseValue(length1),
      length2: falseValue(length2),
    };

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      // fixed: "left",
      align: "center",
    },
    {
      title: "Customer",
      // dataIndex: "customer",
      key: "customer",
      width: 100,
      render: (dataRecord: any) => dataRecord?.customer?.name,

      // fixed: "left",
    },
    {
      title: "Code SKID",
      dataIndex: "no_label",
      key: "no_label",
      width: 150,
      // fixed: "left",
    },
    {
      title: "Size SKID",
      children: [
        {
          title: "T",
          key: "skidT",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.thick),
        },
        {
          title: "W",
          key: "skidW",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.width),
        },
        {
          title: "L1",
          key: "skidL1",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length1),
        },
        {
          title: "L2",
          key: "skidL2",
          width: 50,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.size?.length2),
        },
      ],
    },
    {
      title: "Asset",
      // dataIndex: "store",
      key: "store",
      width: 80,
      render: (dataRecord: any) =>
        dataRecord?.assets ? dataRecord?.assets?.length : "0",

      // fixed: "left",
    },
    {
      title: "In",
      // dataIndex: "store",
      key: "store",
      width: 80,
      render: (dataRecord: any) => dataRecord?.In,

      // fixed: "left",
    },
    {
      title: "Out",
      // dataIndex: "store",
      key: "store",
      width: 80,
      render: (dataRecord: any) => dataRecord?.Out,

      // fixed: "left",
    },
  ];

  return (
    <Card>
      <Row justify="end" className="mb-5">
        <PreviewDialog />
      </Row>
      <Table
        bordered
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
