import { createSlice } from "@reduxjs/toolkit";
import {
  get,
  search,
  getDetail,
  addRemark,
  getDetailHistory,
  getInv,
} from "stores/inventory/adjust/actions/adjust";

import { message } from "antd";

export interface AsjustInvState {
  dialogAction: boolean;
  dialogActionApprove: boolean;
  dialogAdd: boolean;
  dialogUpdate: boolean;
  dialogPrint: boolean;
  dialogAddQuantity: boolean;
  isLoading: boolean;
  isLoadingModal: boolean;
  isLoadingData: boolean;
  isLoadingRemove: boolean;
  isLoadingUpdate: boolean;
  isLoadingSearch: boolean;
  datas: any;
  printDatas: any;
  currentPage: number;
  totalData: number;
  search: string;
  pageSize: number;
  detailData: any;
  thick: string;
  width: string;
  length1: string;
  length2: string;
  date: any;
}

const initialState: AsjustInvState = {
  dialogAction: false,
  dialogActionApprove: false,
  dialogAdd: false,
  dialogUpdate: false,
  dialogPrint: false,
  dialogAddQuantity: false,
  isLoading: false,
  isLoadingModal: false,
  isLoadingData: false,
  isLoadingRemove: false,
  isLoadingUpdate: false,
  isLoadingSearch: false,
  datas: [],
  printDatas: [],
  currentPage: 1,
  totalData: 0,
  search: "",
  pageSize: 10,
  detailData: [],
  thick: "",
  width: "",
  length1: "",
  length2: "",
  date: null
};

export const adjustInvSlice = createSlice({
  name: "adjustInv",
  initialState,
  reducers: {
    toggleActionDialog: (state, action) => {
      state.dialogAction = action.payload;
      state.detailData = [];
    },
    toggleActionDialogApprove: (state, action) => {
      state.dialogActionApprove = action.payload;
    },
    toggleAddDialog: (state, action) => {
      state.dialogAdd = action.payload;
    },
    toggleUpdateDialog: (state, action) => {
      state.dialogUpdate = action.payload;
    },
    togglePrintDialog: (state, action) => {
      state.dialogPrint = action.payload;
    },
    toggleAddQuantityDialog: (state, action) => {
      state.dialogAddQuantity = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setDetailData: (state, action) => {
      state.detailData = action.payload;
    },
    setThick: (state, action) => {
      state.thick = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    setLength1: (state, action) => {
      state.length1 = action.payload;
    },
    setLenght2: (state, action) => {
      state.length2 = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    resetAdjustInv: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(get.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

      builder
      .addCase(getInv.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(getInv.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(getInv.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

      builder
      .addCase(search.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(search.rejected, (state, action: any) => {
        state.isLoadingData = false;
      })
      .addCase(search.fulfilled, (state, action) => {
        state.isLoadingData = false;
        state.datas = action.payload.data;
        state.currentPage = parseInt(action.payload.page);
        state.totalData = action.payload.total;
      });

    builder
      .addCase(getDetail.pending, (state) => {
        state.isLoadingModal = true;
      })
      .addCase(getDetail.rejected, (state, action: any) => {
        state.isLoadingModal = false;
      })
      .addCase(getDetail.fulfilled, (state, action) => {
        state.detailData = action.payload?.data;
        state.isLoadingModal = false;
      });

    builder
      .addCase(getDetailHistory.pending, (state) => {
        state.isLoadingModal = true;
      })
      .addCase(getDetailHistory.rejected, (state, action: any) => {
        state.isLoadingModal = false;
      })
      .addCase(getDetailHistory.fulfilled, (state, action) => {
        state.detailData = action.payload;
        state.isLoadingModal = false;
      });

    // //add remark
    builder
      .addCase(addRemark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRemark.rejected, (state, action: any) => {
        state.isLoading = false;
        message.error("Add Remark Failed");
      })
      .addCase(addRemark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dialogAction = false;
        state.detailData = [];
        message.success("Add Remark Success");
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleActionDialog,
  toggleActionDialogApprove,
  toggleAddDialog,
  setIsLoading,
  toggleUpdateDialog,
  togglePrintDialog,
  toggleAddQuantityDialog,
  setSearch,
  setPageSize,
  setDetailData,
  setThick,
  setWidth,
  setLength1,
  setLenght2,
  setDate,
  resetAdjustInv
} = adjustInvSlice.actions;

export default adjustInvSlice.reducer;
