import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import {
  Card,
  Input,
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  InputNumber,
  Select,
  Button,
} from "antd";
import { getCustomerCode } from "stores/report/summary/actions/summary";

import useAction from "pages/skid/report/summary/sections/action-table/hooks/useAction";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const ActionTable = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const {
    searchPeriode,
    searchSkidName,
    searchCustomerCode,
    searchThick,
    searchWidth,
    searchLength1,
    searchLength2,
    clearSearch,
  } = useAction();
  const { codeCustomer } = useSelector((state: any) => state.reportSummary);

  const handleGetCustomer = async () => {
    const params = { page: 1, per_page: 1000 };
    await dispatch(getCustomerCode(params)).unwrap();
  };

  useEffect(() => {
    handleGetCustomer();
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Title level={5}>Search By</Title>
        <Form
          name="basic"
          form={form}
          // labelCol={{ span: 5 }}
          size="middle"
          // style={{ maxWidth: 400 }}
          // initialValues={{ thick: 0 }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          // autoComplete="off"
          layout="vertical"
        >
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item label="Periode" name="period">
                <RangePicker onChange={searchPeriode} format={"DD-MM-YYYY"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Code SKID" name="skidName">
                <Input onChange={searchSkidName} placeholder="Code SKID" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Customer Code" name="codeCus">
                {/* <Input placeholder="" /> */}
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  onChange={searchCustomerCode}
                  options={codeCustomer}
                  filterOption={(input, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row justify={"space-between"}> */}
          {/* <Col span={20}> */}
          <Form.Item label="Skid Size" style={{ marginBottom: 0 }}>
            <Row gutter={15}>
              <Col span={3}>
                <Form.Item name="thick">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Thick"
                    min={0}
                    onChange={searchThick}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="Width">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Width"
                    min={0}
                    onChange={searchWidth}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length1">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 1"
                    min={0}
                    onChange={searchLength1}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="length2">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Length 2"
                    min={0}
                    onChange={searchLength2}
                    step="0.01"
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col span={3} offset={9}>
                <Button onClick={() => clearSearch(form)} block type="primary">
                  Clear
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ActionTable;
