import SkidList from "pages/skid/skid/list";
import SkidIn from "pages/skid/skid/in";
import SkidOUt from "pages/skid/skid/out";

const Skid = [
  {
    path: "/skid/list",
    element: <SkidList />,
  },
  {
    path: "/skid/in",
    element: <SkidIn />,
  },
  {
    path: "/skid/out",
    element: <SkidOUt />,
  },
];

export default Skid;
