import Adjust from "pages/skid/inventory/adjustment";
import AdjustHistory from "pages/skid/inventory/list-inventory";

const inventory = [
  {
    path: "/inventory/adjust",
    element: <Adjust />,
  },
  {
    path: "/inventory/adjust-history",
    element: <AdjustHistory />,
  },
];

export default inventory;
