import Breadcrumb from "components/Breadcrumb";

const TitleSkidList = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "SKID", to: "/skid/list" },
    { name: "IN", to: "/skid/in" },
  ];

  return <Breadcrumb title="SKID IN" items={bItem} />;
};

export default TitleSkidList;
