import { useEffect, useState } from "react";
import { Typography } from "antd";
import { Col, Row } from "antd";
import SummaryService from "services/skids/SummaryService";

const { Title } = Typography;

const style: React.CSSProperties = {
  background: "rgb(247, 180, 121)",
  padding: "8px 0",
};

const Sumarry = () => {
  const [dataSummaries, setDataSummaries]: any = useState({});

  const getSummaries = async () => {
    const res = await SummaryService.getSummary();
    setDataSummaries(res?.data?.data);
  };

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        paddingTop: "20px",
        paddingBottom: "30px",
      }}
    >
      <div style={{ paddingBottom: "50px" }}>
        <Title style={{ textAlign: "center" }} level={2}>
          Summary SKID
        </Title>
      </div>
      <div style={{ marginLeft: "250px", marginRight: "250px" }}>
        <Row gutter={[24, 24]}>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <Title style={{ textAlign: "center" }} level={4}>
                Jumlah SKID
              </Title>
              <Title style={{ textAlign: "center" }} level={4}>
                {dataSummaries?.jumlahSkid ?? 0}
              </Title>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <Title style={{ textAlign: "center" }} level={4}>
                Total Semua Customer
              </Title>
              <Title style={{ textAlign: "center" }} level={4}>
                {dataSummaries?.jumlahcustomer ?? 0}
              </Title>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <Title style={{ textAlign: "center" }} level={4}>
                Jumlah SKID IN
              </Title>
              <Title style={{ textAlign: "center" }} level={4}>
                {dataSummaries?.jumlahskidIn ?? 0}
              </Title>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <Title style={{ textAlign: "center" }} level={4}>
                Jumlah SKID OUT
              </Title>
              <Title style={{ textAlign: "center" }} level={4}>
                {dataSummaries?.jumlahskidOut ?? 0}
              </Title>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Sumarry;
