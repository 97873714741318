import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoginAction } from "stores/auth/actions";
import { message } from "antd";
import history from "utils/history";

export interface AuthState {
  isLoading: boolean;
  lastLogin: any;
  menuMode: string;
}

const initialState: AuthState = {
  isLoading: false,
  lastLogin: {},
  menuMode: "skid",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.lastLogin = {};
    },
    setMenuMode: (state, action) => {
      state.menuMode = action.payload;
    },
    setLastLogin: (state, action) => {
      state.lastLogin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LoginAction.rejected, (state, action) => {
        state.isLoading = false;
        message.error("Login Gagal! "  + action?.error?.message);
      })
      .addCase(LoginAction.fulfilled, (state, action) => {
        if (action.payload?.role?.id === 2) {
          state.isLoading = false;
          message.error("Not Allowed!, Please Contact Admin");
          return;
        }
        if (action.payload?.role?.id === 8) {
          state.isLoading = false;
          message.error(
            "Not Allowed!, Your Account Only Access Via Android App"
          );
          return;
        }
        state.lastLogin = action.payload;
        state.isLoading = false;
        message.success("Login Success!");
        state.menuMode = "skid";

        if (action.payload?.role?.id === 1) {
          history.replace("/");
        } else if (action.payload?.role?.id === 3) {
          history.replace("/");
        } else if (action.payload?.role?.id === 4) {
          history.replace("/mtn");
          state.menuMode = "mtn";
        } else if (action.payload?.role?.id === 11) {
          history.replace("/mtn/part");
          state.menuMode = "mtn";
        } else {
          history.replace("/");
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { resetLogin, setMenuMode, setLastLogin } = authSlice.actions;

export default authSlice.reducer;
