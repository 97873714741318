import Breadcrumb from "components/Breadcrumb";

const TitleAdustInventory = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Inventory", to: "/inventory/adjust" },
    { name: "Adjustment", to: "/inventory/adjust" },
  ];

  return <Breadcrumb title="Adjustment" items={bItem} />;
};

export default TitleAdustInventory;
