import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createLine = (params: any) => {
  return xhrInstance.post("/mtn/line", params);
};

const getLine = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/line", params));
};

const removeLine = (id: string | number) => {
  return xhrInstance.delete(`/mtn/line/${id}`);
};

const updateLine = (params: any) => {
  return xhrInstance.put(`/mtn/line/${params.id}`, params.body);
};

const LineService = {
  createLine,
  getLine,
  removeLine,
  updateLine,
};

export default LineService;
