import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "services/skids/UserService";

export const create = createAsyncThunk("users/create", async (params: any) => {
  const { data } = await UserService.createUser(params);
  return data;
});

export const get = createAsyncThunk("users/get", async (params: any) => {
  const { data } = await UserService.getUser(params);
  return data;
});

export const search = createAsyncThunk("users/search", async (params: any) => {
  const { data } = await UserService.getUser(params);
  return data;
});

export const remove = createAsyncThunk(
  "users/remove",
  async (id: string | number) => {
    const { data } = await UserService.removeUser(id);
    return data;
  }
);

export const update = createAsyncThunk("users/update", async (params: any) => {
  const { data } = await UserService.updateUser(params);
  return data;
});

export const changePassword = createAsyncThunk("users/changePassword", async (params: any) => {
  const { data } = await UserService.updateUser(params);
  return data;
});

export const getRole = createAsyncThunk("users/role", async () => {
  const { data } = await UserService.getRole();
  return data;
});
