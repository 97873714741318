import { createAsyncThunk } from "@reduxjs/toolkit";
import RepairService from "services/mtns/order-history/PurchaseService";

export const create = createAsyncThunk(
  "purchase/create",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await RepairService.createPurchase(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const partOut = createAsyncThunk(
  "purchase/out",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await RepairService.outPart(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get = createAsyncThunk("purchase/get", async (params: any) => {
  const { data } = await RepairService.getPurchase(params);
  return data;
});

export const search = createAsyncThunk("purchase/search", async (params: any) => {
  const { data } = await RepairService.getPurchase(params);
  return data;
});

export const remove = createAsyncThunk(
  "purchase/remove",
  async (id: string | number) => {
    const { data } = await RepairService.removePart(id);
    return data;
  }
);

export const update = createAsyncThunk("purchase/update", async (params: any) => {
  const { data } = await RepairService.updatePurchase(params);
  return data;
});

export const updateStatus = createAsyncThunk("purchase/updateStatus", async (params: any) => {
  const { data } = await RepairService.updatePurchaseStatus(params);
  return data;
});


export const getLimitPart = createAsyncThunk("purchase/getLimitPart", async (params: any) => {
  const { data } = await RepairService.getLimitPart(params);
  return data;
});