import { Card, Input, Row, Col, Button, Space, DatePicker, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DialogCreateDelivery from "pages/skid/print/delivery_order/sections/action-table/dialog/create";
import useAction from "pages/skid/print/delivery_order/sections/action-table/hooks/useAction";

const { RangePicker } = DatePicker;

const ActionTable = () => {
  const {
    handleChange,
    handleSearch,
    isLoadingSearch,
    search,
    dialogCreate,
    handleChangeDate,
    handleChangeOrder,
    handleChangeRecieved,
    orders,
  } = useAction();

  return (
    <>
      <Card className="mb-3">
        <Row justify="space-between" className="mb-3">
          <Col span={6}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Delivery Noted Number</div>
              <Input.Search
                loading={isLoadingSearch}
                value={search}
                onChange={handleChange}
                placeholder="Search"
                onSearch={handleSearch}
                enterButton
              />
            </Space>
          </Col>

          <Col span={18}>
            <Row justify="end" align="bottom" style={{ height: "100%" }}>
              <Button
                onClick={dialogCreate}
                type="primary"
                icon={<PlusOutlined />}
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={6}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Date</div>
              <RangePicker
                onChange={handleChangeDate}
                style={{ width: "100%" }}
              />
            </Space>
          </Col>
          <Col span={9}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Order By</div>
              <Select
                onChange={handleChangeOrder}
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={orders}
              />
            </Space>
          </Col>
          <Col span={9}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div>Recieved By</div>
              <Select
                onChange={handleChangeRecieved}
                allowClear
                style={{ width: "100%" }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={orders}
              />
            </Space>
          </Col>
        </Row>
      </Card>
      <DialogCreateDelivery />
    </>
  );
};

export default ActionTable;
