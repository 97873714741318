import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createSupplier = (params: any) => {
  return xhrInstance.post("/mtn/supplier", params);
};

const getSupplier = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/supplier", params));
};

const removeSupplier = (id: string | number) => {
  return xhrInstance.delete(`/mtn/supplier/${id}`);
};

const updateSupplier = (params: any) => {
  return xhrInstance.put(`/mtn/supplier/${params.id}`, params.body);
};

const SupplierService = {
  createSupplier,
  getSupplier,
  removeSupplier,
  updateSupplier,
};

export default SupplierService;
