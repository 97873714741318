import xhrInstance from "services/config";

const login = (params:any)=> {
  return xhrInstance.post("/auth/login", params);
}

const changeProfile = (params:any, id:number|string) => {
  return xhrInstance.put(`/users/update/${id}`, params)
} 

const AuthService = {login, changeProfile};

export default AuthService;