import Process from "pages/mtn/inventory/process";
import Compare from "pages/mtn/inventory/process/sections/compare";
import Recap from "pages/mtn/inventory/recap";

const inventoryMtn = [
  {
    path: "/mtn/inventory/process",
    element: <Process />,
  },
  {
    path: "/mtn/inventory/compare",
    element: <Compare />,
  },
  {
    path: "/mtn/inventory/recap",
    element: <Recap />,
  },
];

export default inventoryMtn;
