import { createAsyncThunk } from "@reduxjs/toolkit";
import PrintService from "services/skids/PrintServices";

// export const create = createAsyncThunk(
//   "print/createPickupOrder",
//   async (params: any) => {
//     const { data } = await PrintService.createPickupOrder(params);
//     return data;
//   }
// );

export const get = createAsyncThunk(
  "print/getPickupOrder",
  async (params: any) => {
    const { data } = await PrintService.getPickupOrder(params);
    return data;
  }
);

export const search = createAsyncThunk(
  "print/searchPickupOrder",
  async (params: any) => {
    const { data } = await PrintService.getPickupOrder(params);
    return data;
  }
);

export const remove = createAsyncThunk(
  "print/removePickupOrder",
  async (id: string | number) => {
    const { data } = await PrintService.removePickupOrder(id);
    return data;
  }
);

export const detail = createAsyncThunk(
  "print/detailPickupOrder",
  async (id: string | number) => {
    const { data } = await PrintService.detailPickupOrder(id);
    return data;
  }
);

export const create = createAsyncThunk(
  "print/createPickupOrder",
  async (params: any) => {
    const { data } = await PrintService.createPickupOrder(params);
    return data;
  }
);
