import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Input, Col, Row, Select } from "antd";
import { toggleAddDialog } from "stores/user";
import { AppDispatch } from "stores";
import { get, create, getRole } from "stores/user/actions/user";
import MasterDataService from "services/skids/MasterDataService";

const CreateUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { dialogAdd, isLoading, pageSize, roles } = useSelector(
    (state: any) => state.users
  );
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState<number>(0);

  const getRoles = () => {
    dispatch(getRole()).unwrap();
  };

  const getCustomers = async () => {
    const { data } = await MasterDataService.getCustomer({
      page: 1,
      per_page: 1000000,
    });
    setCustomers(
      data.data?.map((item: any) => ({ value: item.id, label: item.name }))
    );
  };

  useEffect(() => {
    if (dialogAdd) {
      getRoles();
      getCustomers();
    }
    setCustomer(0);
  }, [dialogAdd]);

  const onCancel = () => {
    form.resetFields();
    dispatch(toggleAddDialog(false));
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      delete values.confirmPassword;
      await dispatch(create(values)).unwrap();
      const params = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(params)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        open={dialogAdd}
        title="Create User"
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            onClick={onSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="nik"
                label="NIK"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("NIK is required");
                      }
                      if (/\b\d{5}\b/g.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Only number & must be 5 characters"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="department"
                label="Department"
                rules={[{ required: true }]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                <Select
                  onChange={(val) => setCustomer(val)}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={roles}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Confirm password is required" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The confirm password isn't match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Type here..." />
              </Form.Item>
            </Col>
            {customer === 8 && (
              <Col span={24}>
                <Form.Item
                  name="customer"
                  label="Customer"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      (option?.label.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={customers}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateUser;
