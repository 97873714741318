import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Part", to: "/mtn/part" },
    
  ];

  return <Breadcrumb title="Part" items={bItem} />;
};

export default TitleStore;
