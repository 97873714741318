import xhrInstance from "services/config";
import objToParams from "utils/objToParams";


const getPO = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/part/po-list", params));
};

const detailPO = (id: string | number) => xhrInstance.get(`/mtn/part/po-detail/${id}`); 

const updatePartPO = (params: any) => xhrInstance.put(`/mtn/part/po-in/${params.id}`, params.body);

const PartInService = {
  getPO,
  detailPO,
  updatePartPO
};

export default PartInService;
