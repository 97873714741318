import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { get, remove } from "stores/master-data/customer/actions/customer";
import UpdateStoreDialog from "pages/skid/master_data/customer/sections/table-pagination/dialog/update";
import { toggleUpdateDialog, setPageSize } from "stores/master-data/customer";

const TablePagination = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const { isLoadingData, currentPage, totalData, search, pageSize, datas } =
    useSelector((state: any) => state.customer);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
      email: item?.email ? item?.email : "-",
      phone: item?.phone ? item?.phone : "-",
    };
  });

  useEffect(() => {
    const params: any = {
      page: 1,
      per_page: 10,
    };

    dispatch(get(params)).unwrap();
  }, []);

  const handleDialogUpdate = (record: any) => {
    setCurrentRecord(record);
    dispatch(toggleUpdateDialog(true));
  };

  const handleDelete = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await dispatch(remove(dataRecord.id)).unwrap();
        const params = {
          page: 1,
          per_page: pageSize,
        };
        await dispatch(get(params)).unwrap();
      },
    });
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 70,
      fixed: "left",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      width: 50,
      fixed: "left",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 250,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
      fixed: "left",
    },
    {
      title: "Telp",
      dataIndex: "phone",
      key: "phone",
      width: 100,
      fixed: "left",
    },
    {
      title: "Options",
      key: "options",
      width: 90,
      fixed: "left",
      render: (dataRecord: any) => {
        return (
          <Row justify="space-between">
            <Tooltip title="Edit">
              <Button
                onClick={() => handleDialogUpdate(dataRecord)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>

            <Tooltip title="Delete">
              <Button
                onClick={() => handleDelete(dataRecord)}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Card>
      {contextHolder}
      <UpdateStoreDialog dataRecord={currentRecord} />

      <Table
        rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
