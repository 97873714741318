import { createAsyncThunk } from "@reduxjs/toolkit";
import PartService from "services/mtns/part/PartService";

export const create = createAsyncThunk(
  "part/create",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await PartService.createPart(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const partOut = createAsyncThunk(
  "part/out",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await PartService.outPart(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get = createAsyncThunk("part/get", async (params: any) => {
  const { data } = await PartService.getPart(params);
  return data;
});

export const search = createAsyncThunk("part/search", async (params: any) => {
  const { data } = await PartService.getPart(params);
  return data;
});

export const remove = createAsyncThunk(
  "part/remove",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await PartService.purgePart(id);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const update = createAsyncThunk("part/update", async (params: any) => {
  const { data } = await PartService.updatePart(params);
  return data;
});
