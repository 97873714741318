import axios from "axios";

//to inject store redux
let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const { auth } = store.getState();

    if (auth ? Object.keys(auth?.lastLogin).length > 0 : false) {
      // const lastLoginParse = JSON.parse(lastLogin);
      config.headers.Authorization = "Bearer " + auth.lastLogin?.token;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
