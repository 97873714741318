import { createAsyncThunk } from "@reduxjs/toolkit";
import MasterDataService from "services/mtns/master-data/SupplierService";

export const create = createAsyncThunk(
  "supplier/create",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await MasterDataService.createSupplier(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get = createAsyncThunk("supplier/get", async (params: any) => {
  const { data } = await MasterDataService.getSupplier(params);
  return data;
});

export const search = createAsyncThunk(
  "supplier/search",
  async (params: any) => {
    const { data } = await MasterDataService.getSupplier(params);
    return data;
  }
);

export const remove = createAsyncThunk(
  "supplier/remove",
  async (id: string | number) => {
    const { data } = await MasterDataService.removeSupplier(id);
    return data;
  }
);

export const update = createAsyncThunk(
  "supplier/update",
  async (params: any) => {
    const { data } = await MasterDataService.updateSupplier(params);
    return data;
  }
);
