import { useCallback, useMemo } from "react";
import { Card, Input, Row, Col } from "antd";
import { setSearch } from "stores/approval";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as storeAction from "stores/approval/actions/approval";
import debounce from "lodash/debounce";

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch } = useSelector(
    (state: any) => state.approval
  );

  const sendBackendRequest = useCallback((value: string) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: value,
    };
    dispatch(storeAction.search(params)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    dispatch(setSearch(value));
    debouncedSendRequest(value);
  };

  return (
    <>
      <Card className="mb-3">
        <Row justify="space-between">
          <Col span={6}>
            <Input.Search
              loading={isLoadingSearch}
              value={search}
              placeholder="Search"
              onChange={handleChange}
              onSearch={() => sendBackendRequest(search)}
              enterButton
            />
          </Col>
          {/* <Col span={18}>
            <Row justify="end">
              <Button
                onClick={() => dispatch(toggleAddDialog(true))}
                type="primary"
                icon={<PlusOutlined />}
              >
                Create
              </Button>
            </Row>
          </Col> */}
        </Row>
      </Card>
    </>
  );
};

export default ActionTable;
