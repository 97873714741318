import { createAsyncThunk } from "@reduxjs/toolkit";
// import SkidListService from "services/skids/SkidListService";
import InventoryService from "services/skids/InventoryService";

export const get = createAsyncThunk("adjustInv/getAdj", async (params: any) => {
  const { data } = await InventoryService.getAdjustment(params);
  return data;
});

export const getInv = createAsyncThunk("adjustInv/getInv", async (params: any) => {
  const { data } = await InventoryService.getInventory(params);
  return data;
});

export const addRemark = createAsyncThunk(
  "adjustInv/post",
  async (params: any) => {
    const { data } = await InventoryService.addRemarkAdjustment(
      params.body,
      params.id
    );
    return data;
  }
);

export const getDetail = createAsyncThunk(
  "adjustInv/getDetail",
  async (params: any) => {
    const { data } = await InventoryService.getAdjustmentDetail(params);
    return data;
  }
);

export const getDetailHistory = createAsyncThunk(
  "adjustInv/getDetailHistory",
  async (id: number | string) => {
    const { data } = await InventoryService.getAdjustmentHistory(id);
    return data;
  }
);

export const search = createAsyncThunk(
  "adjustInv/search",
  async (params: any) => {
    const { data } = await InventoryService.getAdjustment(params);
    return data;
  }
);

// export const remove = createAsyncThunk(
//   "skidList/remove",
//   async (id: string | number) => {
//     const { data } = await SkidListService.removeSkid(id);
//     return data;
//   }
// );

// export const update = createAsyncThunk(
//   "skidList/update",
//   async (params: any) => {
//     const { data } = await SkidListService.updateSkid(params);
//     return data;
//   }
// );
