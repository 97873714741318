import { Card } from "antd";
import TablePaginationAdjust from "pages/skid/inventory/list-inventory/sections/table-pagination-adjust";

const TabAdjust = () => {
  return (
    <Card>
      <TablePaginationAdjust />
    </Card>
  );
};

export default TabAdjust;
