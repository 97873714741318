import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createSkid = (params: any) => {
  return xhrInstance.post("/skid", params);
};

const getSkid = (params: any) => {
  return xhrInstance.get(objToParams("/skid", params));
};

const removeSkid = (id: string | number) => {
  return xhrInstance.delete(`/skid/asset/${id}`);
};

const updateSkid = (params: any) => {
  return xhrInstance.put(`/skid/asset/${params.id}`, params.body);
};

const getLastSkid = (id: string | number) => {
  return xhrInstance.get(`/skid/last/${id}`);
};

const addQtySkid = (params: any) => {
  return xhrInstance.post(`/skid/${params.id}`, params.body);
};

const SkidListService = {
  createSkid,
  getSkid,
  removeSkid,
  updateSkid,
  getLastSkid,
  addQtySkid,
};

export default SkidListService;
