import Title from "pages/skid/report/skid_history/sections/title";
import ActionTable from "pages/skid/report/skid_history/sections/action-table";
import TablePagination from "pages/skid/report/skid_history/sections/table-pagination";

const SkidHistory = () => {
  return (
    <>
      <Title />
      <ActionTable />
      <TablePagination />
    </>
  );
};

export default SkidHistory;
