import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const createPurchase = (params: any) => {
  return xhrInstance.post("/mtn/purchase-order/create", params);
};

const getPurchase = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/purchase-order", params));
};

const getPurchaseById = (id: any) => {
  return xhrInstance.get(`/mtn/part-repair/get/${id}`);
};

const outPart = (params: any) => {
  return xhrInstance.post(`/mtn/part/out/${params.id}`, params.body);
};

const removePart = (id: string | number) => {
  return xhrInstance.delete(`/mtn/part/${id}`);
};

const updatePurchase = (params: any) => {
  return xhrInstance.put(`/mtn/part-repair/update/${params.id}`, params.body);
};

const updatePurchaseStatus = (params: any) => {
  return xhrInstance.put(`/mtn/purchase-order/update-status/${params.id}`, params.body);
};

const updateToProcessStatus = (id: number | string) => {
  return xhrInstance.put(`/mtn/purchase-order/update-process/${id}`, {})
}

const getLimitPart = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/purchase-order/list-part-limit", params));
};

const getPurchaseList = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/purchase-order/list-part-limit", params));
};

const getPurchaseStatus = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/purchase-order/list-status", params));

};

const getFilterPurchaseStatus = () => {
  return xhrInstance.get("/mtn/purchase-order/list-filter-status");
};

const printPurchase = (params: any) => {
  return xhrInstance.post("/mtn/purchase-order/print-po", params)
}


const PurchaseService = {
  createPurchase,
  getPurchase,
  outPart,
  removePart,
  updatePurchase,
  getPurchaseList,
  getPurchaseStatus,
  updatePurchaseStatus,
  updateToProcessStatus,
  getPurchaseById,
  getFilterPurchaseStatus,
  getLimitPart,
  printPurchase
};

export default PurchaseService;
