import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { AppDispatch } from "stores";
import { toggleUpdateDialog } from "stores/part";
import { get, update } from "stores/part/actions/part";
import useDialogPart from "pages/mtn/part/sections/hooks/useDialogPart";
import PartService from "services/mtns/part/PartService";

const useDialogUpdate = (dataRecord: any) => {
  const {
    partOption,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleRefImg,
    handleUpload,
    openModal,
    setFileImg,
    setImgBase64,
  } = useDialogPart();

  const { dialogUpdate, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.part
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleCancel = () => {
    form.resetFields();
    dispatch(toggleUpdateDialog(false));
    setFileImg(null);
    setImgBase64("");
  };

  const handleSubmit = async () => {
    try {
      console.log(dataRecord);
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };

      await dispatch(update(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  const setForm = async () => {
    form.setFieldsValue({
      part_name: dataRecord?.part_name,
      store_id: dataRecord?.store_id,
    });
  };

  useEffect(() => {
    if (dialogUpdate) {
      openModal({ whitelist: ["getStore"] });
      setForm();
    }
  }, [dialogUpdate]);

  return {
    dialogUpdate,
    partOption,
    isLoadingUpdate,
    form,
    fileImg,
    imgBase64,
    imgRef,
    handleSubmit,
    handleCancel,
    handleRefImg,
    handleUpload,
  };
};

export default useDialogUpdate;
