import { createAsyncThunk } from "@reduxjs/toolkit";
import PrintService from "services/skids/PrintServices";

export const create = createAsyncThunk(
  "print/createDeliveryOrder",
  async (params: any) => {
    const { data } = await PrintService.createDeliveryOrder(params);
    return data;
  }
);

export const get = createAsyncThunk(
  "print/getDeliveryOrder",
  async (params: any) => {
    const { data } = await PrintService.getDeliveryOrder(params);
    return data;
  }
);

export const search = createAsyncThunk(
  "print/searchDeliveryOrder",
  async (params: any) => {
    const { data } = await PrintService.getDeliveryOrder(params);
    return data;
  }
);

export const remove = createAsyncThunk(
  "print/removeDeliveryOrder",
  async (id: string | number) => {
    const { data } = await PrintService.removeDeliveryOrder(id);
    return data;
  }
);

export const detail = createAsyncThunk(
  "print/detailDeliveryOrder",
  async (letterId: string | number) => {
    const { data } = await PrintService.detailDeliveryOrder(letterId);
    return data;
  }
);
