import Breadcrumb from "components/Breadcrumb";

const TitleSkidHistory = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/" },
    { name: "Report", to: "/report/summary" },
    { name: "Skid History", to: "/report/skid-history" },
  ];

  return <Breadcrumb title="Skid History" items={bItem} />;
};

export default TitleSkidHistory;
