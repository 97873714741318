import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Master Data", to: "/mtn/master-data/rank" },
    { name: "Class", to: "/mtn/master-data/class" },
  ];

  return <Breadcrumb title="Class" items={bItem} />;
};

export default TitleStore;
