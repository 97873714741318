import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getProcess = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/inventory/process/list", params));
};

const getCompareProcesss = () => {
  return xhrInstance.get("/mtn/inventory/process/compare");
}

const uploadProcess = (params: any) => {
  return xhrInstance.post("/mtn/inventory/process/upload", params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const updateProcess = (params: any) => {
  return xhrInstance.put(`/mtn/inventory/process/sumbit`, params);
};

const ProcessService = {
  getProcess,
  updateProcess,
  getCompareProcesss,
  uploadProcess
};

export default ProcessService;
