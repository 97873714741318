import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Button, Pagination, Table, Tooltip, Modal } from "antd";
import { AppDispatch } from "stores";
import { useSelector, useDispatch } from "react-redux";
import { get } from "stores/partIn/actions/partIn";
import { resetPartIn } from "stores/partIn";
import PurchaseService from "services/mtns/order-history/PurchaseService";

import {
  EditOutlined,
  UnorderedListOutlined,
  InteractionOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import { setPageSize } from "stores/partIn";
import DetailRepairPartDialog from "pages/mtn/order-history/purchase/sections/table-pagination/dialog/detail";
import dayjs from "dayjs";

const TablePagination = () => {
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [modal, contextHolder] = Modal.useModal();
  const {
    isLoadingData,
    currentPage,
    totalData,
    search,
    pageSize,
    datas,
    activeTab,
  } = useSelector((state: any) => state.partIn);
  const dispatch = useDispatch<AppDispatch>();

  const getInit = () => {
    const params = {
      page: 1,
      per_page: 10,
    };
    dispatch(get(params)).unwrap();
  };
  useEffect(() => {
    getInit();
    return () => {
      dispatch(resetPartIn());
    };
  }, []);

  const newDatas = datas?.map((item: any, index: number) => {
    return {
      ...item,
      no: pageSize * (currentPage - 1) + index + 1,
    };
  });

  const handleChangeToProcess = (dataRecord: any) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: (() => {
        return (
          <>
            <div>
              PO Number{" "}
              <span style={{ fontWeight: 600 }}>{dataRecord?.po_number}</span>
            </div>
            <div>Are you sure to change status?</div>
          </>
        );
      })(),
      okText: "Ok",
      cancelText: "Cancel",
      onOk: async () => {
        await PurchaseService.updateToProcessStatus(dataRecord?.id);
        const params = {
          page: 1,
          per_page: pageSize,
          status_id: activeTab,
        };
        await dispatch(get(params)).unwrap();
      },
    });
  };

  const handlePaginate = async (page: number, pageSize: number) => {
    dispatch(setPageSize(pageSize));
    const params: any = {
      page: page,
      per_page: pageSize,
      keyword: search,
      status_id: activeTab,
    };

    if (search) {
      params.keyword = search;
    }

    await dispatch(get(params)).unwrap();
  };

  const navigate = useNavigate();
  const toDetailPO = (record: any) => {
    navigate(`/mtn/part/in/po/${record?.id}/detail`, { state: record });
  };

  const columns: any = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 20,
      align: "center",
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
      key: "po_number",
      width: 400,
    },
    {
      title: "Create Date",
      dataIndex: "create_time",
      key: "create_time",
      width: 400,
      render: (record: string) =>
        record ? dayjs.utc(record).format("DD/MM/YYYY HH:mm") : "-",
    },
    {
      title: "Options",
      key: "options",
      width: 10,
      fixed: "right",
      render: (dataRecord: any) => {
        return (
          <Row justify="center">
            <Tooltip title="Detail">
              <Button
                onClick={() => toDetailPO(dataRecord)}
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const [toggleDetail, setToggleDetail] = useState(false);

  return (
    <Card>
      {contextHolder}

      <DetailRepairPartDialog
        toggleDetail={toggleDetail}
        setToggleDetail={setToggleDetail}
        dataRecord={currentRecord}
      />

      <Table
        // rowKey={(record: any) => record.id}
        loading={isLoadingData}
        columns={columns}
        dataSource={newDatas}
        pagination={false}
        // scroll={{ x: "calc(700px + 50%)" }}
      />
      <Row className="mt-5" justify="end">
        <Pagination
          pageSize={pageSize}
          current={currentPage}
          total={totalData}
          showSizeChanger
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page, pageSize) => handlePaginate(page, pageSize)}
        />
      </Row>
    </Card>
  );
};

export default TablePagination;
