import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getSkidIn = (params: any) => {
  return xhrInstance.get(objToParams("/product/in", params));
};

const SkidListService = {
  getSkidIn,
};

export default SkidListService;
