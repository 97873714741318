import SummaryReport from "pages/skid/report/summary";
import PickupOrder from "pages/skid/report/pickup";
import SkidHistoryReport from "pages/skid/report/skid_history";

const report = [
  {
    path: "/report/summary",
    element: <SummaryReport />,
  },
  {
    path: "/report/pickup-order",
    element: <PickupOrder />,
  },
  {
    path: "/report/skid-history",
    element: <SkidHistoryReport />,
  },
];

export default report;
