import Part from "pages/mtn/part";
import PartIn from "pages/mtn/part/in";
import DetailPO from "pages/mtn/part/in/section/detail-po";

const part = [
  {
    path: "/mtn/part",
    element: <Part />,
  },
  {
    path: "/mtn/part/in",
    element: <PartIn />,
  },
  {
    path: "/mtn/part/in/po/:poId/detail",
    element: <DetailPO />,
  },
];

export default part;
