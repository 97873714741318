import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Table, Modal, message } from "antd";
import ProcessService from "services/mtns/inventory/ProcessServices";
import Breadcrumb from "components/Breadcrumb";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const Compare = () => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState({
    parts: [],
    inventories: [],
    isLoading: false,
  });
  const getComparePart = async () => {
    setDatas((val) => ({ ...val, isLoading: true }));
    const { data } = await ProcessService.getCompareProcesss();
    setDatas((val) => ({
      ...val,
      parts: data?.data?.part,
      inventories: data?.data?.inventory,
      isLoading: false,
    }));
  };
  useEffect(() => {
    getComparePart();
  }, []);
  const columns: any = [
    {
      title: "ID",
      dataIndex: "part_number",
      key: "part_number",
      width: 10,
      fixed: "left",
      align: "center",
    },
    {
      title: "Part Line",
      dataIndex: "line_code",
      key: "line_code",
      width: 100,
      fixed: "left",
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "part_name",
      width: 100,
      fixed: "left",
    },
    // {
    //   title: "Size",
    //   dataIndex: "size",
    //   key: "size",
    //   width: 100,
    //   fixed: "left",
    // },
    {
      title: "Quantity",
      // dataIndex: "qty",
      key: "qty",
      width: 40,
      fixed: "left",
      render(record: any) {
        const unMatch = record?.is_match === "0";
        return {
          props: {
            style: {
              background: unMatch && "#ffa245",
              color: unMatch && "#fff",
            },
          },
          children: <div>{record?.qty}</div>,
        };
      },
    },
    {
      title: "Store",
      key: "store_code",
      width: 100,
      fixed: "left",
      render(record: any) {
        const unMatch = record?.is_store_match === "0";
        return {
          props: {
            style: {
              background: unMatch && "rgb(35, 160, 251)",
              color: unMatch && "#fff",
            },
          },
          children: <div>{record?.store_code}</div>,
        };
      },
    },
    {
      title: "Unit",
      dataIndex: "unit_code",
      key: "unit_code",
      width: 100,
      fixed: "left",
    },
  ];

  const bItem = [
    { name: "Dashboard", to: "/" },
    { name: "Inventory", to: "/mtn/inventory/process" },
    { name: "Process", to: "/mtn/inventory/process" },
  ];

  const updateStock = async () => {
    try {
      const req = {
        data_inventory: datas.inventories?.map((item: any) => ({
          id: parseInt(item.id),
          qty: item.qty,
          store_id: item.store_id,
        })),
      };
      await ProcessService.updateProcess(req);
      message.success("Update Inventory Success");
      navigate(-1);
    } catch (err: any) {
      if (err?.response?.status === 500) {
        message.error(err?.response?.data?.message);
      } else {
        message.error("Update Inventory Failed");
      }
    }
  };

  const [modal, contextHolder] = Modal.useModal();

  const confirm = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to update?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => {
        updateStock();
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Breadcrumb title="Compare" items={bItem} back={true} />
      <Card>
        <div
          style={{ display: "flex", overflowX: "auto", marginBottom: "20px" }}
        >
          <div>
            <h2>From File and Mobile</h2>
            <Table
              className="mr-4"
              bordered
              loading={datas?.isLoading}
              columns={columns}
              dataSource={datas.inventories}
              pagination={false}
            />
          </div>
          <div>
            <h2>Actual System</h2>
            <Table
              bordered
              loading={datas?.isLoading}
              columns={columns}
              dataSource={datas?.parts}
              pagination={false}
            />
          </div>
        </div>
        <Button onClick={confirm} type="primary">
          Update
        </Button>
      </Card>
    </>
  );
};

export default Compare;
