import { createAsyncThunk } from "@reduxjs/toolkit";
import RecapServices from "services/mtns/inventory/RecapServices";


export const get = createAsyncThunk("recapInv/get", async (params: any) => {
  const { data } = await RecapServices.getRecap(params);
  return data;
});

export const search = createAsyncThunk("recapInv/search", async (params: any) => {
  const { data } = await RecapServices.getRecap(params);
  return data;
});
