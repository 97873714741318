import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Input, Col, Row, message } from "antd";
import { togglePasswordDialog } from "stores/user";
import { get, changePassword } from "stores/user/actions/user";

const ChangePassword = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { dialogPassword, isLoadingUpdate, pageSize } = useSelector(
    (state: any) => state.users
  );

  useEffect(() => {}, [dialogPassword]);

  const handleCancel = () => {
    form.resetFields();
    dispatch(togglePasswordDialog(false));
  };

  const generatePassword = (length: number) => {
    let charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      result = "";
    for (let i = 0; i < length; ++i) {
      result += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return result;
  };

  const handleGeneratePass = () => {
    const newPassword = generatePassword(8);
    form.setFieldsValue({
      password: newPassword,
      confirm_password: newPassword,
    });
  };

  const handleCopy = async () => {
    try {
      const values = await form.validateFields();
      navigator.clipboard.writeText(values.password);
      messageApi.info("Password success copied");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        id: dataRecord?.id,
        body: values,
      };
      await dispatch(changePassword(params)).unwrap();
      const paramsGet = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(paramsGet)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, [isLoadingUpdate]);

  return (
    <>
      {contextHolder}
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogPassword}
        title="Change Password"
        onCancel={() => handleCancel()}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoadingUpdate}
            onClick={handleSubmit}
            key="submit"
            type="primary"
          >
            Change Password
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10} className="mb-2">
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Confirm password is required" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The confirm password isn't match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" className="mb-2">
            <Button onClick={handleGeneratePass} type="primary">
              Generate Password
            </Button>
          </Row>
          <Row justify="center">
            <Button onClick={handleCopy} type="primary">
              Copy Password
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePassword;
