import { useCallback, useMemo, useEffect } from "react";
import { Card, Input, Row, Col } from "antd";
import DialogCreateSkid from "pages/skid/skid/list/sections/action-table/dialog/create";
import DialogPrint from "pages/skid/skid/list/sections/action-table/dialog/print";
import { setSearch } from "stores/print/label";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "stores";
import * as listAction from "stores/print/label/actions/label";
import debounce from "lodash/debounce";

const ActionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { search, pageSize, isLoadingSearch } = useSelector(
    (state: any) => state.labelPrint
  );

  const sendBackendRequest = useCallback((value: string) => {
    const params = {
      page: 1,
      per_page: pageSize,
      keyword: value,
    };
    dispatch(listAction.get(params)).unwrap();
  }, []);

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendBackendRequest, 500);
  }, [sendBackendRequest]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    dispatch(setSearch(value));
    debouncedSendRequest(value);
  };

  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
    };
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Row justify="space-between">
          <Col span={6}>
            <Input.Search
              loading={isLoadingSearch}
              value={search}
              onChange={handleChange}
              placeholder="Search"
              onSearch={() => sendBackendRequest(search)}
              enterButton
            />
          </Col>
        </Row>
      </Card>
      <DialogCreateSkid />
      <DialogPrint />
    </>
  );
};

export default ActionTable;
