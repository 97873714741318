import React from "react";
import { Layout } from "antd";
import style from "assets/styles/layout.module.css";

const { Footer } = Layout;

const FooterLayout: React.FC = () => {
  return (
    <Footer className={style.footer}>SCI ©2023 Created by Ratu Digital</Footer>
  );
};

export default FooterLayout;
