import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getReport = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/reporting", params));
};

const getListStatus = () => {
  return xhrInstance.get("/mtn/reporting/list-status");
};

const getListType = () => {
  return xhrInstance.get("/mtn/reporting/list-input-type");
};

const downloadReport = (params: any) => {
  return xhrInstance.get(objToParams("/mtn/reporting/download", params), {
    responseType: "blob",
  });
};

const ReportMtnService = {
  getReport,
  getListStatus,
  getListType,
  downloadReport,
};

export default ReportMtnService;
