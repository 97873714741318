import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Input, Col, Row } from "antd";
import { toggleAddDialog } from "stores/master-data/customer";
import { AppDispatch } from "stores";
import { get, create } from "stores/master-data/customer/actions/customer";

const { TextArea } = Input;

const CreateCustomer = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const [codes, setCodes] = useState([]);
  const [form] = Form.useForm();
  const { dialogAdd, isLoading, pageSize } = useSelector(
    (state: any) => state.customer
  );

  const onCancel = () => {
    form.resetFields();
    dispatch(toggleAddDialog(false));
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(create(values)).unwrap();
      const params = {
        page: 1,
        per_page: pageSize,
      };
      await dispatch(get(params)).unwrap();
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        open={dialogAdd}
        title="Create Customer"
        onCancel={() => onCancel()}
        footer={[
          <Button onClick={() => onCancel()} key="back">
            Cancel
          </Button>,
          <Button
            loading={isLoading}
            onClick={onSubmit}
            key="submit"
            type="primary"
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Row gutter={10}>
            <Col span={16}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="code"
                label="Code"
                rules={[
                  { required: true },
                  // () => ({
                  //   validator(_, value) {
                  //     if (
                  //       !value ||
                  //       !codes?.find((item: any) => item === value)
                  //     ) {
                  //       return Promise.resolve();
                  //     }

                  //     return Promise.reject(
                  //       new Error("Found same code, type other")
                  //     );
                  //   },
                  // }),
                ]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true }]}
          >
            <TextArea rows={3} placeholder="Type here..." />
          </Form.Item>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label="Telp">
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCustomer;
