import { createAsyncThunk } from "@reduxjs/toolkit";
import MasterDataService from "services/mtns/master-data/LineService";

export const create = createAsyncThunk(
  "line/create",
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await MasterDataService.createLine(params);
      return data;
    } catch (err) {
      let error: any = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get = createAsyncThunk("line/get", async (params: any) => {
  const { data } = await MasterDataService.getLine(params);
  return data;
});

export const search = createAsyncThunk("line/search", async (params: any) => {
  const { data } = await MasterDataService.getLine(params);
  return data;
});

export const remove = createAsyncThunk(
  "line/remove",
  async (id: string | number) => {
    const { data } = await MasterDataService.removeLine(id);
    return data;
  }
);

export const update = createAsyncThunk("line/update", async (params: any) => {
  const { data } = await MasterDataService.updateLine(params);
  return data;
});
