import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "services/AuthService";

export const LoginAction = createAsyncThunk("auth/login", async (params:any) => {
  try {
    const {data} = await AuthService.login(params);
    return data;
  } catch(err:any) {
    if (err?.response?.status === 400 || err?.response?.status === 404) {
      throw err.response.data.message;
    } else {
      throw new Error("An error occurred");
    }
  }
});
