import Breadcrumb from "components/Breadcrumb";

const TitleStore = () => {
  const bItem: any = [
    { name: "Dashboard", to: "/mtn" },
    { name: "Master Data", to: "/mtn/master-data/rank" },
    { name: "Maker", to: "/mtn/master-data/maker" },
  ];

  return <Breadcrumb title="Maker" items={bItem} />;
};

export default TitleStore;
