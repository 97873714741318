//SKID
import { combineReducers } from "@reduxjs/toolkit";
import auth from "stores/auth";
import store from "stores/master-data/store";
import customer from "stores/master-data/customer";
import skidList from "stores/skid/list";
import skidIn from "stores/skid/in";
import skidOut from "stores/skid/out";
import reportSummary from "stores/report/summary";
import reportByCustomer from "stores/report/by_customer";
import reportSkidHistory from "stores/report/skid_history";
import labelPrint from "stores/print/label";
import users from "stores/user";
import deliveryOrder from "stores/print/delivery_order";
import pickupOrder from "stores/print/pickup_order";
import adjustInv from "stores/inventory/adjust";
import approveInv from "stores/inventory/approve";

//MTN
import rank from "stores/master-data/rank";
import mtnStore from "stores/master-data/mtn-store";
import supplier from "stores/master-data/supplier";
import classes from "stores/master-data/class";
import line from "stores/master-data/line";
import unit from "stores/master-data/unit";
import maker from "stores/master-data/maker";
import part from "stores/part";
import partIn from "stores/partIn";
import repairPart from "stores/order-history/repair"
import purchase from "stores/order-history/purchase";
import approval from "stores/approval";
import mtnProcessInv from "stores/mtnInventory/process";
import mtnRecapInv from "stores/mtnInventory/recap";
import mtnReport from "stores/mtnReport";

const rootReducer = combineReducers({
  auth,

  //skid
  store,
  customer,
  skidList,
  skidIn,
  skidOut,
  reportSummary,
  reportByCustomer,
  reportSkidHistory,
  labelPrint,
  users,
  deliveryOrder,
  adjustInv,
  approveInv,
  pickupOrder,


  //mtn
  rank,
  mtnStore,
  supplier,
  classes,
  line,
  unit,
  maker,
  part,
  partIn,
  repairPart,
  purchase,
  approval,
  mtnProcessInv,
  mtnRecapInv,
  mtnReport
});

export default rootReducer;
